import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import LiveTemplateDefault from 'src/components/template/live'
// import { selectPostalCode } from 'src/modules/reducers/market'

export default props => {
  // const [callsign, setCallsign] = useState(null)
  // const [loadingText, setLoadingText] = useState(null)

  // const postalCode = useSelector(selectPostalCode)

  // useEffect(() => {
  //   // Define the function to fetch postal code data

  //   const fetchPostalCodeData = async () => {
  //     try {
  //       const response = await fetch('https://qa.fubo.tv/stream/data/masn_postal_codes.json')
  //       // const response = await fetch('http://local.fubo.tv:8000/data/masn_postal_codes.json')
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok')
  //       }
  //       const data = await response.json()
  //       const market = data[postalCode]
  //       if (market) {
  //         setCallsign('MASNHD')
  //         setLoadingText(null)
  //       } else {
  //         setLoadingText('Sorry, live preview is not available in your region.')
  //       }
  //     } catch (error) {
  //       console.error('There was a problem fetching the postal code data:', error)
  //       setLoadingText('Error loading data')
  //     }
  //   }

  //   if (postalCode) {
  //     fetchPostalCodeData()
  //   }
  // }, [postalCode])

  return (
    <LiveTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      callsign="MASNHD"
      title="Watch MASN Live With Fubo"
      networkName="MASN"
      networkSet="baseball"
      customNetworks={['masn']}
      // forceCallsign={true}
      // loadingText={loadingText}
    />
  )
}
