import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
    hasOfferInput={true}
    video={videos.ligue_1.videoUrl}
    title="Watch Ligue 1 on beIN SPORTS"
    subtitle="Stream via beIN SPORTS and beIN SPORTS CONNECT"
    tagline="Every Ligue 1 Game Available Live and On Demand"
    networkSet="soccer"
    sportId={sportIds.Soccer}
    leagueId={leagueIds['Ligue 1']}
  />
)
