import { Link } from 'gatsby'
import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'
import BallySportsNBA from 'src/components/page/bally-sports-nba'

export default props => (
  <StyledContainer>
    <BallySportsNBA
      location={props.location}
      video={videos.bally_cavaliers.videoUrl}
      staticAnnotation={videos.bally_cavaliers.staticAnnotation}
      title="Watch the Cleveland Cavaliers live"
      subtitle="STREAM THE CAVS ON FANDUEL SPORTS OHIO"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-cavs-lockup.png?w=450&ch=width&auto=format,compress"
      teamId={teamIds['Cleveland Cavaliers']}
    />
  </StyledContainer>
)
const StyledContainer = styled.div`
  .content-container .hero-note {
    font-size: 0.9rem;
    font-style: italic;
  }
`
