export const leagueLogos = {
  pl: {
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/leagues/epl_compact.png',
    logoWhite: '',
    alt: 'Premier League',
    height: null,
  },
  seriea: {
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/leagues/serie_a.jpg',
    logoWhite: '',
    alt: 'Serie A',
    height: null,
  },
  coppaitalia: {
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/leagues/coppa_italia.png',
    logoWhite: '',
    alt: 'Copppa Italia',
    height: null,
  },
}
