// import React from 'react'
// import USTemplateDefault from 'src/components/template/us'
// import { videos } from 'src/constants/videos'
// export default props => (
//   <USTemplateDefault
//     location={props.location}
//     lang="en"
//     market="us"
//     video={videos.generic_baseball.videoUrl}
//     offerCode="fubo-affiliation-evergreen-14d-trial"
//     offerBanner={
//       <>
//         ✔ Special offer for SNY fans!
//         <br />
//         Enjoy 14 days FREE of Fubo
//       </>
//     }
//     title="Watch baseball live on SNY"
//     subtitle="CATCH LIVE GAMES ALL SEASON LONG"
//     tagline="Stream them from your TV, phone and other devices"
//     networkSet="baseball"
//     league="mlb"
//     customNetworks={['sny']}
//     showRSN={true}
//   />
// )

import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'
export default props => <RedirectTemplate location={props.location} destination="/mlb/" />
