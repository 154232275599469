import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import styled from 'styled-components'
import Layout from 'src/components/common/Layout'
import SEO from 'src/components/common/SEO'

const Template404 = ({ fetchPlans, location, isChannelError, channels }) => {
  const query = location.search
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('https://www.fubo.tv' + query)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [location])

  return (
    <Layout location={location} noFooter>
      <PageWrapper>
        <div className="redirect-content">
          <div>
            <img
              src="https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_WT_RGB.png?ch=width&auto=format,compress"
              alt="fubo"
            />
            <h1>Page Not Found</h1>
            <h2>You are being redirected...</h2>
          </div>
          <div>
            <p>
              <a href={`https://www.fubo.tv + ${query}`}>Click here</a> if you are not redirected
              within a few seconds.
            </p>
          </div>
        </div>
      </PageWrapper>
    </Layout>
  )
}

export default Template404

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #3c25a9 0%, #0c013e 100%);
  color: #fff;
  .redirect-content {
    width: 75%;
    max-width: 860px;
    padding-top: 112px;
    padding-bottom: 200px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 80%;
      padding-top: 60px;
      padding-bottom: 60px;
      }
    img {
      max-width: 120px;
      margin-bottom: 67px;
      @media (max-width: 768px) {
        margin-bottom: 50px;
      }
    }
    h1 {
  
      font-size: 76px;
      font-weight: 700;
      line-height: 100px;
      letter-spacing: -0.46163445711135864px;
      text-align: left;
      margin-bottom: 45px;
      @media (max-width: 768px) {
        font-size: 42px;
        line-height: 1.25;
      }
    }
    h2 {
  
      font-size: 40px;
      font-weight: 400;
      line-height: 59px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 180px;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 50px;
      }
    }
    p {
  
      font-size: 26px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 0px;
      text-align: left;
      a {
    
        font-size: 26px;
        font-weight: 800;
        line-height: 50px;
        letter-spacing: 0px;
        text-align: left;
        color: #1c9fd0;
        text-decoration: underline;
      }
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 1.25;
        a {
          font-size: 18px;
          line-height: 1.25;
      }
    }
  }
`
