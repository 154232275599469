import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/stream-fx-networks-2021.mp4'}
    title="Watch FX with Fubo"
    subtitle="ENJOY HIT ENTERTAINMENT, LIVE AND ON DEMAND"
    tagline="Stream it from your TV, phone and other devices"
    customNetworks={['fx']}
  />
)
