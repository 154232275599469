import {
  UiRender,
  UiRender_EventName,
  UiRender_Properties,
  UiRender_Attribute_EventCategory_EventCategoryValue,
  UiRender_Attribute_EventSubCategory_EventSubCategoryValue,
} from '@fubotv/fubo-schema-ts/services/papi/v3/tracking/ui_render.ts'

import { JsonValue } from '@protobuf-ts/runtime'
import { v4 as uuid } from 'uuid'

import { formatMessage } from '../utils.ts'

export interface UiRenderProps {
  name: keyof typeof UiRender_EventName
  properties: UiRender_Properties
  category: keyof typeof UiRender_Attribute_EventCategory_EventCategoryValue
  subCategory: keyof typeof UiRender_Attribute_EventSubCategory_EventSubCategoryValue
}

/**
 *
 * @function uiRenderEvent
 * @description Creates a Protobuffer for a ui_render event
 * @param {object} input - Object containing property data for ui_render
 * track call
 * @returns {UiRender} Json UiRender Schema
 */
export const uiRenderEvent = ({ category, subCategory, properties }: UiRenderProps): JsonValue => {
  const message: UiRender = {
    event: UiRender_EventName.ui_render,
    message_id: uuid(),
    properties: {
      ...properties,
      fubo_context: {
        ...properties.fubo_context,
        fubo_namespace: 4,
      },
      attribute: {
        event_category: UiRender_Attribute_EventCategory_EventCategoryValue[category],
        event_sub_category: UiRender_Attribute_EventSubCategory_EventSubCategoryValue[subCategory],
      },
    },
    type: 'track',
  }

  return formatMessage(UiRender, message)
}
