const { useRef, useEffect } = require('react')

export const useIntersectionObserver = ({ callback, options = {}, disable = false }) => {
  const sectionRef = useRef(null)

  const defaultOptions = {
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.1, // Trigger when 10% of the component is visible
    ...options,
  }

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !disable) {
        callback(entry)
      }
    }, defaultOptions)

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [callback, options, sectionRef])

  return sectionRef
}
