type Props = {
  width?: number
  height?: number
  fill?: string
  ariaLabel?: string
}

export function FacebookIcon({
  width = 22,
  height = 22,
  fill = '#ffffff',
  ariaLabel = 'Facebook',
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" role="img" aria-label={ariaLabel}>
      <title>Facebook</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
        <g transform="translate(-1310.000000, -31.000000)" fill={fill} fillRule="nonzero">
          <g transform="translate(1310.000000, 30.000000)">
            <g>
              <path d="M20.7850227,1 L1.21425278,1 C0.54409537,1 0,1.54300863 0,2.21425278 L0,21.7850227 C0,22.4562669 0.54409537,23 1.21425278,23 L11.7505763,23 L11.7505763,14.4803069 L8.88375156,14.4803069 L8.88375156,11.1595864 L11.7505763,11.1595864 L11.7505763,8.71079497 C11.7505763,5.86968978 13.4853784,4.32180728 16.0203846,4.32180728 C17.2357242,4.32180728 18.2779095,4.41273134 18.5818349,4.45221629 L18.5818349,7.42191925 L16.8234868,7.42264375 C15.445136,7.42264375 15.1792465,8.07794902 15.1792465,9.03899098 L15.1792465,11.1584996 L18.4677271,11.1584996 L18.0377396,14.4788579 L15.1788843,14.4788579 L15.1788843,22.9989133 L20.7846605,22.9989133 C21.4555424,22.9989133 22,22.4544556 22,21.7850227 L22,2.21352829 C21.9996378,1.54300863 21.4559046,1 20.7850227,1 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
