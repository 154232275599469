import React, { useEffect, useState } from 'react'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import ChannelSection from 'src/components/common/ChannelSectionNew'
import HeroLiveFeed from 'src/components/common/HeroLivePlayer'
import { useSelector } from 'react-redux'
import { selectParams } from 'src/modules/reducers/params'
import { selectCountryCode } from 'src/modules/reducers/market'
import { Helmet } from 'react-helmet'
import RSNGeolocationBanner from 'src/components/common/RSNOnlyBannerNew'
import HighlightSection from 'src/components/common/HighlightSection'
import RedzoneBanner from 'src/components/common/RedzoneBanner'
import MatchTicker from 'src/components/common/MatchTicker'
import { isUSFreeTrial } from 'src/modules/utils/constants'

const LiveTemplateDefault = ({
  lang = 'en',
  market = 'us',
  // Offer Code
  offerCode,
  offerBanner,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  ctaText,
  background,
  callsign,
  forceCallsign,
  loadingText,
  // Optional Highlight Section
  highlightImage,
  highlightHeadline,
  highlightSubtitle,
  highlightNote,
  highlightBackground,
  // Pass Network Name to Live Preview
  networkName,
  // Network Section
  networkHeadline,
  networkSubtitle,
  networkSet,
  customNetworks,
  // Others
  team,
  // AB Test for RSN
  showRSN,
  showRedzone,
  //  Fox Redirect
  foxRedirect,
  // Embedded Gamefinder
  gamefinder,
  // Remove references to cable
  removeCable,
  // AB Test for MatchTicker
  matchtickerSportId,
  matchtickerLeagueName,
  matchtickerLeagueId,
  // Disable Free Trial
  disableFreeTrial,
  // CA Redirect URL
  caRedirectUrl,
  // Personalization Params
  sportId,
  leagueId,
  teamId,
}) => {
  // Disable Free Trial Modifications
  disableFreeTrial = disableFreeTrial ?? isUSFreeTrial
  const modifiedCtaText = ctaText
  const modifiedNetworkSubtitle =
    networkSubtitle ||
    (disableFreeTrial && (lang === 'es' ? 'Cancela cuando quieras.' : 'Cancel online anytime.'))

  let metaDescription =
    pageDescription ||
    '200+ live channels. Free Cloud DVR. Watch on your phone, Roku, Apple TV, Amazon Fire TV, computer & more. FOX, NBC, CBS, NFL Network, SHOWTIME, beIN SPORTS & more.'
  let heroTitle = title || (lang === 'es' ? '' : 'Watch Live Sports & TV Without Cable')

  const params = useSelector(selectParams)
  const countryCode = useSelector(selectCountryCode)

  const [pageSlug, setPageSlug] = useState(null)

  useEffect(() => {
    if (callsign) {
      setPageSlug(callsign)
    } else if (countryCode === 'CA' || countryCode === 'CAN') {
      setPageSlug('BEINCHD')
    } else if (params?.page_slug) {
      setPageSlug(params?.page_slug)
    } else if (!forceCallsign) {
      setPageSlug('FS1')
    }
  }, [params?.page_slug, countryCode, callsign])

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      caRedirectUrl={caRedirectUrl}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <Helmet>
        <link rel="preconnect" crossOrigin="anonymous" href="https://preview.fubo.tv" />
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://preview.fubo.tv" />
      </Helmet>
      <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
      {matchtickerLeagueId && (
        <MatchTicker
          lang={lang}
          market={market}
          sportId={matchtickerSportId}
          leagueName={matchtickerLeagueName}
          leagueId={matchtickerLeagueId}
        />
      )}
      <HeroLiveFeed
        lang={lang}
        market={market}
        callsign={pageSlug}
        title={heroTitle}
        subTitle={subtitle}
        background={background}
        ctaText={modifiedCtaText}
        subhead={
          disableFreeTrial &&
          (lang === 'es' ? 'Sigue disfrutando con Fubo.' : 'Keep watching with Fubo.')
        }
        logo={logo}
        logoHeight={logoHeight}
        networkName={networkName}
        loadingText={loadingText}
      />
      {highlightImage && (
        <HighlightSection
          image={highlightImage}
          title={highlightHeadline}
          subtitle={highlightSubtitle}
          miceText={highlightNote}
          background={highlightBackground}
        />
      )}
      {showRedzone && <RedzoneBanner />}
      {showRSN && <RSNGeolocationBanner ctaText={modifiedCtaText} />}
      <NetworkSection
        lang={lang}
        market={market}
        networkHeadline={networkHeadline}
        subtitle={modifiedNetworkSubtitle}
        networkSet={networkSet}
        customNetworks={customNetworks}
        ctaText={modifiedCtaText}
      />
      <ChannelSection
        lang={lang}
        market={market}
        title={
          foxRedirect
            ? 'Watch the channels you love'
            : removeCable
            ? 'Enjoy the sports, shows, news and movies you love'
            : null
        }
      />
      <DealSection
        lang={lang}
        market={market}
        title={
          foxRedirect ? 'How is Fubo better?' : removeCable ? 'Why you should choose Fubo' : null
        }
        savingsText={removeCable && 'Fubo has the live sports and TV you love for half the cost.'}
        dvrIcon={
          foxRedirect ? (
            <img
              src="https://assets-imgx.fubo.tv/marketing/lp/images/icons/football-icon.png"
              height="69"
              style={{ margin: '10px 0' }}
            />
          ) : null
        }
        dvrHeader={foxRedirect ? 'Hundreds of sporting events' : null}
        dvrText={
          foxRedirect
            ? 'Enjoy the freedom to record all the sports, shows, movies & news that you love to watch & rewatch again.'
            : null
        }
        cableSource={foxRedirect}
      />
      <PlatformSection lang={lang} market={market} ctaText={modifiedCtaText} />
      <FAQSection
        lang={lang}
        market={market}
        team={team}
        foxRedirect={foxRedirect}
        removeCable={removeCable}
        disableFreeTrial={disableFreeTrial}
      />
    </Layout>
  )
}

export default LiveTemplateDefault
