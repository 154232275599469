import React from 'react'
import OneboxTemplateDefault from 'src/components/template/onebox'
import { videos } from 'src/constants/videos'
import { getURIParamValue } from 'src/modules/utils/analytics'
export default props => {
  const urlSeason = getURIParamValue(props.location.search, 'season')

  const videoUrlSubfolder = urlSeason ? urlSeason : 'pac12'

  return (
    <OneboxTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.generic_sports.videoUrl}
      videoUrlBase={'https://marketing-partners.fubo.tv/wsc/' + videoUrlSubfolder + '/'}
      background="https://assets-imgx.fubo.tv/marketing/lp/images/posters/onebox-pac12.jpg?w=1920&ch=width&auto=format,compress"
      title="Watch live college sports with Fubo"
      networkSet="sports"
      logoHeight="50px"
    />
  )
}
