import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="fs2"
    title="Watch FS2 Live with Fubo"
    networkName="FS2"
  />
)
