/* eslint-disable camelcase */

// fonts/weights
const brand = 'FT Regola Neue' as const
const fontBody = 'Helvetica,Arial,sans-serif' as const
const extrabold = 800
const bold = 700
const semibold = 600
const medium = 500
const normal = 400

// letter spacing:
const letter_spacing_0 = '0.00em'
const letter_spacing_1 = '0.01em'
const letter_spacing_2 = '0.02em'
const letter_spacing_4 = '0.04em'

// sizes:
const font_size_01 = '0.625rem' // 10px
const font_size_02 = '0.688rem' // 11px
const font_size_03 = '0.75rem' // 12px
const font_size_04 = '0.875rem' // 14px
const font_size_05 = '1rem' // 16px
const font_size_06 = '1.125rem' // 18px
const font_size_07 = '1.25rem' // 20px
const font_size_08 = '1.375rem' // 22px
const font_size_09 = '1.5rem' // 24px
const font_size_10 = '1.75rem' // 28px
const font_size_11 = '2rem' // 32px
const font_size_11B = '2.125rem' // 34px
const font_size_12 = '2.25rem' // 36px
const font_size_13 = '2.5rem' // 40px
const font_size_13B = '2.75rem' // 44px
const font_size_14 = '2.875rem' // 46px
const font_size_15 = '3.25rem' // 52px
const font_size_16 = '3.625rem' // 58px
const font_size_16B = '4rem' // 64px
const font_size_17 = '4.125rem' // 66px

// line-heights
const line_height_small = '1.25'
const line_height_normal = '1.5'
const line_height_1 = '0.063rem'
const line_height_10 = '0.625rem'
const line_height_15 = '0.938rem'
const line_height_16 = '1rem'
const line_height_17 = '1.063rem'
const line_height_18 = '1.125rem'
const line_height_19 = '1.188rem'
const line_height_21 = '1.313rem'
const line_height_24 = '1.5rem'
const line_height_25 = '1.563rem'
const line_height_27 = '1.688rem'
const line_height_29 = '1.813rem'
const line_height_32 = '2rem'
const line_height_36 = '2.25rem'
const line_height_40 = '2.5rem'
const line_height_45 = '2.813rem'
const line_height_80 = '5rem'

// transformations:
const uppercase = 'uppercase'

// types
type FontsValues = {
  brand: typeof brand
  fontBody: typeof fontBody
}
type WeightValues = {
  extrabold: 800
  bold: 700
  semibold: 600
  medium: 500
  normal: 400
}
type FontsizeValues = {
  font_size_01: '0.625rem'
  font_size_02: '0.688rem'
  font_size_03: '0.75rem'
  font_size_04: '0.875rem'
  font_size_05: '1rem'
  font_size_06: '1.125rem'
  font_size_07: '1.25rem'
  font_size_08: '1.375rem'
  font_size_09: '1.5rem'
  font_size_10: '1.75rem'
  font_size_11: '2rem'
  font_size_11B: '2.125rem'
  font_size_12: '2.25rem'
  font_size_13: '2.5rem'
  font_size_13B: '2.75rem'
  font_size_14: '2.875rem'
  font_size_15: '3.25rem'
  font_size_16: '3.625rem'
  font_size_16B: '4rem'
  font_size_17: '4.125rem'
}
type LineHeightValues = {
  line_height_small: '1.25'
  line_height_normal: '1.5'
  line_height_1: '0.063rem'
  line_height_10: '0.625rem'
  line_height_15: '0.938rem'
  line_height_16: '1rem'
  line_height_17: '1.063rem'
  line_height_18: '1.125rem'
  line_height_19: '1.188rem'
  line_height_21: '1.313rem'
  line_height_24: '1.5rem'
  line_height_25: '1.563rem'
  line_height_27: '1.688rem'
  line_height_29: '1.813rem'
  line_height_32: '2rem'
  line_height_36: '2.25rem'
  line_height_40: '2.5rem'
  line_height_45: '2.813rem'
  line_height_80: '5rem'
}
type LetterSpacingValues = {
  letter_spacing_0: '0.00em'
  letter_spacing_1: '0.01em'
  letter_spacing_2: '0.02em'
  letter_spacing_4: '0.04em'
}
type TransformationValues = {
  uppercase: 'uppercase'
}

// exports
export const fontsValues: FontsValues = {
  brand,
  fontBody,
}
export const weightsValues: WeightValues = {
  extrabold,
  bold,
  semibold,
  medium,
  normal,
}
export const lineHeightValues: LineHeightValues = {
  line_height_small,
  line_height_normal,
  line_height_1,
  line_height_10,
  line_height_15,
  line_height_16,
  line_height_17,
  line_height_18,
  line_height_19,
  line_height_21,
  line_height_24,
  line_height_25,
  line_height_27,
  line_height_29,
  line_height_32,
  line_height_36,
  line_height_40,
  line_height_45,
  line_height_80,
}
export const fontSizeValues: FontsizeValues = {
  font_size_01,
  font_size_02,
  font_size_03,
  font_size_04,
  font_size_05,
  font_size_06,
  font_size_07,
  font_size_08,
  font_size_09,
  font_size_10,
  font_size_11,
  font_size_11B,
  font_size_12,
  font_size_13,
  font_size_13B,
  font_size_14,
  font_size_15,
  font_size_16,
  font_size_16B,
  font_size_17,
}
export const letterSpacingValues: LetterSpacingValues = {
  letter_spacing_0,
  letter_spacing_1,
  letter_spacing_2,
  letter_spacing_4,
}
export const transformationValues: TransformationValues = {
  uppercase,
}

// tokens stuff:
export const FONT_PREFIX = '--font-family'
const FONT_SIZE_PREFIX = '--font-size'
const LINE_HEIGHT_PREFIX = '--line-height'
export const FONT_WEIGHT_PREFIX = '--font-weight'
const LETTER_SPACING_PREFIX = '--letter-spacing'
export const TRANSFORMATION_PREFIX = '--text-transform'
const fonts = {
  brand: `var(${FONT_PREFIX}-brand)`,
  fontBody: `var(${FONT_PREFIX}-fontBody)`,
} as const
const fontSizes = {
  fontsize01: `var(${FONT_SIZE_PREFIX}-01)`,
  fontsize02: `var(${FONT_SIZE_PREFIX}-02)`,
  fontsize03: `var(${FONT_SIZE_PREFIX}-03)`,
  fontsize04: `var(${FONT_SIZE_PREFIX}-04)`,
  fontsize05: `var(${FONT_SIZE_PREFIX}-05)`,
  fontsize06: `var(${FONT_SIZE_PREFIX}-06)`,
  fontsize07: `var(${FONT_SIZE_PREFIX}-07)`,
  fontsize08: `var(${FONT_SIZE_PREFIX}-08)`,
  fontsize09: `var(${FONT_SIZE_PREFIX}-09)`,
  fontsize10: `var(${FONT_SIZE_PREFIX}-10)`,
  fontsize11: `var(${FONT_SIZE_PREFIX}-11)`,
  fontSize11B: `var(${FONT_SIZE_PREFIX}-11B)`,
  fontsize12: `var(${FONT_SIZE_PREFIX}-12)`,
  fontsize13: `var(${FONT_SIZE_PREFIX}-13)`,
  fontsize13B: `var(${FONT_SIZE_PREFIX}-13B)`,
  fontsize14: `var(${FONT_SIZE_PREFIX}-14)`,
  fontsize15: `var(${FONT_SIZE_PREFIX}-15)`,
  fontsize16: `var(${FONT_SIZE_PREFIX}-16)`,
  fontsize16B: `var(${FONT_SIZE_PREFIX}-16B)`,
  fontsize17: `var(${FONT_SIZE_PREFIX}-17)`,
} as const
const lineHeights = {
  lineHeightSmall: `var(${LINE_HEIGHT_PREFIX}-small)`,
  lineHeightNormal: `var(${LINE_HEIGHT_PREFIX}-normal)`,
  lineHeight1: `var(${LINE_HEIGHT_PREFIX}-1)`,
  lineHeight10: `var(${LINE_HEIGHT_PREFIX}-10)`,
  lineHeight15: `var(${LINE_HEIGHT_PREFIX}-15)`,
  lineHeight16: `var(${LINE_HEIGHT_PREFIX}-16)`,
  lineHeight17: `var(${LINE_HEIGHT_PREFIX}-17)`,
  lineHeight18: `var(${LINE_HEIGHT_PREFIX}-18)`,
  lineHeight19: `var(${LINE_HEIGHT_PREFIX}-19)`,
  lineHeight21: `var(${LINE_HEIGHT_PREFIX}-21)`,
  lineHeight24: `var(${LINE_HEIGHT_PREFIX}-24)`,
  lineHeight25: `var(${LINE_HEIGHT_PREFIX}-25)`,
  lineHeight27: `var(${LINE_HEIGHT_PREFIX}-27)`,
  lineHeight29: `var(${LINE_HEIGHT_PREFIX}-29)`,
  lineHeight32: `var(${LINE_HEIGHT_PREFIX}-32)`,
  lineHeight36: `var(${LINE_HEIGHT_PREFIX}-36)`,
  lineHeight40: `var(${LINE_HEIGHT_PREFIX}-40)`,
  lineHeight45: `var(${LINE_HEIGHT_PREFIX}-45)`,
  lineHeight80: `var(${LINE_HEIGHT_PREFIX}-80)`,
} as const
const weights = {
  extrabold: `var(${FONT_WEIGHT_PREFIX}-extrabold)`,
  bold: `var(${FONT_WEIGHT_PREFIX}-bold)`,
  semibold: `var(${FONT_WEIGHT_PREFIX}-semibold)`,
  medium: `var(${FONT_WEIGHT_PREFIX}-medium)`,
  normal: `var(${FONT_WEIGHT_PREFIX}-normal)`,
} as const
const letterSpacings = {
  letterSpacing0: `var(${LETTER_SPACING_PREFIX}-0)`,
  letterSpacing1: `var(${LETTER_SPACING_PREFIX}-1)`,
  letterSpacing2: `var(${LETTER_SPACING_PREFIX}-2)`,
  letterSpacing4: `var(${LETTER_SPACING_PREFIX}-4)`,
} as const
const body = {
  XS: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize03,
    fontWeight: weights.normal,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  S: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.medium,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  M: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize05,
    fontWeight: weights.medium,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  L: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize06,
    fontWeight: weights.medium,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  XL: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize09,
    fontWeight: weights.medium,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
} as const
const heading = {
  XS: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  S: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize05,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  M: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize07,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightSmall,
  },
  L: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize09,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightSmall,
  },
  XL: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize11,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightSmall,
  },
  XXL: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize13,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightSmall,
  },
  XXXL: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize15,
    fontWeight: weights.extrabold,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightSmall,
  },
} as const

const interactive = {
  S: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  M: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize05,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  L: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize06,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  SLink: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.semibold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  MLink: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize05,
    fontWeight: weights.semibold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
} as const

const label = {
  XS: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize01,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing4,
    lineHeight: lineHeights.lineHeightSmall,
  },
  S: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize03,
    fontWeight: weights.medium,
    letterSpacing: letterSpacings.letterSpacing2,
    lineHeight: lineHeights.lineHeightSmall,
  },
  SBold: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize03,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing2,
    lineHeight: lineHeights.lineHeightSmall,
  },
  M: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  L: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize05,
    fontWeight: weights.medium,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
  LBold: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize05,
    fontWeight: weights.bold,
    letterSpacing: letterSpacings.letterSpacing1,
    lineHeight: lineHeights.lineHeightSmall,
  },
} as const

const paragraph = {
  XS: {
    fontFamily: fonts.fontBody,
    fontSize: fontSizes.fontsize03,
    fontWeight: weights.normal,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightNormal,
  },
  S: {
    fontFamily: fonts.fontBody,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.normal,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightNormal,
  },
  M: {
    fontFamily: fonts.fontBody,
    fontSize: fontSizes.fontsize05,
    fontWeight: weights.normal,
    letterSpacing: letterSpacings.letterSpacing0,
    lineHeight: lineHeights.lineHeightNormal,
  },
} as const

const legacy = {
  dropdownItem: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.bold,
    lineHeights: lineHeights.lineHeight21,
  },
  eventTime: {
    fontFamily: fonts.brand,
    fontSize: fontSizes.fontsize04,
    fontWeight: weights.bold,
  },
  headline: {
    fontSize: fontSizes.fontsize16B,
    lineHeight: lineHeights.lineHeight80,
    fontWeight: weights.extrabold,
  },
  headlineSmallScreen: {
    fontSize: fontSizes.fontsize11,
    lineHeight: lineHeights.lineHeight40,
    fontWeight: weights.extrabold,
  },
  subtext: {
    fontSize: fontSizes.fontsize09,
    lineHeight: lineHeights.lineHeight29,
    fontWeight: weights.medium,
  },
  subtextSmallScreen: {
    fontSize: fontSizes.fontsize04,
    lineHeight: lineHeights.lineHeight17,
    fontWeight: weights.medium,
  },
  paragraph1: {
    fontSize: fontSizes.fontsize06,
    lineHeight: lineHeights.lineHeight27,
    fontWeight: weights.medium,
  },
  paragraph2: {
    fontSize: fontSizes.fontsize04,
    lineHeight: lineHeights.lineHeight21,
    fontWeight: weights.medium,
  },
  paragraph3: {
    fontSize: fontSizes.fontsize03,
    lineHeight: lineHeights.lineHeight18,
    fontWeight: weights.normal,
  },
  title1: {
    fontSize: fontSizes.fontsize10,
    lineHeight: lineHeights.lineHeight36,
    fontWeight: weights.medium,
  },
  title2: {
    fontSize: fontSizes.fontsize07,
    lineHeight: lineHeights.lineHeight24,
    fontWeight: weights.medium,
  },
  title3: {
    fontSize: fontSizes.fontsize06,
    lineHeight: lineHeights.lineHeight25,
    fontWeight: weights.medium,
  },
  short1: {
    fontSize: fontSizes.fontsize05,
    lineHeight: lineHeights.lineHeight21,
    fontWeight: weights.medium,
  },
  short2: {
    fontSize: fontSizes.fontsize04,
    lineHeight: lineHeights.lineHeight19,
    fontWeight: weights.medium,
  },
  short3: {
    fontSize: fontSizes.fontsize04,
    lineHeight: lineHeights.lineHeight19,
    fontWeight: weights.normal,
  },
  short4: {
    fontSize: fontSizes.fontsize03,
    lineHeight: lineHeights.lineHeight18,
    fontWeight: weights.normal,
  },
} as const
export const typography = {
  fonts,
  weights,
  lineHeights,
  fontSizes,
  letterSpacings,
  // bundles:
  body,
  heading,
  interactive,
  label,
  paragraph,
  legacy,
} as const
