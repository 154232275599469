import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NFL_ESPN_2022_LP_15s.mp4'}
      staticAnnotation={videos.nflespn.staticAnnotation}
      title="Watch football on ESPN"
      subtitle="CATCH THE BIGGEST MATCHUPS OF THE SEASON"
      tagline="Stream them live from your TV, phone and other devices."
      ctaText="Start Watching"
      networkSet="nfl"
      customNetworks={['espn']}
      showRedzone={true}
      sportId={sportIds.Football}
      leagueId={leagueIds.NFL}
    />
  )
}
