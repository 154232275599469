import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    // video={videos.colorado_rockies.videoUrl}
    poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/colorado-rockies-bg.jpg"
    pageTitle="Watch Colorado Rockies baseball"
    title={
      <>
        Watch Colorado Rockies<sup>®</sup> baseball
      </>
    }
    subtitle="STREAM LIVE GAMES ALL SEASON LONG"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="baseball"
    league="MLB"
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['Colorado Rockies']}
  />
)
