import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.generic_baseball.videoUrl}
      title="Mira la MLB en vivo en español"
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/mlb/"
    />
  )
}
