const initialState = {}

export const paramsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PARAMS':
      return {
        ...state,
        ...action.payload,
      }
    case 'DELETE_PARAM':
      const { [action.payload]: _, ...rest } = state // Extract the parameter to delete
      return rest // Return the state without the specified parameter
    default:
      return state
  }
}
