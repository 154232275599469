import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectProPlanCardsFlag, selectStacFlagsReady } from 'src/modules/reducers/cohorts'
import { selectPostalCode } from 'src/modules/reducers/market'
import { fetchRSNs } from 'src/modules/utils/checkRSN'
import { selectStateForProtobuf } from '../reducers'
import { fireUIRenderEvent } from '../analytics/v3'

const useEssentialsEligibilityCheck = () => {
  const proPlanCardsFlag = useSelector(selectProPlanCardsFlag)
  const cohortingReady = useSelector(selectStacFlagsReady)

  const [isEssentialsEligible, setIsEssentialsEligible] = useState(false)
  const postalCode = useSelector(selectPostalCode)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const [isEventSent, setIsEventSent] = useState(false)

  const fireEvent = useCallback(
    isEligible => {
      if (!isEventSent && cohortingReady) {
        fireUIRenderEvent(
          {
            component: 'pro_and_essential_plan_cards',
            type: proPlanCardsFlag,
            element: isEligible ? 'eligible' : 'not eligible',
          },
          stateForProtobuf
        )
        setIsEventSent(true)
      }
    },
    [isEventSent, cohortingReady]
  )

  useEffect(() => {
    let mounted = true

    const checkEligibility = async () => {
      if (!postalCode) return

      if (!proPlanCardsFlag.includes('test')) {
        setIsEssentialsEligible(false)
        fireEvent(false)
        return
      }

      try {
        const rsnList = await fetchRSNs(postalCode)
        if (!mounted) return

        if (!rsnList) {
          setIsEssentialsEligible(false)
          // Postal code has no rsns
          fireEvent(false)
          return
        }

        const hasExcludedRSNs = ['SNY', 'MSG', 'Marquee'].some(rsn => rsnList.includes(rsn))
        console.log(hasExcludedRSNs, 'hasExcludedRSNs')
        if (hasExcludedRSNs) {
          // Postal code has excluded RSNs
          console.log('not essentials eligible')
        }
        fireEvent(!hasExcludedRSNs)
        setIsEssentialsEligible(!hasExcludedRSNs)
      } catch (error) {
        console.error('Error checking RSN exclusions:', error)
        setIsEssentialsEligible(false)
      }
    }

    checkEligibility()

    return () => {
      mounted = false
    }
  }, [proPlanCardsFlag, postalCode, cohortingReady])

  return isEssentialsEligible
}

export default useEssentialsEligibilityCheck
