const initialState = {
  isInitialized: false,
  isLoading: false,
}

export const zvaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ZVA_INIT_LOADING':
      return {
        ...state,
        isLoading: true,
      }
    case 'ZVA_INIT_SUCCESS':
      return {
        ...state,
        isInitialized: true,
        isLoading: false,
      }
    case 'ZVA_INIT_FAILED':
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
