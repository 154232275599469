import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      addonCode="mlb"
      title="Get MLB.TV with Fubo"
      subtitle="SEE EVERY OUT-OF-MARKET TEAM IN ACTION"
      tagline="Watch live games from any device all season"
      video={videos.mlbtv.videoUrl}
      staticAnnotation={videos.mlbtv.staticAnnotation}
      heroCtaNote={
        <>
          Blackout and other restrictions apply. Major League Baseball trademarks and copyrights are
          used with permission of Major League Baseball. Visit{' '}
          <a href="MLB.com" target="_blank" style={{ textDecoration: 'underline' }}>
            MLB.com
          </a>{' '}
          for details.
        </>
      }
      networkSet="baseball"
      league="mlb"
      showRSN={true}
      caRedirectUrl="/ca/mlb-tv-ca/"
    />
  )
}
