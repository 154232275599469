import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerCode="pro-boston-sox-14dtrial-230414"
    video={videos.seasonal_us.videoUrl}
    videoMobile={videos.seasonal_us.videoMobileUrl}
    annotations={videos.seasonal_us.annotations}
    title="Autodesk employees special offer"
    subtitle="Enjoy a 14-day free trial of Fubo"
    tagline="Watch Maximum Effort Channel, along with live TV and Sports"
    showRSN={true}
  />
)
