import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { updateParams } from 'src/modules/reducers/params'

export default props => {
  const dispatch = useDispatch()

  const params = {
    irad: '366904',
    irmp: '3291532',
  }

  useEffect(() => {
    dispatch(updateParams(params))
  }, [])

  return (
    <USTemplateDefault
      location={props.location}
      offerBanner={
        <>
          ✔ Special offer applied! <br />
          15% off your first payment for Ziply Fiber customers
        </>
      }
      offerCode={'elite-fubo-basic-ziply'}
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      tagline="Watch and DVR the channels you love. No contracts, no commitment."
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/fubotv_ziply_lockup.png?w=450&ch=width&auto=format,compress"
      logoHeight="60px"
    />
  )
}
