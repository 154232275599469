import { MessageType } from '@protobuf-ts/runtime'

import { getUniqueIdCookie } from '@fubotv/fubo-cookie'
import bowser from 'bowser'
import { get } from 'lodash-es'

import type { ClientIdentifierInput } from '@fubotv/js-client/dist/esm/modules/state/identification.js'

import FUBO_APP_VERSION from '../../../../package.json'

const getTimezoneOffset = () => (0 - new Date().getTimezoneOffset()).toString()


export function parseMessage<T extends object>(
  Schema: MessageType<T>,
  message: T,
  repeatingProperty?: string
) {
  try {
    if (repeatingProperty) {
      /**
       * This is an arbitrary index from the repeating event property in the
       * fubo-schema repo. The iterating functionality lies in the
       * event_properties type (parent), not the singular type (child). Thus,
       * the message is an object with the property key that is iterated over
       * within protobuf. For example, "Cohorting" is a protobuf type that is a
       * single key/value pair. The "CohortingEvent_Properties" parent defines
       * "Cohorting[]" and all other properties. This is why we return only the
       * "repeatingProperty" in the return statement here.
       */
      // @ts-ignore
      return Schema.fromJson(message)[repeatingProperty]
    }

    /**
     * In case of non-repeating properties, we simply pass in the singular
     * type's schema and return that value. This theoretically will never be
     * the event property's parent. For example, this could be "Location" but
     * not "LocationEvent_Properties".
     */
    return Schema.create(Schema.fromJsonString(JSON.stringify(message)))
  } catch (e) {
    console.error('Error parsing protobuf message', e)
  }
}

export function formatMessage<T extends object>(Schema: MessageType<T>, message: T) {
  try {
    return Schema.toJson(message, {
      enumAsInteger: false,
      useProtoFieldName: true,
      emitDefaultValues: true,
    })
  } catch (e) {
    console.error('Error formatting protobuf message', e)
  }
}

/**
 * Until fubo-schema is updated to include the category and subcategory
 * properties, we have to manually add these. Due to this issue, typing
 * errors are being ignored in this method.
 *
 * This method should be completely removed once updated in favor of the
 * `formatMessage` method.
 *
 * @param Schema - The schema type from the v3 spec
 * @param message - The raw analytics object from the v3 event formatter
 */
export function formatCohortingMessage<T extends object>(Schema: MessageType<T>, message: T) {
  const schema = formatMessage(Schema, message)

  if (schema?.constructor === Object) {
    // @ts-expect-error
    ;(schema as object).properties = {
      // @ts-expect-error
      ...schema.properties,
      // @ts-expect-error
      attribute: message.properties.attribute || {},
    }
  }

  return schema
}

export const stripNil = function(obj: any): any {
  for (const prop in obj) {
    if (obj[prop] === undefined || obj[prop] === null) delete obj[prop]
  }
  return obj
}



export const getIdentityInfo = () => {
  let deviceGroup = 'desktop'
  let deviceType = 'desktop'
  let deviceApp = 'web'
  let devicePlatform = 'desktop'
  if (bowser.mobile) {
    deviceGroup = 'mobile'
    deviceType = 'phone'
    deviceApp = 'mobile_web'
    if (bowser.silk) {
      devicePlatform = 'fire_phone'
    } else if (bowser.android) {
      devicePlatform = 'android_phone'
    } else if (bowser.ios) {
      devicePlatform = 'iphone'
    }
  } else if (bowser.tablet) {
    deviceGroup = 'mobile'
    deviceType = 'tablet'
    deviceApp = 'mobile_web'

    if (bowser.silk) {
      devicePlatform = 'fire_tablet'
    } else if (bowser.android) {
      devicePlatform = 'android_tablet'
    } else if (bowser.ios) {
      devicePlatform = 'ipad'
    }
  }
  const uniqueId = getUniqueIdCookie()

  return {
    applicationId: '',
    browserEngineName: '',
    browserEngineVersion: '',
    browserName: '',
    browserVersion: '',
    clientVersion: get(FUBO_APP_VERSION, 'version', '(none)'),
    deviceGroup,
    deviceType,
    deviceApp,
    devicePlatform,
    deviceModel: `${bowser.osname} ${bowser.osversion} ${bowser.name} ${bowser.version}`,
    deviceId: uniqueId,
    drmSchemeHeader: '',
    osName: '',
    osVersion: '',
    playerVersion: '',
    profileId: '',
    supportedCodecs: '',
    supportedStreamingProtocols: '',
    timezoneOffset: getTimezoneOffset(),
    vendor: '',
  }
}
