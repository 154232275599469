import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="nesn"
    title="Watch NESN Live Online with Fubo"
    networkName="NESN"
  />
)
