import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Grammys_2022_LP_12sec_V3.mp4'}
    title="Watch the GRAMMYs on CBS"
    subtitle="SEE MUSIC'S BIGGEST NIGHT® LIVE"
    tagline="Stream it from your TV and other devices"
    ctaText={'Start Watching'}
  />
)
