import React from "react"

const EmptyCircle = () => {
  return (
    <svg
      className="selected-icon empty-circle"
      width="24"
      height="24"
      viewBox="0 0 30 30"
      role="img"
      aria-label="check mark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 16 3 C 8.8203 3 3 8.8203 3 16 C 3 23.1797 8.8203 29 16 29 C 23.1797 29 29 23.1797 29 16 C 29 8.8203 23.1797 3 16 3 Z Z"
        fill="#d1d1d1"
      ></path>
    </svg>
  )
}

export default EmptyCircle