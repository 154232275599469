const MAX_ATTEMPTS = 6
const WAIT_TIME = 500

const ZVAAwaiter = (resolver, rejecter, config, attempt, dispatch) => {
  // Ensure all functions we plan to call are available, just checking window.Solvvy wasn't actually adequate
  if (window.zoomCampaignSdk?.open) {
    resolver()
  } else if (attempt < MAX_ATTEMPTS) {
    setTimeout(() => ZVAAwaiter(resolver, rejecter, config, attempt + 1), WAIT_TIME * MAX_ATTEMPTS)
  } else {
    const errorMessage = `ZVA still not available after ${MAX_ATTEMPTS} attempts`
    dispatch(initZvaFailed())
    console.error(errorMessage)
    rejecter(new Error(errorMessage))
  }
}

export const initZVA = config => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      try {
        if (window.zoomCampaignSdk?.open) {
          dispatch(initZvaSuccess())
          resolve()
        } else {
          dispatch(initZvaLoading())

          const solvvyEl = document.createElement('script')
          solvvyEl.setAttribute('data-apikey', 'dN62y0A6T_GmWyv-1LZFJw')
          solvvyEl.setAttribute('data-env', 'us01')
          solvvyEl.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js'
          solvvyEl.async = true
          solvvyEl.onload = () => {
            window.addEventListener('zoomCampaignSdk:ready', () => {
              resolve()
            })
          }
          solvvyEl.onerror = err => {
            console.error('ZVA: error loading script', err)
            dispatch(initZvaFailed())
            reject(err)
          }
          document && document.body && document.body.appendChild(solvvyEl)
        }
      } catch (err) {
        console.error('Zva: error loading script', err)
        dispatch(initZvaFailed())
        reject(err)
      }
    })
      .then(() => {
        return new Promise((resolve, reject) => {
          ZVAAwaiter(resolve, reject, config, 1, dispatch)
        })
      })
      .then(() => {
        new Promise((resolve, reject) => {
          if (window.zoomCampaignSdk?.open) {
            try {
              window.fubo_zva_userData = config
              window.zoomCampaignSdk?.open()
              window.zoomCampaignSdk?.show()
              dispatch(initZvaSuccess())
              resolve()
            } catch (err) {
              console.error('Solvvy: error opening', err)
              dispatch(initZvaFailed())
              reject(err)
            }
          } else {
            const errorMessage = 'Solvvy cannot open because it is not found in the window object'
            const error = new Error(errorMessage)
            console.error('Solvvy: error opening', error)
            dispatch(initZvaFailed())
            reject(error)
          }
        })
      })
  }
}

const initZvaLoading = () => {
  return {
    type: 'ZVA_INIT_LOADING',
  }
}

const initZvaSuccess = () => {
  return {
    type: 'ZVA_INIT_SUCCESS',
  }
}

const initZvaFailed = () => {
  return {
    type: 'ZVA_INIT_FAILED',
  }
}
