import React from 'react'
import styled from 'styled-components'

const CTAButton = ({ link, round, children, ...props }) => {
  return (
    <StyledCTAButton href={link} {...props} className="CTAButton">
      {children}
    </StyledCTAButton>
  )
}

export default CTAButton

const StyledCTAButton = styled.a`
  position: relative;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-style: normal;

  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.56px;
  padding: 15px 41px;
  white-space: nowrap;

  background: #e03100;
  border-radius: 4px;
  box-sizing: border-box;

  :hover {
    ${'' /* background: #b33922; */}
  }

  @media screen and (min-width: 768px) {
    font-size: 26px;
    padding: 21px 53px;
    min-width: 290px;
  }
`
