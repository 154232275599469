import React from 'react'
import CollegeFootball from 'src/pages/college-football'
import { teamIds } from 'src/constants/personalizationIds'
export default props => (
  <CollegeFootball
    {...props}
    team="Clemson Tigers"
    subtitle="SEE THE CLEMSON TIGERS IN ACTION THIS SEASON"
    teamId={teamIds['Clemson Tigers']}
  />
)
