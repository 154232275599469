import { DeviceCategory_DeviceCategoryValue } from '@fubotv/fubo-schema-ts/common/v3/generic/common_enums.ts'
import bowser from 'bowser'

import pkg from '../../../../package.json'

export { default as getModalContext } from './modalContext.ts'

export function getDeviceCategoryV3(): DeviceCategory_DeviceCategoryValue {
  return bowser.mobile
    ? DeviceCategory_DeviceCategoryValue['mobile_web']
    : DeviceCategory_DeviceCategoryValue['web']
}
export function getDeviceContext(): any {
  const { navigator } = window

  return {
    browser: bowser.name,
    browser_engine: getBrowserEngine(),
    browser_version: bowser.version,
    device_type: getDeviceType(),
    input_mode: getInputType(),
    os: getOS(),
    os_version: bowser.osversion,
    runtime: 'web',
    user_agent: navigator.userAgent,
  }
}

function getBrowserEngine(): string {
  return (
    /** IE <= 11 */
    (bowser.msie && 'msie') ||
    /**Chrome 0-27, Android <4.4, iOs, BB, etc. */
    (bowser.webkit && 'webkit') ||
    /**Chrome >=28, Android >=4.4, Opera, etc. */
    (bowser.blink && 'blink') ||
    /**Firefox, etc. */
    (bowser.gecko && 'gecko') ||
    /** IE > 11 */
    (bowser.msedge && 'msedge')
  )
}

export function getOS(): string {
  return (
    (bowser.mac && 'macos') ||
    (bowser.windows && 'windows') ||
    (bowser.windowsphone && 'windows_phone') ||
    (bowser.linux && 'linux') ||
    (bowser.chromeos && 'chromeos') ||
    (bowser.android && 'android') ||
    (bowser.ios && 'ios') ||
    (bowser.blackberry && 'blackberry') ||
    (bowser.firefoxos && 'firefoxos') ||
    (bowser.webos && 'webos') ||
    (bowser.bada && 'bada') ||
    (bowser.tizen && 'tizen') ||
    (bowser.sailfish && 'sailfish') ||
    'unknown'
  )
}
function getDeviceType(): string {
  return (bowser.tablet && 'tablet') || (bowser.mobile && 'phone') || '_'
}
function getInputType(): string {
  return '_'
}
export function getAppContext(): any {
  return {
    app_name: pkg.name,
    app_version: pkg.version,
    player_version: '_',
  }
}

export const getPageOptions = () => {
  const location = window.location
  const path = location.pathname
  return {
    origin_page: window.document.referrer,
    url: location.href,
    page: path.substring(1, path.length - 1),
    app_section: 'stream',
    source: 'stream_landing_page',
  }
}
