import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    addonCode="deportes"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/SEC_Network_2021.mp4"
    staticAnnotations={{
      logoUrl:
        'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/espn_cfb_v2.png?auto=format,compress?w=100&fit=fill&fill=solid',
    }}
    title="Watch SEC Network with Fubo"
    subtitle="LIVE GAMES, NEWS, ANALYSIS AND MORE"
    tagline="Stream it from your TV, phone and other devices"
  />
)
