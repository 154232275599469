import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NOC_LP_2021_15s.mp4'}
      offerCode={'fubo-affiliation-evergreen-15off'}
      offerBanner={
        <>
          Special offer for Agent Zero fans! <br />
          Save 15% off* your first month of Fubo.
        </>
      }
      title="Stream live TV & sports"
      subtitle="PLUS GET 15% OFF YOUR FIRST MONTH"
      tagline="Enjoy 200+ live channels. No contract, no commitment."
      networkSet="sports"
    />
  )
}
