import type { LiteProducts } from '@fubotv/types/api/UP/products'

import { ApiError } from '~/types/domain'

import {
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCTS_SUCCESS,
  FetchProductsFailAction,
  FetchProductsSuccessAction,
  TryFetchProductsAction,
} from '../actionTypes/'

import { call } from '../../js-client'

import { doFetchProducts } from 'src/modules/reducers/products/effects'

export const tryFetchProducts = () => {
  return async dispatch => {
    try {
      const data = await call(() =>doFetchProducts({visibilityContext: 'store'}))
      await dispatch(fetchProductsSuccessful(data))
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'response' in error) {
        dispatch(fetchProductsFail(error.response))
      } else if (error && typeof error === 'object' && 'request' in error) {
        dispatch(fetchProductsFail(error.request))
      } else {
        dispatch(fetchProductsFail(
          typeof error === 'string' ? error : 'An unknown error occurred'
        ))
      }
    }
  }
}

export const fetchProductsSuccessful = ({
  products: plans,
  addons: addOns = [],
}: LiteProducts): FetchProductsSuccessAction => ({
  type: FETCH_PRODUCTS_SUCCESS,
  plans,
  addOns,
})

export const fetchProductsFail = (
  error: ApiError
): FetchProductsFailAction => ({
  type: FETCH_PRODUCTS_FAIL,
  error,
})
