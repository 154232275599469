import React from 'react'
import { useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectParams } from 'src/modules/reducers/params'
export default props => {
  const params = useSelector(selectParams)

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerBanner={
        params?.offer ? (
          <>
            Enjoy an exclusive offer with your stay: <br />
            Get 20% off your first month of Fubo.
          </>
        ) : null
      }
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      tagline="Watch and DVR the channels you love. No contracts, no commitment."
      showRSN={true}
    />
  )
}
