import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledWrapper>
      <USDefaultTemplate
        location={props.location}
        lang="en"
        market="us"
        offerBanner={
          <>
            ✔ Special offer unlocked! <br /> Xbox Game Pass gets you an extended 14-day FREE trial.
          </>
        }
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Watch live sports, shows, movies & news"
        subtitle="Stream it all live anytime, anywhere, from any device."
        hasOfferInput={true}
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-xbox.png?ch=width&auto=format,compress"
        showRedzone={true}
        newTemplate={true}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .content-container .hero-content {
    max-width: 670px;
  }
`
