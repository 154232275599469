import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_angels.videoUrl}
    staticAnnotation={videos.bally_angels.staticAnnotation}
    title="Watch Los Angeles baseball live"
    subtitle="STREAM THE ANGELS ON FANDUEL SPORTS WEST"
    teamId={teamIds['Los Angeles Angels']}
  />
)
