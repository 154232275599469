import { navigate } from 'gatsby'
import React from 'react'
import { useSelector } from 'react-redux'
import CTAButton from 'src/components/common/PlanCardCTA'
import { selectCTAParams } from 'src/modules/reducers/params'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import styled from 'styled-components'

const PlanCard = ({
  offerCode,
  plan,
  title,
  price,
  discountedPrice,
  tagline,
  listItems,
  ctaText = 'Start Free Trial',
  footnote,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  ctaURL = replaceUrlParam(ctaURL, 'offer', offerCode)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'plan-card')

  return (
    <StyledPlanCard>
      <a className="planCard" href={ctaURL}>
        <div className="planCard__content">
          <div className="planCard__header">
            <div className="planCard__title">{title}</div>
            <div className="planCard__divider"></div>
            <div className={`planCard__price`}>
              {discountedPrice ? (
                <>
                  {discountedPrice}
                  {footnote && '*'} <span className="faded-price">{price}</span>
                </>
              ) : (
                <>
                  {price}
                  {footnote && '*'}
                </>
              )}
            </div>
            {tagline && <div className="discount__note">{tagline}</div>}
          </div>
          <div className="planCard__list">
            {listItems?.map((item, index) => {
              if (item === null) return null
              const check = item.available ? (
                <span className="bullet check">✔&#xfe0e;</span>
              ) : (
                <span className="bullet cross">✘&#xfe0e;</span>
              )

              return (
                <div key={index} className="list-item">
                  {check} {item.text}
                </div>
              )
            })}
          </div>
        </div>
        <div className="planCard_cta_container">
          <div className="planCard_cta">
            <CTAButton link={ctaURL} color="#fa4616">
              {ctaText}
            </CTAButton>
          </div>
          {/* <div className="learnMore_cta">
            <button onClick="">Learn More</button>
          </div> */}
        </div>
      </a>
      {footnote && <div className="planCard__footnote">{footnote}</div>}
    </StyledPlanCard>
  )
}

export default PlanCard

const StyledPlanCard = styled.div`
  /* Reset CSS for <button /> */
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  width: 100%;
  max-width: 450px;
  position: relative;
  .planCard {
    color: #424242;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    cursor: pointer;
    transition: transform 0.4s;
    ${'' /* &:hover {
      transform: scale(1.01);
    } */}
    @media (max-width: 992px) {
      padding-bottom: 8%;
    }
    .planCard__content {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: 7%;
      padding-right: 7%;
      flex: 1;
      .planCard__header {
        .planCard__eyebrow {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 15px;
        }
        .planCard__title {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 16px;
        }
        .planCard__divider {
          ${'' /* background: rgb(104, 104, 104, 0.2); */}
          background: #555858;
          height: 1.5px;
          width: 75px;
          border: 0;
          margin: 25px 0;
        }
        .planCard__price {
          font-size: 28px;
          font-weight: 700;
          @media (max-width: 992px) {
            font-size: 24px;
          }
        }
        .faded-price {
          margin-top: 4px;
          font-size: 0.7em;
          color: rgba(66, 66, 66, 0.7);
          text-decoration: line-through;
          font-weight: 400;
          -webkit-text-decoration-color: #fa4616;
          text-decoration-color: #fa4616;
        }
        .discount__note {
          font-size: 1rem;
          font-weight: 700;
          letter-spacing: 0.01em;
          line-height: 1.25;
          text-decoration: none;
          text-transform: none;
          color: #ffffff;
          display: inline-block;
          background-color: #2a2e38;
          border-radius: 4px;
          padding: 8px 8px;
          margin-top: 10px;
          span {
            color: #49f296;
          }
        }
      }
      .planCard__list {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 100px;
        gap: 20px;
        @media (max-width: 992px) {
          margin-top: 25px;
          margin-bottom: 50px;
        }
        .list-item {
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0px;
          ${'' /* margin-bottom: 14px; */}
          display: flex;
          .bullet {
            width: 20px;
            margin-right: 8px;
            line-height: 1;
            flex: 0 0 20px;
          }
          .check {
            color: #04a928;
            ${'' /* color: blue; */}
            font-size: 18px;
          }
          .cross {
            color: #ff0000;
            font-size: 20px;
          }
        }
      }
    }
    .planCard_cta {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: 6%;
      padding-right: 6%;
      ${'' /* height: 100%; */}
      justify-content: flex-end;
      color: #fff;
      text-transform: uppercase;

      .CTAButton {
        ${'' /* color: #fa4616;
        border-color: #fa4616;
        background: #fff; */}
      }
    }
    .learnMore_cta {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: 6%;
      padding-right: 6%;
      ${'' /* height: 100%; */}
      justify-content: flex-end;
      ${'' /* color: #fff; */}
      text-transform: uppercase;

      margin-top: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #000000;

      .CTAButton {
        ${'' /* color: #fa4616;
        border-color: #fa4616;
        background: #fff; */}
      }
    }
  }
  .planCard__footnote {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
    @media (min-width: 1020px) {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
`
