import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default props => (
  <NFL
    {...props}
    team="Minnesota Vikings"
    teamShort="Vikings"
    teamId={teamIds['Minnesota Vikings']}
  />
)
