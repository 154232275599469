import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/FreeformHoliday2020.mp4"
    title="Watch 25 Days of Christmas on Freeform"
    subtitle="HOLIDAY MOVIES, TV SHOWS AND SPECIALS"
    tagline="Stream them from your TV, phone and other devices"
  />
)
