import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laDiscountPriceMonthly } from 'src/constants/laPlanData'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      ctaText="Learn More"
      video={videos.latino.videoUrl}
      annotations={videos.latino.annotations}
      title="Save big on live TV for your family"
      subtitle={`Access now for only $${laDiscountPriceMonthly} the first month`}
      tagline="60+ channels of sports, news, novelas, and entertainment in Spanish"
      networkSet="entertainmentLatino"
      noteLink="/la/acceder/"
    />
  )
}
