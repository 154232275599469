import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds } from 'src/constants/personalizationIds'

export default props => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.ufl.videoUrl}
      title="Watch UFL games with Fubo"
      subtitle="ALL SEASON LONG ON ABC, ESPN, FOX & FS1"
      tagline="Stream the action live from your TV & other devices"
      networkSet="football"
      customNetworks={['espn', 'abc', 'fox', 'fs1']}
      sportId={sportIds.Football}
    />
  )
}
