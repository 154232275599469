import React from 'react'
import { useSelector } from 'react-redux'
import FuboFreeTemplate from 'src/components/template/fubofree'
import USTemplateDefault from 'src/components/template/us'
import { fanduelSportsNetworkFAQs } from 'src/constants/faqs'
import { videos } from 'src/constants/videos'
import { selectShowFanduelPlanCardsFlag } from 'src/modules/reducers/cohorts'
import { useCheckFanduelRSN } from 'src/modules/utils/useCheckFanduelRSN'

export default props => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()
  const showFDOnly = useSelector(selectShowFanduelPlanCardsFlag)

  const commonProps = {
    location: props.location,
    lang: 'en',
    market: 'us',
    poster: 'https://assets.fubo.tv/marketing/lp/images/posters/fdsn_poster-1.jpg',
    title: 'Watch FanDuel Sports with Fubo',
    subtitle: 'STREAM LIVE GAMES AND LOCAL TEAM COVERAGE',
    tagline: 'Anytime. Anywhere. From any device. Regional restrictions apply.',
    networkSet: 'sports',
    customNetworks: ['fanduelsports'],
  }

  return showFDOnly && showFDPlanCards ? (
    <FuboFreeTemplate
      {...commonProps}
      hasFanduelPlanCards={showFDPlanCards}
      fanduelRSNData={rsnData}
      additionalFAQs={fanduelSportsNetworkFAQs}
    />
  ) : (
    <USTemplateDefault
      {...commonProps}
      hasFanduelPlanCards={showFDPlanCards}
      fanduelRSNData={rsnData}
      additionalFAQs={fanduelSportsNetworkFAQs}
    />
  )
}
