import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/golfgen.mp4'}
    title="Watch the Masters live with Fubo"
    subtitle="PLUS OVER 200 CHANNELS OF LIVE SPORTS & TV"
    tagline="No contract, no commitment."
    ctaText={'Start Watching'}
    networkSet="sports"
    sportId={sportIds.Golf}
  />
)
