import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.leagues_cup.videoUrl}
      title="Watch the Leagues Cup on Univision"
      subtitle="SEE LIGA MX & MLS TEAMS GO HEAD-TO-HEAD"
      tagline="Stream live matches from any device with Fubo"
      networkSet="soccerLatino"
      customNetworks={['univision', 'unimas']}
      noteLink="/la/leagues-cup-es/"
    />
  )
}
