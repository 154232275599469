import React from 'react'
import OneboxTemplateDefault from 'src/components/template/onebox/canada'
import { videos } from 'src/constants/videos'
import { getURIParamValue } from 'src/modules/utils/analytics'
export default props => {
  const urlSeason = getURIParamValue(props.location.search, 'season')

  const videoUrlSubfolder = urlSeason ? urlSeason : 'eplca'

  return (
    <OneboxTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={videos.canada_epl.videoUrl}
      videoUrlBase={'https://marketing-partners.fubo.tv/wsc/' + videoUrlSubfolder + '/'}
      background="https://assets-imgx.fubo.tv/marketing/lp/images/posters/onebox-epl.png?w=1920&ch=width&auto=format,compress"
      title="Watch every Premier League match live exclusively on Fubo"
      subtitle="See your favourite clubs in action. Stream select matches in 4K. Cancel anytime."
      plan="soccer"
      networkSet="canadaEssentials"
      customNetworks={['fsn']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
      logoHeight="90px"
    />
  )
}
