import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.seasonal_us.videoUrl}
    videoMobile={videos.seasonal_us.videoMobileUrl}
    annotations={videos.seasonal_us.annotations}
    title="Watch Your Favorite Shows, Movies & Sports"
    subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
    tagline="Watch and DVR the channels you love. No contracts, no commitment."
    networkSet="entertainment"
    showRSN={true}
  />
)
