import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/CONCACAF_2021_LP_19s.mp4'}
      title="Watch the CONCACAF Gold Cup"
      subtitle="SEE TEAM CANADA, U.S.A, MEXICO & MORE"
      tagline="Stream live matches on OneSoccer with Fubo"
      networkSet="canada"
      plan="soccer"
      customNetworks={['onesoccer']}
    />
  )
}
