import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/Cycling2022_1280x720_LP_20s.mp4"
    title="Watch live cycling events"
    subtitle="SEE THE TOUR DE FRANCE AND MORE"
    tagline="Stream them from your TV and other devices"
    networkSet="sports"
    customNetworks={['nbc', 'usa', 'cnbc']}
    sportId={sportIds.Cycling}
  />
)
