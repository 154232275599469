import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds } from 'src/constants/personalizationIds'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.womens_basketball.videoUrl}
    title="Watch women's basketball with Fubo"
    subtitle="CATCH THE ACTION LIVE ALL SEASON LONG"
    tagline="Stream games from your TV, computer & other devices"
    networkSet="womensBasketball"
    league="WNBA"
    showRSN={true}
    sportId={sportIds.Basketball}
  />
)
