import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
export default props => (
  <StyledWrapper>
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.oscars.videoUrl}
      staticAnnotation={videos.oscars.staticAnnotation}
      pageTitle="Watch the Oscars® on ABC"
      title={
        <>
          Watch The Oscars<sup>®</sup> on ABC
        </>
      }
      subtitle="SEE WHO WILL TAKE HOME A STATUETTE"
      tagline="Stream it live from your TV and other devices"
      ctaText="Start Watching"
    />
  </StyledWrapper>
)

const StyledWrapper = styled.div`
  @media (min-width: 605px) {
    .content-container .annotation-image img {
      top: 35px;
      bottom: unset;
    }
  }
`
