import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectParams, updateParams } from 'src/modules/reducers/params'
export default props => {
  const params = useSelector(selectParams)
  const dispatch = useDispatch()

  // Check referrer for valid visitor from capitaloneoffers.com
  const [validReferrer, setValidReferrer] = React.useState(true)
  React.useEffect(() => {
    const docReferrer = document && document.referrer
    if (
      docReferrer &&
      (docReferrer.includes('experiences.global') ||
        docReferrer.includes('experiencesbenefits.com'))
    ) {
      setValidReferrer(true)
    }
  }, [params])

  React.useEffect(() => {
    if (validReferrer) {
      const irparams = {
        irad: '1511683',
        irmp: '365718',
      }
      dispatch(updateParams(irparams))
    }
  }, [validReferrer])

  const offerBanner = validReferrer ? (
    <>
      ✔ Special offer for AMEX Card Members!
      <br />
      Save up to $75 on a Fubo subscription when you sign up with an AMEX Card.
    </>
  ) : (
    <>
      Offer Not Applied
      <br />
      Please revisit this page from the offer on the AMEX website
    </>
  )

  const miceText = validReferrer
    ? '*Offer applies to first-time Fubo subscribers who sign up for a subscription using an American Express card associated with this offer. Offer valid one time only. Earn a $25 credit each month for 3 months ($75 value), which will automatically be applied as a credit each billing cycle.'
    : false

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerCode={validReferrer ? 'elite-amex-promo-3mon' : null}
      offerBanner={offerBanner}
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      tagline="Watch and DVR the channels you love. No contracts, no commitment."
      // heroCtaNote={validReferrer && '*Offer applies to first-time Fubo subscribers'}
      highlightImage={
        validReferrer &&
        'https://assets-imgx.fubo.tv/marketing/lp/images/devices/LP_group_fubo-devices-min-v2.png?ch=width&auto=format,compress'
      }
      highlightHeadline={validReferrer && 'Save $75 on Live TV'}
      highlightSubtitle={
        validReferrer &&
        'Enjoy $25 off each month for 3 months when you use your American Express card as a payment method.'
      }
      highlightNote={miceText}
      showRSN={true}
    />
  )
}
