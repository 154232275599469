export const Play = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.4719 15.2074C27.1386 15.5923 27.1386 16.5546 26.4719 16.9395L9.97192 26.4657C9.30526 26.8506 8.47192 26.3695 8.47192 25.5997L8.47192 6.54715C8.47192 5.77735 9.30526 5.29622 9.97192 5.68112L26.4719 15.2074Z"
      fill="white"
    />
  </svg>
)
