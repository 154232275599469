import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
export default props => {
  return (
    <StyledContainer>
      <LatinoTemplateDefault
        location={props.location}
        lang="en"
        market="latino"
        video={videos.generic_baseball.videoUrl}
        title="Watch the Caribbean Series live in Spanish"
        tagline="60+ channels of sports, news, soaps, and entertainment in Spanish"
        networkSet="entertainmentLatino"
        customNetworks={['espndeportes']}
        noteLink="/la/caribe/"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 860px;
  }
`
