const Crossmark = ({ fill = '#FAFAFA' }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.228209 1.02698C0.527034 0.72815 1.01153 0.72815 1.31035 1.02698L4.59523 4.31186L7.88011 1.02698C8.17894 0.72815 8.66343 0.72815 8.96226 1.02698C9.26108 1.3258 9.26108 1.81029 8.96226 2.10912L5.67738 5.394L8.96226 8.67888C9.26108 8.97771 9.26108 9.4622 8.96226 9.76103C8.66343 10.0599 8.17894 10.0599 7.88011 9.76103L4.59523 6.47614L1.31035 9.76103C1.01153 10.0599 0.527034 10.0599 0.228208 9.76103C-0.070617 9.4622 -0.070617 8.97771 0.228208 8.67888L3.51309 5.394L0.228209 2.10912C-0.0706167 1.81029 -0.0706167 1.3258 0.228209 1.02698Z"
      fill={fill}
    />
  </svg>
)

export default Crossmark
