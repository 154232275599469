import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerCode="pro-affiliation-15-off"
    offerBanner={
      <>
        ✔ Special offer for ClutchPoints fans:
        <br />
        Enjoy 15% off your first month of Fubo.
      </>
    }
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-clutchpoints.png?w=451&ch=width&auto=format,compress"
    logoHeight="42px"
    video={videos.generic_basketball_baseball.videoUrl}
    title="Watch all your favorite sports with Fubo"
    subtitle="ENJOY BASEBALL, BASKETBALL, SOCCER & MORE"
    tagline="Stream it all live anywhere, from any device"
    networkSet="sports"
    showRSN={true}
  />
)
