import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={
      'https://assets.fubo.tv/marketing/lp/videos/compressed/EN_WorldCup2022_1280x720_LP_15s.mp4'
    }
    staticAnnotation={{
      logoUrl:
        'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/world_cup_qatar_2022_fox_fs1.png?h=100&ch=width&auto=format,compress',
      height: 80,
    }}
    title="Watch the FIFA World Cup Qatar 2022™"
    subtitle="TUNE IN ON FOX AND FS1 with Fubo"
    tagline="Stream live games on your TV and other devices"
    networkHeadline="Watch the FIFA World Cup™ on FOX and FS1 with Fubo"
    networkSet="soccer"
    customNetworks={['fox', 'fs1']}
    sportId={sportIds.Soccer}
  />
)
