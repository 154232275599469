import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

export default props => (
  <StyledWrapper>
    <BallySportsNBA
      location={props.location}
      staticAnnotation={videos.bally_pistons.staticAnnotation}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-detroit-pistons.png?ch=width&auto=format,compress"
      logoHeight="75px"
      title="Watch your Detroit Pistons live"
      subtitle="PLUS GET LOCAL DETROIT SPORTS TALK & MORE"
      tagline="Stream FanDuel Sports Network from any device. Regional restrictions apply."
      poster={
        'https://assets.fubo.tv/marketing/lp/images/posters/detroit-pistons-web-lp.jpg?ch=width&auto=format,compress'
      }
      posterMobile={
        'https://assets.fubo.tv/marketing/lp/images/posters/detroit-pistons-mobile-lp.jpg?ch=width&auto=format,compress'
      }
      teamId={teamIds['Detroit Pistons']}
      offerCode={'us-recu-ga-fanduel_det-mo-14d-sponsorship'}
      offerBanner={<>✔ Special offer activated: Sign up today to enjoy 14 days FREE.</>}
      hideFanduelPlanCards={true}
    />
  </StyledWrapper>
)

const StyledWrapper = styled.div`
  // width: 100%;
  // height: 100%;
`
