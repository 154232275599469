import React from 'react'
import CompareTemplate from 'src/components/template/compare'
import { directvData } from 'src/components/template/compare/components/data/directvData'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

export default props => (
  <StyledContainer>
    <CompareTemplate location={props.location} data={directvData} />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .compare-hero .content h1 {
    ${breakpointQuery.desktop} {
      font-size: 40px;
      gap: 20px;
    }

    @media (min-width: 1000px) {
      font-size: 50px;
    }
  }
`
