import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.latino.videoUrl}
      annotations={videos.latino.annotations}
      title="Save big on live TV for your family"
      // subtitle="FOR ONLY $32.99/mo"
      tagline="60+ channels of sports, news, novelas, and entertainment in Spanish."
      networkSet="entertainmentLatino"
      noteLink="/la/oferta/"
      offerCode="latino-bundle-monthly-v3-trial-10offv2"
      offerBanner={
        <>
          ✔ Special offer activated!
          <br />
          Enjoy $10 off your first month of Fubo.
        </>
      }
      discountedPrice={'$22.99/mo'}
    />
  )
}
