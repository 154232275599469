import React, { useEffect } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        offerCode="premier-aaa-25-off"
        offerBanner={
          <>
            ✔ Special offer for AAA members!
            <br />
            Get 25% off your first month of Fubo Premier.
          </>
        }
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Watch live sports & TV without cable"
        subtitle="ANYTIME. ANYWHERE. FROM ANY DEVICE."
        tagline="Plus, stream NBA games all season with NBA League Pass"
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-aaa.png?ch=width&auto=format,compress"
        logoHeight="60px"
        highlightNBA={true}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div``
