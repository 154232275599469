import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="VH1HD"
    title="Watch VH1 Live Online with Fubo"
    networkName="VH1"
  />
)
