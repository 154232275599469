import {
  CohortingEvent,
  CohortingEvent_EventName,
  CohortingEvent_Properties,
} from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/cohorting_event.ts'
import { formatCohortingMessage } from '../utils.ts'
import { v4 as uuid } from 'uuid'

export { CohortingEvent_Properties as CohortingEventProperties } from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/cohorting_event.ts'

export interface CohortingEventProps {
  name: keyof typeof CohortingEvent_EventName
  properties: CohortingEvent_Properties
  category: string
  subCategory: string
}

export const cohortingEvent = ({ properties, category, subCategory }: CohortingEventProps) => {
  const message: CohortingEvent = {
    event: CohortingEvent_EventName.cohorting_event,
    message_id: uuid(),
    properties: {
      ...properties,
      // @ts-expect-error - Cohorting type does not currently include these.
      attribute: {
        event_category: category,
        event_sub_category: subCategory,
      },
    },
    type: 'track',
  }

  const schema = formatCohortingMessage(CohortingEvent, message)

  return schema
}
