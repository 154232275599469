import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerCode="fubo-affiliation-evergreen-14d-trial"
    offerBanner={
      <>
        ✔ Special offer applied!
        <br />
        Enjoy 14 days FREE of Fubo
      </>
    }
    video={videos.generic_baseball.videoUrl}
    title="Watch baseball live without cable"
    subtitle="CATCH LIVE GAMES ALL SEASON LONG"
    tagline="Stream it live anytime, anywhere, from any device"
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-jomboy.png?w=450&ch=width&auto=format,compress"
    networkSet="baseball"
    league="mlb"
    showRSN={true}
  />
)
