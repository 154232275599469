import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="beIN1HD"
    title="Watch beIN SPORTS Live with Fubo"
    networkName="beIN SPORTS"
  />
)
