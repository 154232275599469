import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.latino.videoUrl}
      annotations={videos.latino.annotations}
      title="Ahorra en TV en vivo para toda la familia"
      subtitle="Accede ahora por solo $19.99/mes los tres primeros meses"
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="entertainmentLatino"
      offerCode="us-recu-mk-latino_comun-mo-7d-13amtoff3-v1"
      offerBanner={
        <>
          ✔ ¡Se ha aplicado la oferta exclusiva de Comun!
          <br />
          Aprovecha $13 de descuento al mes en los próximos 3 meses
        </>
      }
      discountedPrice={'$19.99/mes'}
      cardFootnote="*Se renueva a $32.99/mes después del los tres primeros meses. Pueden aplicarse impuestos y otros cargos. Cancela cuando quieras."
    />
  )
}
