import {
  ChannelExtended,
  ChannelsMap,
} from '@fubotv/js-client/dist/esm/domains/UP/channels/types'
import { promoUPChannelComparator } from '@fubotv/js-client/dist/esm/domains/UP/channels/utils'
import { AddOn, Tag, Plan as UPPlan } from '@fubotv/types/api/UP/products'
import { createSelector } from 'reselect'

import { selectUPState, AppState } from '../'
// import {
//   selectForZeroTodayFlag,
//   selectGrowthCanadaProductSelectFlag,
// } from '~/reducers/stacFlags/'
// import {
//   getFormattedUnit,
//   getFormattedUnitAdjective,
// } from '~/utils/billing/'

import { LocalState } from '.'
import { mapProducts } from './utils'

import type {
  CategorizedAddOns,
  FreeTrialInfoResponse,
  MappedAddOn,
  MappedPlan,
  MappedProductsKey,
} from './types'

type SelectChannelsExtended = (state: AppState) => Array<ChannelExtended>

const selectProductsState: (state: AppState) => LocalState = createSelector(
  selectUPState,
  (products) => products
)

// This is equivalent to the legacy selectAllPlans
export const selectAvailablePlans: (state: AppState) => UPPlan[] = createSelector(
  selectProductsState,
  ({ plans }) => plans
)

export const selectMappedPlans: (
  state: AppState
) => Record<MappedProductsKey, Map<string, MappedPlan>> = createSelector(
  selectProductsState,
  ({ plans }) => mapProducts(plans)
)

export const selectAvailableAddOns: (state: AppState) => AddOn[] =
  createSelector(selectProductsState, ({ addOns }) => addOns)

export const selectMappedAddOns: (
  state: AppState
) => Record<MappedProductsKey, Map<string, MappedAddOn>> = createSelector(
  selectProductsState,
  ({ addOns }) => mapProducts(addOns)
)

export const selectCategorizedAddOns: (state: AppState) => CategorizedAddOns =
  createSelector(selectAvailableAddOns, (addOns: AddOn[]) => {
    return addOns.reduce(
      (acc: any, addOn: AddOn) => {
        // Conditionals are in display order
        if (addOn.tags) {
          addOn.tags.forEach((tag: Tag) => {
            if (tag.slug === 'most_popular') acc.most_popular.push(addOn)
            if (tag.slug === 'sports') acc.sports.push(addOn)
            if (tag.slug === 'premium') acc.premium.push(addOn)
            if (tag.slug === 'entertainment') acc.entertainment.push(addOn)
            if (tag.slug === 'channels') acc.channels.push(addOn)
            if (tag.slug === 'features') acc.features.push(addOn)
          })
        } else {
          // No tags property defaults to "Channels" category
          acc.channels.push(addOn)
        }

        return acc
      },
      {
        most_popular: [],
        sports: [],
        premium: [],
        entertainment: [],
        channels: [],
        features: [],
      }
    )
  })
export const selectIsProductsLoading: (state: AppState) => boolean =
  createSelector(
    selectProductsState,
    ({ isLoadingProducts }) => isLoadingProducts
  )

// export const selectPreselectedPlanKey: (state: AppState) => string =
//   createSelector(
//     selectGrowthCanadaProductSelectFlag,
//     selectMappedPlans,
//     (canadaPreselectedPlanCode, mappedPlans) => {
//       let processedKey = 'quarterly_plans'
//       if (canadaPreselectedPlanCode) {
//         const preselectedPlan = mappedPlans.allByProductCode.get(
//           canadaPreselectedPlanCode
//         )

//         if (preselectedPlan) {
//           const period = getFormattedUnit(
//             preselectedPlan.ratePlan?.billingCycleUnit,
//             preselectedPlan.ratePlan?.billingCycleLength
//           )
//           processedKey = `${getFormattedUnitAdjective(period)}_plans`
//         }
//       }
//       return processedKey
//     }
//   )

export const selectFreeTrialInfo: (state: AppState) => FreeTrialInfoResponse =
  createSelector(selectUPState, (upData) => {
    return upData.products.freeTrialInfo
  })

// export const selectShowUnderlinedZeroTodayOnBanner: (
//   state: AppState
// ) => boolean = createSelector(
//   selectFreeTrialInfo,
//   selectForZeroTodayFlag,
//   ({ hasFreeTrial }, forZeroTodayFlag) =>
//     Boolean(hasFreeTrial && forZeroTodayFlag?.includes('test'))
// )

export const selectAllAvailableAddOns = createSelector(
  selectAvailablePlans,
  (availablePlans) => {
    const allAvailableAddOns = new Set()
    availablePlans.forEach((plan) => {
      plan.addonsIncluded?.forEach((addOn: string) => {
        allAvailableAddOns.add(addOn)
      })
    })

    return allAvailableAddOns
  }
)

export const selectHasShowtimeAddOn = createSelector(
  selectAllAvailableAddOns,
  (allAvailableAddOns) =>
    [...allAvailableAddOns.values()].some((addOn: string) =>
      addOn.includes('showtime')
    )
)

export const selectChannelsMap: (state: AppState) => ChannelsMap =
  createSelector(selectUPState, ({ products }) => products.channels)

export const selectIsChannelsLoading: (state: AppState) => boolean =
  createSelector(
    selectProductsState,
    ({ isLoadingChannels }) => isLoadingChannels
  )

export const selectAllChannelsFetched: (state: AppState) => boolean =
  createSelector(
    selectProductsState,
    ({ allChannelsFetched }) => allChannelsFetched
  )

export const selectAllUPChannels: SelectChannelsExtended = createSelector(
  selectChannelsMap,
  (allChannelsMap) => Object.values(allChannelsMap)
)

// To recreate current logic for returning the promoted channels
// We first filter all channels that have the tag 'promo'
// Then we sort by top channels and alphabetical order
export const selectAllUPPromoChannels: SelectChannelsExtended = createSelector(
  selectAllUPChannels,
  (allChannels) =>
    allChannels
      .filter((channel) => channel.tags.includes('promo'))
      .sort(promoUPChannelComparator)
)

// Generic channel selector by plan code substring
export const selectChannelsByPlanCode = (planCode: string) =>
  createSelector(selectAvailablePlans, (availablePlans) =>
    availablePlans.find(plan => plan.code.includes(planCode))?.channels || []
  )

// Generic channel selector by exact plan code
export const selectChannelsByExactPlanCode = (planCode: string) =>
  createSelector(selectAvailablePlans, (availablePlans) =>
    availablePlans.find(plan => plan.code === planCode)?.channels || []
  )

// Filter channels by tag
export const selectChannelsByTag = (channels, tag: string) =>
  createSelector(channels, (allChannels) =>
    allChannels.filter((channel) => channel?.tags?.includes(tag))
  )

// Most Channels
export const selectMostChannels = createSelector(selectAvailablePlans, (availablePlans) =>
  availablePlans.filter(plan => plan?.channels).sort((a, b) => (b.channels?.length || 0) - (a.channels?.length || 0))[0]?.channels || []
)

// Specific plan selectors using the generic functions
export const selectProChannels = selectChannelsByExactPlanCode('us-pro')
export const selectEliteChannels = selectChannelsByExactPlanCode('us-elite-v2') || selectChannelsByPlanCode('us-elite-v')
export const selectDeluxeChannels = selectChannelsByPlanCode('us-deluxe')
// Select Latino channels
export const selectLatinoChannels = selectChannelsByExactPlanCode('us-latino') || selectChannelsByPlanCode('us-latino')
// Select Canada channels
export const selectCanadaEntertainmentChannels = selectChannelsByExactPlanCode('canada-entertainment') || selectChannelsByPlanCode('canada-entertainment')
export const selectCanadaSportsChannels = selectChannelsByExactPlanCode('canada-essentials') || selectChannelsByPlanCode('canada-essentials')
export const selectCanadaPremiumChannels = selectChannelsByExactPlanCode('canada-premium') || selectChannelsByPlanCode('canada-premium')
// Select Spain channels
export const selectSpainChannels = selectChannelsByExactPlanCode('spain-basic') || selectChannelsByPlanCode('spain-basic')
// Select Fubo Free Channels
export const selectFuboFreeChannels = selectChannelsByTag(selectMostChannels, 'fubo_free')


