type Props = {
  width?: number
  height?: number
  fill?: string
  ariaLabel?: string
}

export function InstagramIcon({
  width = 24,
  height = 24,
  fill = '#ffffff',
  ariaLabel = 'Instagram',
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" role="img" aria-label={ariaLabel}>
      <title>Instagram</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
        <g transform="translate(-1386.000000, -30.000000)" fill={fill} fillRule="nonzero">
          <g transform="translate(1310.000000, 30.000000)">
            <g>
              <g transform="translate(76.000000, 0.000000)">
                <path d="M16.5,0 L7.5,0 C3.3585,0 0,3.3585 0,7.5 L0,16.5 C0,20.6415 3.3585,24 7.5,24 L16.5,24 C20.6415,24 24,20.6415 24,16.5 L24,7.5 C24,3.3585 20.6415,0 16.5,0 Z M21.75,16.5 C21.75,19.395 19.395,21.75 16.5,21.75 L7.5,21.75 C4.605,21.75 2.25,19.395 2.25,16.5 L2.25,7.5 C2.25,4.605 4.605,2.25 7.5,2.25 L16.5,2.25 C19.395,2.25 21.75,4.605 21.75,7.5 L21.75,16.5 Z" />
                <path d="M12,6 C8.6865,6 6,8.6865 6,12 C6,15.3135 8.6865,18 12,18 C15.3135,18 18,15.3135 18,12 C18,8.6865 15.3135,6 12,6 Z M12,15.75 C9.933,15.75 8.25,14.067 8.25,12 C8.25,9.9315 9.933,8.25 12,8.25 C14.067,8.25 15.75,9.9315 15.75,12 C15.75,14.067 14.067,15.75 12,15.75 Z" />
                <circle cx="18.45" cy="5.55" r="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
