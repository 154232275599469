// import React from 'react'
// import USTemplateDefault from 'src/components/template/us'
// import { videos } from 'src/constants/videos'
// export default props => (
//   <USTemplateDefault
//     location={props.location}
//     lang="en"
//     market="us"
//     video={videos.bkfc.videoUrl}
//     offerCode="pro-bkfc-fubo-basic-v3"
//     offerBanner={<>✔ 15% off Fubo discount applied!</>}
//     title="Watch Knucklemania IV on Fubo"
//     subtitle="CATCH THE ACTION LIVE FROM L.A."
//     tagline="Plus over 200 top channels of live TV and sports"
//     ctaText="Start Watching"
//     heroCtaNote={
//       <a href="https://bkfc.live/fubo" target="_blank">
//         Already a Fubo subscriber? <span style={{ textDecoration: 'underline' }}>Click here</span>{' '}
//         to get Knucklemania on Pay-Per-View.
//       </a>
//     }
//     disableFreeTrial={true}
//     networkSet="sports"
//     customNetworks={['fsn']}
//     showRSN={true}
//   />
// )

import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'
export default props => <RedirectTemplate location={props.location} destination="/bkfc/" />
