import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.seasonal_us.videoUrl}
    videoMobile={videos.seasonal_us.videoMobileUrl}
    annotations={videos.seasonal_us.annotations}
    title="Stream live TV and sports"
    subtitle="WATCH FROM YOUR LAPTOP OR MOBILE DEVICE"
    tagline="No inflight Wi-Fi purchase necessary."
  />
)
