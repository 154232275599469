import React from 'react'
import CompareTable from 'src/components/template/compare/components/modules/CompareTable'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import Footnote from 'src/components/template/compare/components/modules/Footnote'
import SectionText from 'src/components/template/compare/components/modules/SectionText'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const ComparePlansSection = ({ competitor, competitorColor, data }) => {
  const { heading, subheading, tableData, note } = data
  return (
    <StyledComparePlansSection>
      <CompareContainer className="content">
        <SectionText heading={heading} subheading={subheading} />
        <CompareTable
          competitor={competitor}
          competitorColor={competitorColor}
          tableData={tableData}
        />
        {note && <Footnote>{note}</Footnote>}
      </CompareContainer>
    </StyledComparePlansSection>
  )
}

export default ComparePlansSection

const StyledComparePlansSection = styled.section`
  .footnote {
    ${breakpointQuery.mobile} {
      font-size: 5.82413px;
      line-height: 5px;
    }
  }
`
