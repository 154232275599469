import {
  UiInteraction,
  UiInteraction_Attribute_EventCategory_EventCategoryValue,
  UiInteraction_Attribute_EventSubCategory_EventSubCategoryValue,
  UiInteraction_EventName,
  UiInteraction_Properties,
} from '@fubotv/fubo-schema-ts/features/growth/v3/tracking/ui_interaction.ts'
import { JsonValue } from '@protobuf-ts/runtime'
import { v4 as uuid } from 'uuid'

import { formatMessage } from '../utils.ts'

export interface UiInteractionProps {
  name: keyof typeof UiInteraction_EventName
  properties: UiInteraction_Properties
  category: keyof typeof UiInteraction_Attribute_EventCategory_EventCategoryValue
  subCategory: keyof typeof UiInteraction_Attribute_EventSubCategory_EventSubCategoryValue
}

/**
 *
 * @function uiInteractionEvent
 * @description Creates a Protobuffer for a ui_interaction event
 * @param {object} input - Object containing property data for ui_interaction
 * track call
 * @returns {UiInteraction} Json UiInteraction Schema
 */
export const uiInteractionEvent = ({
  name,
  properties,
  category,
  subCategory,
}: UiInteractionProps): JsonValue => {
  const message: UiInteraction = {
    event: UiInteraction_EventName.ui_interaction,
    message_id: uuid(),
    properties: {
      ...properties,
      attribute: {
        event_category: UiInteraction_Attribute_EventCategory_EventCategoryValue[category],
        event_sub_category:
          UiInteraction_Attribute_EventSubCategory_EventSubCategoryValue[subCategory],
      },
    },
    type: 'track',
  }

  return formatMessage(UiInteraction, message)
}
