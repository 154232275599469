import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
export default props => (
  <BallySportsMLB
    location={props.location}
    offerCode="fubo-affiliation-evergreen-14d-trial"
    offerBanner={
      <>
        ✔ Special offer for diehard PHNX fans!
        <br />
        Get 14 days FREE of Fubo
      </>
    }
    video={videos.bally_diamondbacks.videoUrl}
    // staticAnnotation={videos.bally_diamondbacks.staticAnnotation}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-phnx.png?w=451&ch=width&auto=format,compress"
    logoHeight="42px"
    title="Watch Arizona baseball live"
    subtitle="STREAM THE DIAMONDBACKS ALL SEASON LONG"
  />
)
