import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.concacaf_gold_cup.videoUrl}
      title="Watch the CONCACAF Gold Cup"
      subtitle="CATCH LIVE GAMES ON TUDN"
      tagline="Stream them from any device with Fubo"
      networkSet="soccerLatino"
    />
  )
}
