import React from 'react'
import styled from 'styled-components'
import { defaultCTAURI, USDefaultCtaTextShort } from 'src/modules/utils/constants'
import { offerCodes } from 'src/constants/offerCodes'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { useSelector } from 'react-redux'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { getCtaText } from 'src/modules/utils/language/buttons'

const RedzoneBanner = ({ redzoneOfferCode, ctaText }) => {
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  ctaURL = replaceUrlParam(ctaURL, 'offer', redzoneOfferCode || offerCodes.elite)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'redzone')
  const handleOnClick = e => {
    fireUIInteractionEvent(
      {
        cta_name: 'Choose the Elite with Sports Plus plan to watch',
        container_name: 'redzone_promo_banner_cta_clicked',
      },
      stateForProtobuf
    )
  }

  const ctaCopy = ctaText || USDefaultCtaTextShort

  return (
    <StyledRedzoneBanner>
      <div className="banner-content">
        <div className="banner-text">
          {/* <h4>
            Want NFL Redzone from NFL Network?
          </h4> */}
          <h3>
            {/* <span className="block">
              Get <span className="highlight">NFL Redzone</span> from NFL Network.
            </span>{' '}
            <span className="block">Choose Elite with Sports Plus.</span> */}
            Choose the{' '}
            <a href={ctaURL} className="highlight">
              Elite with Sports Plus
            </a>{' '}
            plan to watch
          </h3>
        </div>
        <div className="banner-logos">
          <span className="banner-image redzone">
            <img
              src="https://assets-imgx.fubo.tv/marketing/lp/images/networks/logo-nfl-redzone-slim.png"
              alt=""
              width="100%"
            />
          </span>
        </div>
        <div className="banner-button">
          <ButtonWrapper>
            <a href={ctaURL} onClick={handleOnClick}>
              {ctaCopy}
            </a>
          </ButtonWrapper>
        </div>
      </div>
    </StyledRedzoneBanner>
  )
}

export default RedzoneBanner

const StyledRedzoneBanner = styled.div`
  background: linear-gradient(90deg, rgb(44, 50, 62) 0.1%, rgb(20, 23, 31) 89.41%);
  width: 100%;
  z-index: 5;
  text-align: left;
  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    margin: 0 auto;
    gap: 20px;
    max-width: 1132px;

    @media screen and (max-width: 1200px) {
      max-width: 1005px;
    }

    @media screen and (max-width: 1000px) {
      gap: 10px;
      padding: 20px 20px;
    }

    @media screen and (max-width: 850px) {
      flex-direction: column;
      gap: 20px;
      padding: 35px 10px;
    }
    .banner-logos {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7%;
      max-width: 240px;

      .banner-image {
        &.redzone {
          flex: 1 1 60%;
        }
        &.showtime {
          flex: 1 1 40%;
        }
      }

      @media screen and (min-width: 851px) and (max-width: 920px) {
        flex-direction: column;
        gap: 10px;
      }

      @media screen and (min-width: 851px) {
        flex: 0 1 330px;
      }
      @media screen and (max-width: 850px) {
        width: 75%;
        max-width: 200px;
        gap: 20px;
      }
    }
    .banner-text {
      color: #fff;
      flex: 0 0 auto;
      padding: 10px 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      @media screen and (max-width: 850px) {
        align-items: center;
        gap: 10px;
        text-align: center;
        padding: 0;
      }
      ${'' /* span {
        font-size: 1rem;
        text-decoration: underline;
      } */}
      span.block {
        display: inline-block;
      }
      h3 {
        font-size: 1.35rem;
        .highlight {
          font-size: 1.45rem;
          text-decoration: underline;
        }
        ${'' /* text-shadow: none; */}
        @media screen and (max-width: 1200px) {
          font-size: 1.3rem;
        }
        @media screen and (min-width: 851px) and (max-width: 1060px) {
          ${'' /* display: flex;
          flex-direction: column; */}
        }
        @media screen and (max-width: 850px) {
          display: flex;
          flex-direction: column;
          gap: 4px;
          justify-content: center;
          font-size: 1.3rem;
        }
      }
      h4 {
        font-size: 0.9rem;
        ${'' /* color: #fa4616; */}
        color: #fff;
        letter-spacing: 0.6px;
        ${'' /* text-shadow: none; */}
        @media screen and (max-width: 1200px) {
          font-size: 0.9rem;
        }
      }
    }
    .banner-button {
    }
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 10px auto;
  a {
    font-size: 23px;
    display: inline-block;
    text-shadow: none;
    background: #fff;
    color: #000;
    padding: 14px 18px;
    border-radius: 3px;
    font-weight: 700;
    font-weight: 700;
    text-align: center;
    width: 280px;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    transition: background 0.5s;
    &:hover {
      background: #d7d7d7;
    }
    @media screen and (max-width: 1200px) {
      width: auto;
      font-size: 21px;
      padding: 16px 30px;
    }
    @media screen and (max-width: 736px) {
      font-size: 20px;
      width: 242px;
      padding: 10px 15px;
    }
  }
`
