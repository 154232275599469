import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/AdobeStock_210894329.jpg?w=2048&ch=width&auto=format,compress"
    title="Watch TyC Sports Live"
    subtitle="STREAM ON YOUR PHONE, TV AND MORE"
    networkSet="sports"
    customNetworks={['tyc']}
  />
)
