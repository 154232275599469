import { getFuboEnv } from 'src/config/env'
import { fireProtoAnalyticsEvent } from './v3'

export const track = (eventName, eventProperties, state) => {
  try {
    window.analytics.track(eventName, eventProperties)
  } catch (err) {
    getFuboEnv() !== 'prod' && console.error(err)
  }
}
