import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
      hasOfferInput={true}
      video={videos.buffalo_sabres.videoUrl}
      videoMobile={videos.buffalo_sabres.videoMobileUrl}
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/20231012__JH_4682_1.jpg?w=4096&ch=width&auto=format,compress"
      staticAnnotation={networkLogos.fsn}
      pageTitle="Watch Buffalo Sabres hockey"
      title={
        <>
          Watch Buffalo Sabres<sup>®</sup> hockey
        </>
      }
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream Fubo Sports Niagara from any device. No cable required. Regional restrictions apply."
      plan="soccer"
      plansSubtitle="Canada’s home of the Buffalo Sabres and exclusive live soccer"
      hasPlanCards={false}
      networkSet="canada"
      customNetworks={['fsn']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-buffalo-sabres-v2.png?ch=width&auto=format,compress"
      logoHeight="100px"
      sportId={sportIds.Hockey}
      leagueId={leagueIds.NHL}
      teamId={teamIds['Buffalo Sabres']}
    />
  )
}
