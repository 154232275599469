import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.fifa_women_world_cup.videoUrl}
    staticAnnotation={videos.fifa_women_world_cup.staticAnnotation}
    title="Watch the FIFA Women's World Cup™"
    subtitle="LIVE ON FOX AND FS1 WITH FUBO"
    tagline="Stream matches from July 20 to August 20"
    networkSet="soccer"
    customNetworks={['fox', 'fs1']}
    sportId={sportIds.Soccer}
  />
)
