import { css as _css, keyframes as _keyframes } from '@emotion/css'
import type { Properties } from 'csstype'


interface CSSProp {
  label?: string
  [propertyName: string]: any
}
interface StyleAttribute {
  [attributeName: string]: ''
}
interface Timeline {
  [timelineValue: string]: CSSProperties
}
export { default as cx } from 'classnames'
type CSSRule = CSSProp | StyleAttribute | null | undefined | false
type CSSProperties = Properties<string | number>
export function css(...rules: CSSRule[]): string {
  return _css(...rules).toString()
}

export function keyframes(timeline: Timeline): string {
  // @ts-expect-error - TS2558 - Expected 0 type arguments, but got 1.
  return _keyframes<CSSProperties>(timeline)
}
