export const development = {
  env: 'development',
  default: { host: 'http://local.fubo.tv:8000' },

  api: {
    host: 'https://qa-api.fubo.tv',
  },
  config: {
    host: 'https://qa.fubo.tv',
  },
  imgixURI: 'https://fubo-landing-qa.imgix.net/',

  // Fubo Imgix path
  imgix: 'https://assets-imgx.fubo.tv',

  amolio: {
    host: 'https://amolio-qa.fubo.tv',
  },
  // launchDarkly feature toggle
  launchDarkly: '5991925d0d67620b12cebc4f',

  // segment.io analytics
  segment: {
    publicKey: 'k9vwcm56po',
  },
  impact: {
    url: 'https://trkapi.impact.com/PageLoad',
    campaignId: 5119,
  },
}
