import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
      hasOfferInput={true}
      video={videos.texas_rangers.videoUrl}
      staticAnnotation={networkLogos.fanduelsports}
      pageTitle="Watch Texas Rangers baseball"
      title={
        <>
          Watch Texas Rangers<sup>®</sup> baseball
        </>
      }
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream FanDuel Sports Southwest from any device. No cable required. Regional restrictions apply."
      networkSet="baseball"
      customNetworks={['fanduelsports']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-texas-rangers.png?w=450&ch=width&auto=format,compress"
      logoHeight="100px"
      league="MLB"
      sportId={sportIds.Baseball}
      leagueId={leagueIds.MLB}
      teamId={teamIds['Texas Rangers']}
    />
  )
}
