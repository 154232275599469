function isStringType(data) {
  return typeof data === 'string'
}

function isArrayType(data) {
    return Array.isArray(data)
}


export {
    isStringType,
    isArrayType,
}