import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { images } from 'src/constants/images'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      poster={images.formula1.url}
      title="Live Grand Prix Races in Spanish"
      subtitle="Stream them from any device"
      tagline="60+ channels of sports, news, novelas, and entertainment in Spanish"
      networkSet="entertainmentLatino"
      noteLink="/la/formula1-es/"
    />
  )
}
