export const kebabCase = str => {
  if (typeof str !== 'string') {
    return '';
  }
  return str
    .trim()                          // Remove leading and trailing spaces
    .toLowerCase()                   // Convert to lowercase
    .replace(/[_\s]+/g, '-')         // Replace spaces and underscores with hyphens
    .replace(/[^a-z0-9-]/g, '')      // Remove non-alphanumeric characters except hyphens
    .replace(/--+/g, '-')            // Replace multiple hyphens with a single hyphen
    .replace(/^-+|-+$/g, '');        // Remove leading and trailing hyphens
};
