import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default props => (
  <NFL
    {...props}
    team="Green Bay Packers"
    teamShort="Packers"
    teamId={teamIds['Green Bay Packers']}
  />
)
