import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      offerBanner={
        <>
          ✔ Limited-time offer for Arsenal fans:
          <br />
          Get your first 3 months of matches for as low as $16.65/mo.
        </>
      }
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/CA_ArsenalFanOrg_LP_9s.mp4'}
      title="Watch Arsenal F.C. live"
      subtitle="SEE YOUR CLUB IN ACTION ALL SEASON LONG"
      tagline="Stream every match exclusively on Fubo"
      plan="soccer"
      networkSet="canada"
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
      logoHeight="90px"
      team="Arsenal"
      // hasScheduleBanner={true}
      planCardOverride="soccerOffer"
      forceAnchorLink={true}
      soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_soccer-arsenal.jpg?w=451&ch=width&auto=format,compress"
    />
  )
}
