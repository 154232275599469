const black = '0,0,0' // #000000, hsl(0, 0%, 0%)
const blue000 = '2,10,13' // #020A0D, hsl(196, 73%, 3%)
const blue020 = '4,22,28' // #04161C, hsl(195, 75%, 6%)
const blue040 = '8,45,58' // #082D3A, hsl(196, 76%, 13%)
const blue060 = '14,79,103' // #0E4F67, hsl(196, 76%, 23%)
const blue080 = '22,125,163' // #167DA3, hsl(196, 76%, 36%)
const blue100 = '43,177,227' // #2BB1E3, hsl(196, 77%, 53%)
const blue120 = '103,199,235' // #67C7EB, hsl(196, 77%, 66%)
const blue140 = '163,221,243' // #A3DDF3, hsl(197, 77%, 80%)
const blue160 = '208,238,249' // #D0EEF9, hsl(196, 77%, 90%)
const blue180 = '238,249,253' // #EEF9FD, hsl(196, 79%, 96%)
const blue200 = '253,254,255' // #FDFEFF, hsl(210, 100%, 100%)
const fublue000 = '0,15,38' // #000F26, hsl(216, 100%, 7%)
const fublue020 = '0,32,79' // #00204F, hsl(216, 100%, 15%)
const fublue040 = '0,47,118' // #002f76, hsl(216, 100%, 23%)
const fublue060 = '9,73,169' // #0949A9, hsl(216, 90%, 35%)
const fublue080 = '35,103,205' // #2367CD, hsl(216, 71%, 47%)
const fublue100 = '62,126,223' // #3E7EDF, hsl(216, 72%, 56%)
const fublue120 = '119,168,241' // #77A8F1, hsl(216, 81%, 71%)
const fublue140 = '166,199,249' // #A6C7F9, hsl(216, 87%, 81%)
const fublue160 = '208,238,249' // #D0EEF9, hsl(196, 77%, 90%)
const fublue180 = '218,232,254' // #DAE8FE, hsl(217, 95%, 93%)
const fublue200 = '243,248,255' // #F3F8FF, hsl(215, 100%, 98%)
const fublue = '0,0,0' // undefined, hsl(0, 0%, 0%)
const green000 = '0,36,6' // #002406, hsl(130, 100%, 7%)
const green020 = '0,56,9' // #003809, hsl(130, 100%, 11%)
const green040 = '0,76,13' // #004C0D, hsl(130, 100%, 15%)
const green060 = '0,96,16' // #006010, hsl(130, 100%, 19%)
const green080 = '0,116,19' // #007413, hsl(130, 100%, 23%)
const green100 = '0,136,23' // #008817, hsl(130, 100%, 27%)
const green120 = '8,157,33' // #089D21, hsl(130, 90%, 32%)
const green140 = '35,177,59' // #23B13B, hsl(130, 67%, 42%)
const green160 = '75,197,95' // #4BC55F, hsl(130, 51%, 53%)
const green180 = '126,217,141' // #7ED98D, hsl(130, 54%, 67%)
const green200 = '156,227,167' // #9CE3A7, hsl(129, 56%, 75%)
const green220 = '187,237,195' // #BBEDC3, hsl(130, 58%, 83%)
const grey000 = '15,16,16' // #0F1010, hsl(180, 3%, 6%)
const grey020 = '25,26,26' // #191A1A, hsl(180, 2%, 10%)
const grey040 = '35,36,36' // #232424, hsl(180, 1%, 14%)
const grey060 = '45,47,47' // #2D2F2F, hsl(180, 2%, 18%)
const grey080 = '55,57,57' // #373939, hsl(180, 2%, 22%)
const grey100 = '65,68,68' // #414444, hsl(180, 2%, 26%)
const grey120 = '75,78,78' // #4B4E4E, hsl(180, 2%, 30%)
const grey140 = '85,88,88' // #555858, hsl(180, 2%, 34%)
const grey160 = '95,99,99' // #5F6363, hsl(180, 2%, 38%)
const grey180 = '115,120,120' // #737878, hsl(180, 2%, 46%)
const grey200 = '125,130,130' // #7D8282, hsl(180, 2%, 50%)
const grey220 = '135,140,140' // #878C8C, hsl(180, 2%, 54%)
const grey240 = '146,150,150' // #929696, hsl(180, 2%, 58%)
const grey260 = '156,160,160' // #9CA0A0, hsl(180, 2%, 62%)
const grey280 = '167,170,170' // #A7AAAA, hsl(180, 2%, 66%)
const grey300 = '177,180,180' // #B1B4B4, hsl(180, 2%, 70%)
const grey320 = '187,190,190' // #BBBEBE, hsl(180, 2%, 74%)
const grey340 = '198,200,200' // #C6C8C8, hsl(180, 2%, 78%)
const grey360 = '208,210,210' // #D0D2D2, hsl(180, 2%, 82%)
const grey380 = '219,220,220' // #DBDCDC, hsl(180, 1%, 86%)
const grey400 = '229,230,230' // #E5E6E6, hsl(180, 2%, 90%)
const grey420 = '239,240,240' // #EFF0F0, hsl(180, 3%, 94%)
const grey440 = '250,250,250' // #FAFAFA, hsl(0, 0%, 98%)
const orange000 = '20,4,0' // #140400, hsl(12, 100%, 4%)
const orange020 = '54,12,0' // #360C00, hsl(13, 100%, 11%)
const orange040 = '88,19,0' // #581300, hsl(13, 100%, 17%)
const orange060 = '122,27,0' // #7A1B00, hsl(13, 100%, 24%)
const orange070 = '156,34,0' // #9C2200, hsl(13, 100%, 31%)
const orange080 = '190,42,0' // #BE2A00, hsl(13, 100%, 37%)
const orange090 = '207,45,0' // #CF2D00, hsl(13, 100%, 41%)
const orange100 = '224,49,0' // #E03100, hsl(13, 100%, 44%)
const orange110 = '255,58,3' // #FF3A03, hsl(13, 100%, 51%)
const orange120 = '255,111,71' // #FF6F47, hsl(13, 100%, 64%)
const orange140 = '255,151,122' // #FF977A, hsl(13, 100%, 74%)
const orange160 = '255,191,173' // #FFBFAD, hsl(13, 100%, 84%)
const orange180 = '255,217,207' // #FFD9CF, hsl(13, 100%, 91%)
const orange200 = '255,244,241' // #FFF4F1, hsl(13, 100%, 97%)
const purple000 = '7,4,17' // #070411, hsl(254, 62%, 4%)
const purple020 = '12,7,30' // #0C071E, hsl(253, 62%, 7%)
const purple040 = '23,14,57' // #170E39, hsl(253, 61%, 14%)
const purple060 = '40,24,98' // #281862, hsl(253, 61%, 24%)
const purple080 = '62,37,153' // #3E2599, hsl(253, 61%, 37%)
const purple100 = '86,52,206' // #5634CE, hsl(253, 61%, 51%)
const purple120 = '131,106,219' // #836ADB, hsl(253, 61%, 64%)
const purple140 = '177,161,233' // #B1A1E9, hsl(253, 62%, 77%)
const purple160 = '211,202,242' // #D3CAF2, hsl(254, 61%, 87%)
const purple180 = '234,230,249' // #EAE6F9, hsl(253, 61%, 94%)
const purple200 = '245,243,252' // #F5F3FC, hsl(253, 60%, 97%)
const red000 = '46,0,0' // #2E0000, hsl(0, 100%, 9%)
const red020 = '85,0,0' // #550000, hsl(0, 100%, 17%)
const red040 = '123,0,0' // #7B0000, hsl(0, 100%, 24%)
const red060 = '157,0,0' // #9D0000, hsl(0, 100%, 31%)
const red080 = '185,0,0' // #B90000, hsl(0, 100%, 36%)
const red100 = '207,18,18' // #CF1212, hsl(0, 84%, 44%)
const red120 = '224,46,46' // #E02E2E, hsl(0, 74%, 53%)
const red140 = '237,84,84' // #ED5454, hsl(0, 81%, 63%)
const red160 = '246,131,131' // #F68383, hsl(0, 86%, 74%)
const red180 = '252,186,186' // #FCBABA, hsl(0, 92%, 86%)
const red200 = '253,214,214' // #FDD6D6, hsl(0, 91%, 92%)
const red220 = '255,242,242' // #FFF2F2, hsl(0, 100%, 97%)
const white = '255,255,255' // #FFFFFF, hsl(0, 0%, 100%)
const yellow000 = '161,129,2' // #A18102, hsl(48, 98%, 32%)
const yellow020 = '178,143,4' // #B28F04, hsl(48, 96%, 36%)
const yellow040 = '195,157,9' // #C39D09, hsl(48, 91%, 40%)
const yellow060 = '211,170,17' // #D3AA11, hsl(47, 85%, 45%)
const yellow080 = '223,183,29' // #DFB71D, hsl(48, 77%, 49%)
const yellow100 = '233,194,46' // #E9C22E, hsl(47, 81%, 55%)
const yellow120 = '241,204,69' // #F1CC45, hsl(47, 86%, 61%)
const yellow140 = '247,215,98' // #F7D762, hsl(47, 90%, 68%)
const yellow160 = '251,225,132' // #FBE184, hsl(47, 94%, 75%)
const yellow180 = '253,236,172' // #FDECAC, hsl(47, 95%, 83%)
const yellow200 = '254,241,192' // #FEF1C0, hsl(47, 97%, 87%)
const yellow220 = '255,246,212' // #FFF6D4, hsl(47, 100%, 92%)

type RGBValues = {
  black: '0,0,0'
  blue000: '2,10,13'
  blue020: '4,22,28'
  blue040: '8,45,58'
  blue060: '14,79,103'
  blue080: '22,125,163'
  blue100: '43,177,227'
  blue120: '103,199,235'
  blue140: '163,221,243'
  blue160: '208,238,249'
  blue180: '238,249,253'
  blue200: '253,254,255'
  fublue000: '0,15,38'
  fublue020: '0,32,79'
  fublue040: '0,47,118'
  fublue060: '9,73,169'
  fublue080: '35,103,205'
  fublue100: '62,126,223'
  fublue120: '119,168,241'
  fublue140: '166,199,249'
  fublue160: '208,238,249'
  fublue180: '218,232,254'
  fublue200: '243,248,255'
  fublue: '0,0,0'
  green000: '0,36,6'
  green020: '0,56,9'
  green040: '0,76,13'
  green060: '0,96,16'
  green080: '0,116,19'
  green100: '0,136,23'
  green120: '8,157,33'
  green140: '35,177,59'
  green160: '75,197,95'
  green180: '126,217,141'
  green200: '156,227,167'
  green220: '187,237,195'
  grey000: '15,16,16'
  grey020: '25,26,26'
  grey040: '35,36,36'
  grey060: '45,47,47'
  grey080: '55,57,57'
  grey100: '65,68,68'
  grey120: '75,78,78'
  grey140: '85,88,88'
  grey160: '95,99,99'
  grey180: '115,120,120'
  grey200: '125,130,130'
  grey220: '135,140,140'
  grey240: '146,150,150'
  grey260: '156,160,160'
  grey280: '167,170,170'
  grey300: '177,180,180'
  grey320: '187,190,190'
  grey340: '198,200,200'
  grey360: '208,210,210'
  grey380: '219,220,220'
  grey400: '229,230,230'
  grey420: '239,240,240'
  grey440: '250,250,250'
  orange000: '20,4,0'
  orange020: '54,12,0'
  orange040: '88,19,0'
  orange060: '122,27,0'
  orange070: '156,34,0'
  orange080: '190,42,0'
  orange090: '207,45,0'
  orange100: '224,49,0'
  orange110: '255,58,3'
  orange120: '255,111,71'
  orange140: '255,151,122'
  orange160: '255,191,173'
  orange180: '255,217,207'
  orange200: '255,244,241'
  purple000: '7,4,17'
  purple020: '12,7,30'
  purple040: '23,14,57'
  purple060: '40,24,98'
  purple080: '62,37,153'
  purple100: '86,52,206'
  purple120: '131,106,219'
  purple140: '177,161,233'
  purple160: '211,202,242'
  purple180: '234,230,249'
  purple200: '245,243,252'
  red000: '46,0,0'
  red020: '85,0,0'
  red040: '123,0,0'
  red060: '157,0,0'
  red080: '185,0,0'
  red100: '207,18,18'
  red120: '224,46,46'
  red140: '237,84,84'
  red160: '246,131,131'
  red180: '252,186,186'
  red200: '253,214,214'
  red220: '255,242,242'
  white: '255,255,255'
  yellow000: '161,129,2'
  yellow020: '178,143,4'
  yellow040: '195,157,9'
  yellow060: '211,170,17'
  yellow080: '223,183,29'
  yellow100: '233,194,46'
  yellow120: '241,204,69'
  yellow140: '247,215,98'
  yellow160: '251,225,132'
  yellow180: '253,236,172'
  yellow200: '254,241,192'
  yellow220: '255,246,212'
}
export const rgbValues: RGBValues = {
  black,
  blue000,
  blue020,
  blue040,
  blue060,
  blue080,
  blue100,
  blue120,
  blue140,
  blue160,
  blue180,
  blue200,
  fublue000,
  fublue020,
  fublue040,
  fublue060,
  fublue080,
  fublue100,
  fublue120,
  fublue140,
  fublue160,
  fublue180,
  fublue200,
  fublue,
  green000,
  green020,
  green040,
  green060,
  green080,
  green100,
  green120,
  green140,
  green160,
  green180,
  green200,
  green220,
  grey000,
  grey020,
  grey040,
  grey060,
  grey080,
  grey100,
  grey120,
  grey140,
  grey160,
  grey180,
  grey200,
  grey220,
  grey240,
  grey260,
  grey280,
  grey300,
  grey320,
  grey340,
  grey360,
  grey380,
  grey400,
  grey420,
  grey440,
  orange000,
  orange020,
  orange040,
  orange060,
  orange070,
  orange080,
  orange090,
  orange100,
  orange110,
  orange120,
  orange140,
  orange160,
  orange180,
  orange200,
  purple000,
  purple020,
  purple040,
  purple060,
  purple080,
  purple100,
  purple120,
  purple140,
  purple160,
  purple180,
  purple200,
  red000,
  red020,
  red040,
  red060,
  red080,
  red100,
  red120,
  red140,
  red160,
  red180,
  red200,
  red220,
  white,
  yellow000,
  yellow020,
  yellow040,
  yellow060,
  yellow080,
  yellow100,
  yellow120,
  yellow140,
  yellow160,
  yellow180,
  yellow200,
  yellow220,
}
export const COLOR_PREFIX = '--fubo-color'

// create the palette with the correct rgb values as a string for ease of use
const setColors = () => {
  const palette: Record<string, any> = {}
  Object.keys(rgbValues).forEach((key) => {
    palette[key] = `rgb(${rgbValues[key]})`
  })
  return palette
}
export const palette = setColors()
