import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"

const StyledFallingSnow = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 999;
  pointer-events: none;

  .snowContainer {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    .roundSmall,
    .roundMedium,
    .roundLarge {
      background: #fff;
      border-radius: 50%;
      position: absolute;
      width: 3px;
      height: 3px;
      z-index: 999;
    }
    .roundMedium {
      width: 4px;
      height: 4px;
    }
    .roundLarge {
      width: 5px;
      height: 5px;
    }
    .starSmall,
    .starMedium,
    .starLarge {
      position: absolute;
      width: 10px;
      height: 10px;
      background: url(http://www.freepngimg.com/thumb/snowflakes/52-snowflake-png-image-thumb.png);
      background-size: 100% 100%;
      z-index: 999;
    }
    .starMedium {
      width: 15px;
      height: 15px;
    }
    .starLarge {
      width: 20px;
      height: 20px;
    }
    .realSmall,
    .realMedium,
    .realLarge {
      position: absolute;
      width: 25px;
      height: 25px;
      background: url(http://pngimg.com/upload/snowflakes_PNG7576.png);
      background-size: 100% 100%;
      z-index: 999;
    }
    .realMedium {
      width: 30px;
      height: 30px;
    }
    .realLarge {
      width: 40px;
      height: 40px;
    }
    .sharpSmall,
    .sharpMedium,
    .sharpLarge {
      position: absolute;
      width: 20px;
      height: 20px;
      background-size: 100% 100%;
      background: url(http://pngimg.com/upload/snowflakes_PNG7559.png);
      background-size: 100% 100%;
      z-index: 999;
    }
    .sharpMedium {
      width: 28px;
      height: 28px;
    }
    .sharpLarge {
      width: 35px;
      height: 35px;
    }
    .ringSmall,
    .ringMedium,
    .ringLarge {
      position: absolute;
      width: 15px;
      height: 15px;
      background-image: url(http://www.freepngimg.com/thumb/snowflakes/13-white-snowflake-png-image-thumb.png);
      background-size: 100% 100%;
      z-index: 999;
    }
    .ringMedium {
      width: 25px;
      height: 25px;
    }
    .ringLarge {
      width: 35px;
      height: 35px;
    }
  }
`

const FallingSnow = () => {
  const snowRef = useRef()

  useLayoutEffect(() => {
    var w = window.innerWidth,
      h = window.innerHeight,
      sizes = ["Small", "Medium", "Large"],
      types = ["round", "star", "real", "sharp", "ring"],
      snowflakes = w / 40

    // window.addEventListener("resize", handleResize)

    for (var i = 0; i < snowflakes; i++) {
      var snowflakeDiv = document.createElement("div")
      var sizeIndex = Math.ceil(Math.random() * 3) - 1 //get random number between 0 and 2
      var size = sizes[sizeIndex] //get random size
      var typeIndex = Math.ceil(Math.random() * 5) - 1
      var type = types[typeIndex]
      gsap.set(snowflakeDiv, { attr: { class: type + size }, x: R(0, w), y: R(-200, -150) })
      snowRef.current.appendChild(snowflakeDiv)
      snowing(snowflakeDiv)
    }

    function snowing(element) {
      gsap.to(element, {
        y: h + 100,
        ease: "Linear.easeNone",
        repeat: -1,
        delay: -15,
        duration: R(8, 15),
      })
      gsap.to(element, {
        x: "+=100",
        repeat: -1,
        yoyo: true,
        ease: "Sine.easeInOut",
        duration: R(4, 8),
      })
      gsap.to(element, {
        rotation: R(0, 360),
        repeat: -1,
        yoyo: true,
        ease: "Sine.easeInOut",
        delay: -5,
        duration: R(2, 8),
      })
    }

    function R(min, max) {
      return min + Math.random() * (max - min)
    }

    return () => {
      snowRef.current.innerHTML = ""
    }
  })

  return (
    <StyledFallingSnow>
      <div className="snowContainer" ref={snowRef}></div>
    </StyledFallingSnow>
  )
}

export default FallingSnow
