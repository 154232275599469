import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="ATTSWHD"
    title="Space City Home Network Live with Fubo"
    networkName="Space City Home Network"
    customNetworks={['spacecity']}
  />
)
