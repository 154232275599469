import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import useEssentialsEligibilityCheck from 'src/modules/hooks/useEssentialsEligibilityCheck'

export default props => {
  const isEssentialsEligible = useEssentialsEligibilityCheck()

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/espn2020.mp4"
      staticAnnotations={networkLogos.espn}
      title="Watch ESPN live with Fubo"
      subtitle="SPORTING EVENTS, NEWS, ANALYSIS AND MORE"
      tagline="Stream it all from your TV, phone and other devices"
      ctaText="Start Watching"
      networkSet="sports"
      hasProPlanCards={isEssentialsEligible}
    />
  )
}
