// src/hooks/useLazyLoad.js
import { useState, useEffect } from 'react';

const useLazyLoad = (src) => {
  const [source, setSource] = useState(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const imgElement = document.querySelector(`[data-src="${src}"]`);

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.disconnect();
          }
        });
      },
      {
        rootMargin: '0px 0px 200px 0px',
        threshold: 0.1,
      }
    );

    if (imgElement) {
      observer.observe(imgElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [src]);

  useEffect(() => {
    if (isIntersecting) {
      setSource(src);
    }
  }, [isIntersecting, src]);

  return source;
};

export default useLazyLoad;
