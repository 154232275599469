import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    lang="en"
    market="us"
    offerCode="pro-denver-nuggets-14d-trial"
    offerBanner="✔ Special offer activated: Enjoy 14 days FREE."
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/Nuggets_1280x720_LP_10s.mp4"
    staticAnnotation={networkLogos.altitude}
    title="Watch Denver basketball live"
    subtitle="STREAM THE NUGGETS ON ALTITUDE SPORTS"
    tagline="Anytime. Anywhere. From any device."
    networkSet="basketball"
    customNetworks={['altitude']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-denver-nuggets.png?ch=width&auto=format,compress"
    logoHeight="100px"
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
    teamId={teamIds['Denver Nuggets']}
  />
)
