import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    lang="en"
    market="us"
    offerCode="pro-affiliation-15-off"
    offerBanner={
      <>
        ✔ Special offer for diehard DNVR fans!
        <br />
        Get 15% off your first month of Fubo
      </>
    }
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-dnvr.png?w=451&ch=width&auto=format,compress"
    logoHeight="42px"
    video={videos.altitude_sports.videoUrl}
    title="Watch Altitude Sports with Fubo"
    subtitle="SEE THE AVALANCHE, NUGGETS & MORE"
    tagline="Stream it live anytime, anywhere, from any device"
    networkSet="sports"
    customNetworks={['altitude']}
  />
)
