import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    //nba league pass addon
    // addonCode="nbalp"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NBAonNBC_CELTICS_LP_20s.mp4'}
    staticAnnotation={networkLogos.nbcsboston}
    title="Watch live Celtics basketball games on NBC Sports Boston"
    subtitle="PLUS GET BOSTON SPORTS TALK AND MORE"
    tagline="Stream NBC Sports Boston from your TV and other devices. Regional restrictions apply."
    ctaText="Learn More"
    networkSet="basketball"
    customNetworks={['nbcsboston', 'nbatv']}
    league="nba"
    showRSN={true}
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
    teamId={teamIds['Boston Celtics']}
  />
)
