import React from 'react'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const CompareContainer = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>
}

export default CompareContainer

const StyledContainer = styled.div`
  max-width: 1000px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  margin-bottom: 40px;

  ${breakpointQuery.desktop} {
    margin-bottom: 70px;
  }
`
