import { SET_APP_REFERRER } from './actionTypes.ts'

export type ImpactState = {
  clickId?: string
  refUrl?: string
}

const initialState: ImpactState = {}

const impactReducer = (state = initialState, action): ImpactState => {
  switch (action.type) {
    case SET_APP_REFERRER:
      return {
        ...state,
        refUrl: action.referrer,
      }

    default:
      return state
  }
}

export default impactReducer
