import { createSelector } from 'reselect'

export const selectZvaState = state => state.zva

export const selectIsZvaInitialized = createSelector(selectZvaState, state => {
  return state.isInitialized
})

export const selectIsZvaLoading = createSelector(selectZvaState, state => {
  return state.isLoading
})
