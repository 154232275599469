import React from 'react'
import LiveTemplateDefault from 'src/components/template/live/canada'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="canada"
    callsign="BEINCHD"
    title="Watch beIN SPORTS with Fubo"
    plan="soccer"
    networkSet="canadaEssentials"
    customNetworks={['fsn']}
  />
)
