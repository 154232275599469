import React from 'react'
import CTAButton from 'src/components/common/CTAButton'
import Layout from 'src/components/common/Layout'
import styled from 'styled-components'

const SurveyTemplate = ({ lang = 'en', market = 'all', url }) => {
  return (
    <Layout lang={lang} market={market}>
      <StyledSurveyTemplate>
        <div className="logo">
          <img
            src="https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_BK_RGB.png?w=300&ch=width&auto=format,compress"
            alt="logo"
          />
        </div>
        <div className="title">
          <h1>Welcome!</h1>
        </div>
        <div className="text">
          <p>Thank you for your interest in Fubo’s beta test group.</p>
          <p>
            To start the short survey, click below. We will get back to you and let you know if
            you’ve been accepted into the program.
          </p>
          <p>Thank you,</p>
          <p>The Fubo Team</p>
        </div>
        <CTAButton link={url}>Start Survey</CTAButton>
      </StyledSurveyTemplate>
    </Layout>
  )
}

export default SurveyTemplate

const StyledSurveyTemplate = styled.div`
  color: #000;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  flex: 1;
  .logo {
    margin-bottom: 40px;
    max-width: 200px;
    img {
      width: 100%;
    }
  }
  .text {
    margin-bottom: 20px;
    a {
      color: #3366cc;
      font-weight: 600;
      text-decoration: underline;
    }
    p {
      font-size: 1.4rem;
    }
  }
`
