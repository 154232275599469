import React, { useEffect, useState } from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        hasOfferInput={true}
        offerBanner="✔ Enter your Marc + Mandy Show promo code to redeem 6 months of Fubo for FREE"
        ctaText="Redeem Offer"
        video={videos.seasonal_ca.videoUrl}
        videoMobile={videos.seasonal_ca.videoMobileUrl}
        annotations={videos.seasonal_ca.annotations}
        title="Watch live sports & entertainment"
        subtitle="ENJOY TOP LEAGUES, POPULAR TV SHOWS & MORE"
        tagline="Stream it all from your TV, tablet & other devices"
        networkSet="canadaEssentials"
        plan="soccer"
        hasPlanCards={false}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 800px;
  }
  .desktop-line-break {
    display: inline-block;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
