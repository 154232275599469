import React from 'react'
import CollegeFootball from 'src/pages/college-football'
import { teamIds } from 'src/constants/personalizationIds'
export default props => (
  <CollegeFootball
    {...props}
    team="Tennessee Volunteers"
    teamId={teamIds['Tennessee Volunteers']}
  />
)
