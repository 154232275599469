import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Layout from 'src/components/common/Layout'
import { useSelector } from 'react-redux'
import { selectCTAParams, selectParams } from 'src/modules/reducers/params'
import { selectCountryCode } from 'src/modules/reducers/market'
import LivePreviewPlayer from 'src/components/common/LivePreviewPlayer'
import { createUrl } from 'src/modules/utils/createUrl'
import { defaultCTAURI } from 'src/modules/utils/constants'
import HeroCTA from 'src/components/template/live-widget/components/heroCTA'

const LiveTemplateDefault = ({ lang = 'en', market = 'all', disableFreeTrial = true }) => {
  const params = useSelector(selectParams)
  const countryCode = useSelector(selectCountryCode)

  const [pageSlug, setPageSlug] = useState(null)

  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = 'https://www.fubo.tv' + createUrl(defaultCTAURI, parsedParams)

  useEffect(() => {
    if (params?.page_slug) {
      setPageSlug(params?.page_slug)
    } else if (countryCode === 'CA' || countryCode === 'CAN') {
      setPageSlug('BEINCHD')
    } else {
      setPageSlug('FS1')
    }
  }, [params?.page_slug, countryCode])

  return (
    <Layout lang={lang} market={market} noFooter={true}>
      <StyledLiveWidget>
        <LivePreviewPlayer
          ctaText={disableFreeTrial && 'Learn More'}
          ctaSubhead={disableFreeTrial && 'Keep watching with Fubo.'}
          callsign={pageSlug}
          lang={lang}
        />
        <HeroCTA link={ctaURL} />
      </StyledLiveWidget>
    </Layout>
  )
}

export default LiveTemplateDefault

const StyledLiveWidget = styled.div`
  background-color: #242424;
  color: #242424;
  margin: 0;
  position: relative;
  max-width: 688px !important;
`
