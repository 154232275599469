import {
  CanadaDefaultCtaText,
  LatinoDefaultCtaText,
  LatinoDefaultCtaText_ES,
  LatinoDefaultCtaTextShort,
  USDefaultCtaText,
  USDefaultCtaText_ES,
  USDefaultCtaTextShort,
} from '../constants'

export const getCtaText = (lang, market, isTextShort = false) => {
  switch (market) {
    case 'latino':
      return lang === 'es'
        ? LatinoDefaultCtaText_ES
        : isTextShort
        ? LatinoDefaultCtaTextShort
        : LatinoDefaultCtaText
    case 'canada':
      return CanadaDefaultCtaText
    default:
      return lang === 'es'
        ? USDefaultCtaText_ES
        : isTextShort
        ? USDefaultCtaTextShort
        : USDefaultCtaText
  }
}
