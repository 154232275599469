import React from 'react'
import { useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectParams } from 'src/modules/reducers/params'
export default props => {
  const params = useSelector(selectParams)

  // Check referrer for valid visitor from capitaloneoffers.com
  const [validReferrer, setValidReferrer] = React.useState(false)
  React.useEffect(() => {
    if (params?.irmp === '101044') {
      setValidReferrer(true)
    }
  }, [params])

  const offerBanner = validReferrer ? (
    <>
      ✔ Special offer for Capital One Members
      <br />
      Save 15% on your first month of Fubo when you sign up with a Capital One Card.
    </>
  ) : (
    <>
      Offer Not Applied
      <br />
      Please revisit this page from the offer on the Capital One website
    </>
  )

  const miceText = validReferrer
    ? '*Offer applies to first-time Fubo subscribers who sign up for a subscription using a Capital One card associated with this offer. Offer valid one time only.'
    : false

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      miceText={miceText}
      offerCode={validReferrer ? 'fubo-affiliation-evergreen-15off' : null}
      offerBanner={offerBanner}
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      tagline="Watch and DVR the channels you love. No contracts, no commitment."
      showRSN={true}
    />
  )
}
