import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <USDefaultTemplate
        location={props.location}
        lang="en"
        market="us"
        video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Bulls_LP_Web_10s.mp4'}
        videoMobile={'https://assets.fubo.tv/marketing/lp/videos/mobile/Bulls_LP_Mobile_10s.mp4'}
        title="Watch Chicago basketball live"
        subtitle={
          <>
            STREAM THE BULLS AND MORE ON{' '}
            <span className="desktop-line-break">CHICAGO SPORTS NETWORK</span>
          </>
        }
        tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
        networkSet="basketball"
        customNetworks={['chsn']}
        sportId={sportIds.Basketball}
        leagueId={leagueIds.NBA}
        teamId={teamIds['Chicago Bulls']}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline-block;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
