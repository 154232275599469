import { getEnvironment } from 'src/config/env/env.js'
import {
  TRY_PAGE_LOAD,
  PAGE_LOAD_SUCCESS,
  PAGE_LOAD_FAIL,
  SetAppReferrerAction,
  SET_APP_REFERRER,
  TryPageLoadAction,
  PageLoadSuccessAction,
  PageLoadFailAction,
} from './actionTypes.ts'
import { impactRadiusPageLoad } from './effects.ts'

// Define action creators
export const tryPageLoad = ({
  deviceId,
  referringUrl,
}: {
  deviceId: string
  referringUrl?: string
}) => ({
  type: TRY_PAGE_LOAD,
  payload: { deviceId, referringUrl },
})

export const pageLoadSuccess = (data: any) => ({
  type: PAGE_LOAD_SUCCESS,
  payload: data,
})

export const pageLoadFail = (error: any) => ({
  type: PAGE_LOAD_FAIL,
  payload: error,
})

export const setAppReferrer = (referrer: string): SetAppReferrerAction => ({
  type: SET_APP_REFERRER,
  referrer,
})

export type ImpactAction =
  | TryPageLoadAction
  | PageLoadSuccessAction
  | PageLoadFailAction
  | SetAppReferrerAction

export const setImpactRadiusPageLoad = ({ referringUrl, deviceId }) => async dispatch => {
  const isProd = getEnvironment().env === 'production'
  const pageUrl = isProd
    ? window?.location?.href
    : `https://qa.fubo.tv${window?.location?.pathname}${window?.location?.search}`
  return await impactRadiusPageLoad({
    PageUrl: pageUrl ?? 'https://www.fubo.tv',
    ReferringUrl: referringUrl
      ? referringUrl
      : document?.referrer?.includes('fubo.tv')
      ? ''
      : document.referrer,
    UserAgent: navigator.userAgent,
    CustomProfileId: deviceId,
  })
    .then(resp => dispatch(pageLoadSuccess(resp)))
    .catch(error =>
      dispatch(
        pageLoadFail((error && error.xhr && error.xhr.response) || error?.response?.body?.status)
      )
    )
}
