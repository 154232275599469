import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/YoungTurks_10s_LP.mp4'}
    staticAnnotation={networkLogos.tyt}
    title="Watch TYT Network"
    subtitle="FOLLOW ALL THE DAY'S BIGGEST HEADLINES"
    tagline="Stream it live from your TV and other devices"
    customNetworks={['tyt']}
  />
)
