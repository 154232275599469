import { getFeatureFlags } from '@fubotv/js-client/dist/esm/domains/featureflags/index'

import type { ResponseData } from '@fubotv/types/api/featureflags'

import { supportedFlags } from './flags'
import pkg from '../../../../package.json'
import type { StacFlagKeys } from './flags'
import { call } from '../api-client'

export const doGetStacFlags = (
  keys: StacFlagKeys = supportedFlags,
  deviceId: string
): Promise<ResponseData> => {
  call.setState({
    clientIdentifiers: {
      applicationId: '',
      browserEngineName: '',
      browserEngineVersion: '',
      browserName: '',
      browserVersion: '',
      clientVersion: '',
      deviceApp: '',
      deviceGroup: '',
      deviceId: deviceId,
      deviceModel: '',
      devicePlatform: '',
      deviceType: '',
      drmSchemeHeader: '',
      osName: '',
      osVersion: '',
      playerVersion: '',
      profileId: '',
      supportedCodecs: '',
      supportedStreamingProtocols: '',
      vendor: '',
    },
  })
  return call(getFeatureFlags, {
    v: 1,
    platform: 'web',
    clientVersion: pkg.version,
    keys,
  })
}