const body_sm = `
  /* Body + Paragraph/Body-S */

  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  letter-spacing: 0.01em;
`

const body_md = `
  /* Body + Paragraph/Body-M */

  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  letter-spacing: 0.01em;
`

export const body = { sm: body_sm, md: body_md }

const heading_xs = `
  
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* or 147% */
`

const heading_sm = `
  /* Heading/Heading-S */

  
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* or 122% */
`

const heading_md = `
/* Heading/Heading-M */


font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 26px;
/* or 130% */
`

const heading_lg = `
  /* Heading/Heading-L */
  
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* or 125% */
`

const heading_xxl = `
/* Heading/Heading-XXL */


font-style: normal;
font-weight: 800;
font-size: 40px;
line-height: 48px;
/* or 120% */
`

export const heading = {
  xs: heading_xs,
  sm: heading_sm,
  md: heading_md,
  lg: heading_lg,
  xxl: heading_xxl,
}

export const typography = { body: body, heading: heading }
