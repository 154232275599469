import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'
import { CaEplDefaultCtaText } from 'src/modules/utils/constants'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/OneSoccer_LP_30s.mp4'}
      title="Watch OneSoccer with Fubo"
      subtitle="THE HOME OF CANADIAN SOCCER"
      tagline="Stream it live from your TV and other devices"
      plan="soccer"
      networkSet="canada"
    />
  )
}
