export function parseQueryParams(url) {
  // Takes the search params from the url, turns it into a URLSearchParams constructor, and creates object from it
  return Object.fromEntries(
    [...new URLSearchParams(new URL(url).search).entries()].map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  )
}

function getSdflagsValue(input) {
  const regex = /sdflags\[(\d+)\]/
  const match = input.match(regex)
  console.log(match, input, 'match')
  return match ? match[1] : null
}

export function parseFlagsQueryParams(params) {
  let flagsObj = {}
  const flagsQuery = Object.keys(params || [])
    .filter(p => p.includes('sdflags'))
    .forEach(key => {
      // Use regex to remove "sdflags[" and "]"
      const newKey = key.replace(/sdflags\[(.*?)\]/, '$1')
      flagsObj[newKey] = params[key]
    })
  return flagsObj
}
