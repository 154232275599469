export const initTranscend = () => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      try {
        if (window.transcend?.ready) {
          dispatch(initTranscendSuccess())
          resolve()
        } else {
          const transcendEl = document.createElement('script')
          transcendEl.src =
            'https://cdn.transcend.io/cm/8bb139e4-7c3e-463f-bcab-dcffc8cf43e4/airgap.js'
          transcendEl.setAttribute('data-cfasync', 'false')
          transcendEl.setAttribute(
            'data-tracker-overrides',
            'FacebookLDUStrict GoogleConsentMode VimeoDNT YouTubePrivacyEnhancedMode'
          )
          transcendEl.setAttribute('data-report-only', 'off')
          transcendEl.async = true
          transcendEl.onload = () => {
            dispatch(initTranscendSuccess())
            resolve()
          }
          transcendEl.onerror = err => {
            console.error('Transcend: error loading script', err)
            dispatch(initTranscendFailed())
            reject(err)
          }
          document && document.body && document.body.appendChild(transcendEl)
        }
      } catch (err) {
        console.error('Transcend: error loading script', err)
        dispatch(initTranscendFailed())
        reject(err)
      }
    })
  }
}

const initTranscendSuccess = () => {
  return {
    type: 'TRANSCEND_INIT_SUCCESS',
  }
}

const initTranscendFailed = () => {
  return {
    type: 'TRANSCEND_INIT_FAILED',
  }
}
