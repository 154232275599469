import { Cohorting } from '@fubotv/fubo-schema-ts/common/v3/generic/cohorting.js'

import { selectStacFlagsState } from 'src/modules/reducers/cohorts'

export const cohortingPropertyMapper = (state): Cohorting[] => {
  const stacFlags = selectStacFlagsState(state).flags
  /**
   * This is a temporary solution to add user tier to the cohorting event.
   * We will remove this once Monet fires this event on tier changes.
   */
  const stacFlagsWithUserTier = {
    ...stacFlags,
  }
  return Object.keys(stacFlagsWithUserTier).map(key => ({
    experiment_name: key,
    experiment_group:
      // @ts-ignore
      typeof stacFlagsWithUserTier[key].value === 'string'
        ? // @ts-ignore
          stacFlagsWithUserTier[key].value
        : // @ts-ignore
          JSON.stringify(stacFlagsWithUserTier[key].value),
  }))
}
