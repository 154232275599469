import React from 'react'
import Checkmark from 'src/components/template/compare/components/icons/CheckmarkSmall'
import CompareCard from 'src/components/template/compare/components/modules/CompareCard'
import { typography } from 'src/components/template/compare/constants/typography'
import styled from 'styled-components'

const CompareCardList = ({ competitor, list, ctaPosition, ...props }) => {
  return (
    <StyledCompareCardList
      hasLogo={true}
      hasButton={true}
      ctaPosition={ctaPosition}
      competitor={competitor}
      {...props}
    >
      <div className="list">
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <span>
                <Checkmark />
              </span>{' '}
              {item}
            </li>
          ))}
        </ul>
      </div>
    </StyledCompareCardList>
  )
}

export default CompareCardList

const StyledCompareCardList = styled(CompareCard)`
  .list {
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        ${typography.body.md}

        display: flex;
        align-items: flex-start;
        letter-spacing: 0.01em;

        /* Grey / Grey-440 */

        color: #fafafa;

        text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);

        span {
          margin-right: 10px;
          width: 10px;
        }
      }
    }
  }
`
