import React from 'react'
import { teamIds } from 'src/constants/personalizationIds'
import CollegeFootball from 'src/pages/college-football'
export default props => (
  <CollegeFootball
    {...props}
    team="Alabama Crimson Tide"
    teamId={teamIds['Alabama Crimson Tide']}
  />
)
