import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      offerBanner={
        <>
          ✔ Special offer applied! <br />
          Get 15% off your first month of Fubo
        </>
      }
      offerCode={'fubo-affiliation-evergreen-15off'}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/PFL_Challenger_20s_LP.mp4'}
      title="Watch the Professional Fighters League"
      subtitle="SEE EVERY FIGHT LIVE with Fubo"
      tagline="Stream these MMA fights from any device"
      networkSet="sports"
    />
  )
}
