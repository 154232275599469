// import React, { useEffect, useState } from 'react'
// import USTemplateDefault from 'src/components/template/us'
// import { videos } from 'src/constants/videos'
// import styled from 'styled-components'

// export default props => {
//   return (
//     <USTemplateDefault
//       location={props.location}
//       lang="en"
//       market="us"
//       offerCode="fubo-affiliation-evergreen-15off"
//       offerBanner={
//         <>
//           Celebrate National Streaming Day!
//           <br />
//           Enjoy 15% off your first month of Fubo.
//         </>
//       }
//       video={videos.seasonal_us.videoUrl}
//       annotations={videos.seasonal_us.annotations}
//       title="Stream Live TV & Sports"
//       subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
//       tagline="Watch and DVR the channels you love. No contracts, no commitment."
//       showRSN={true}
//     />
//   )
// }

import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'
export default props => <RedirectTemplate location={props.location} destination="/tv/" />
