import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.liga_mx.videoUrl}
    staticAnnotation={videos.liga_mx.staticAnnotation}
    title="Watch Liga MX Live on TUDN"
    subtitle="STREAM ON YOUR PHONE, TV AND MORE"
    // tagline="Follow top leagues from all over the world"
    networkSet="soccer"
    customNetworks={['tudn']}
    // noteLink="/liga-mx-es/"
  />
)
