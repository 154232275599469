import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_reds.videoUrl}
    staticAnnotation={videos.bally_reds.staticAnnotation}
    title="Watch Cincinnati baseball live"
    subtitle="STREAM THE REDS ON FANDUEL SPORTS OHIO"
    teamId={teamIds['Cincinnati Reds']}
  />
)
