import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="es"
    market="us"
    video={videos.seasonal_us.videoUrl}
    annotations={videos.seasonal_us.annotations}
    title="Deportes en vivo & on-demand"
    subtitle="DISFRUTA EN TU TV, COMPUTADORA O EN CUALQUIER OTRO DISPOSITIVO."
    tagline="Cuando quieras y como quieras."
    networkSet="sports"
  />
)
