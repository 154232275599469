import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Winter_Olympics_2022_LP_20s_V2.mp4'
    }
    title="Watch the Olympic Winter Games live"
    subtitle="SEE ICE HOCKEY, SKATING AND MORE"
    tagline="Stream it on NBCUniversal from your TV and other devices"
    ctaText="Learn More"
    networkSet="sports"
    customNetworks={['nbc']}
  />
)
