import React, { Component } from 'react'
import styled from 'styled-components'

const FeatureBoxes = () => {
  return (
    <FeaturesWrapper>
      <div className="featureBox onDemand">
        <img
          src="https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/onTheGo_v2.png?auto=format%2Ccompress"
          alt="Dispositivos"
          className="icon"
          srcSet="https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/onTheGo_v2.png?auto=format%2Ccompress 1x, https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/onTheGo_v2.png?auto=format%2Ccompress&amp;dpr=2 2x, https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/onTheGo_v2.png?auto=format%2Ccompress&amp;dpr=3 3x"
        />

        <div className="textContain">
          <h2>Donde Quieras</h2>
          <h3>
            Accede desde tus dispositivos preferidos al contenido en directo o a la carta cuando y
            donde quieras.
          </h3>
        </div>
      </div>
      <div className="featureBox fourk">
        <img
          src="https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/grabarDVR_v2.png?auto=format%2Ccompress"
          srcSet="https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/grabarDVR_v2.png?auto=format%2Ccompress 1x, https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/grabarDVR_v2.png?auto=format%2Ccompress&amp;dpr=2 2x, https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/grabarDVR_v2.png?auto=format%2Ccompress&amp;dpr=3 3x"
          alt="Grabadora"
          className="icon"
        />

        <div className="textContain">
          <h2>Grabadora</h2>
          <h3>
            Desde el inicio te damos 30 horas gratis de espacio para que puedas grabar tu contenido
            favorito y acceder a él cuando quieras.
          </h3>
        </div>
      </div>

      <div className="featureBox dvr">
        <img
          src="https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/bajoDemanda.png?auto=format%2Ccompress"
          srcSet="https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/bajoDemanda.png?auto=format%2Ccompress 1x, https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/bajoDemanda.png?auto=format%2Ccompress"
          alt="Bajo Demanda"
          className="icon"
        />
        <div className="textContain">
          <h2>Bajo Demanda</h2>
          <h3>Puedes ver temporadas completas de las mejores series internacionales.</h3>
        </div>
      </div>
    </FeaturesWrapper>
  )
}

export default FeatureBoxes

const FeaturesWrapper = styled.section`
  background-color: #242526;
  text-align: center;
  padding: 0px 0px 40px 0;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }

  .featureBox {
    padding: 60px 0 70px 0;
    width: 33.33%;
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
  }

  @media only screen and (max-width: 900px) {
    .featureBox {
      width: 100%;
    }
  }

  .featureBox .icon {
    margin: 0 auto 20px auto;
    max-width: 100%;
    width: 114px;
    display: block;
  }

  @media only screen and (max-width: 900px) {
    .featureBox .icon {
      margin: 0 auto 10px auto;
    }
  }

  .featureBox div.textContain {
    width: 100%;
    padding: 0 2%;
    text-align: center;
    box-sizing: border-box;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
  }

  div.textContain h2,
  div.textContain h3 {
    margin-right: auto;
    margin-left: auto;
  }

  .featureBox h2 {
    color: #ffffff;
    line-height: 1.1;
    width: 95%;
    margin: 15px auto;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 900px) {
    .featureBox h2 {
      margin: 8px auto;
      font-size: 24px;
    }
  }

  .featureBox h2 span {
    display: inline-block;
  }

  .featureBox h3 {
    width: 90%;
    font-weight: 700;
    font-size: 22px;
    color: white;
  }

  @media only screen and (max-width: 900px) {
    .featureBox h3 {
      width: 66%;
      font-size: 16px;
    }
  }

  .featureBox.dvr {
    background: radial-gradient(
      ellipse 100% 0% at 0% 50%,
      rgba(160, 205, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.06) 100%
    );
  }

  .featureBox.fourk {
    background: linear-gradient(-225deg, #fa4616 0%, #721e4e 100%),
      radial-gradient(
        ellipse 100% 0% at 0% 50%,
        rgba(160, 205, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%
      );
  }

  .featureBox.onDemand {
    background: linear-gradient(0deg, #2f234c 0%, #130a2c 100%);
  }
`
