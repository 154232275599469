import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/PFL_Challenger_20s_LP.mp4'}
      title="Watch the Professional Fighters League"
      subtitle="SEE EVERY FIGHT LIVE with Fubo"
      tagline="Stream these MMA fights from any device"
      networkSet="sports"
      sportId={sportIds['Mixed martial arts']}
      leagueId={leagueIds['Professional Fighters League']}
    />
  )
}
