import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/UtahHockey_Static_LP_Web.jpg?ch=width&auto=format,compress"
    posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/UtahHockey_Static_LP_Mobile.jpg?ch=width&auto=format,compress"
    title="Watch Utah hockey live"
    subtitle="STREAM GAMES ON KUPX"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="hockey"
    sportId={sportIds.Hockey}
    leagueId={leagueIds.NHL}
    teamId={teamIds['Utah Hockey Club']}
    customNetworks={['kupx']}
  />
)
