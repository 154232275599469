import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/ACCCBB2020.mp4"
    title="Enjoy ACC Basketball on Fubo"
    subtitle="GET LIVE GAMES, ANALYSIS AND MORE"
    tagline="Stream it from your TV, phone and other devices"
    staticAnnotations={{
      logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/CBB_ACCN.png',
    }}
  />
)
