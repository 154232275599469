import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { images } from 'src/constants/images'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      offerCode={'canada-month-entertainment-plus'}
      ctaText="Get Access"
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/CA_Ent_Superchannel_bundle_hero.jpg?w=2048&ch=width&auto=format,compress"
      title="Big savings on top TV channels"
      subtitle="SUPER CHANNEL AND YOUR FAVORITE STACKTV NETWORKS"
      plan="entertainment"
      networkSet="canada"
      networkHeadline="Watch the shows and movies you love"
      networkSubtitle="Live, on-demand and streaming on all your devices"
      customNetworks={[
        'global',
        'hgtvca',
        'foodnetworkca',
        'slice',
        'heartandhome',
        'fuse',
        'vault',
      ]}
      planCardOverride={'superChannelOffer'}
    />
  )
}
