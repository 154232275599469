import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/DWTS2021.mp4"
    staticAnnotations={networkLogos.abc}
    title="Watch Dancing with the Stars on ABC"
    subtitle="NEW EPISODES EVERY MONDAY"
    tagline="Stream them live from your TV and other devices"
    ctaText="Start Watching"
  />
)
