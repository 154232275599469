import React from 'react'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => {
  return (
    <BallySportsNHL
      location={props.location}
      video={videos.bally_lightning.videoUrl}
      staticAnnotation={videos.bally_lightning.staticAnnotation}
      title="Watch Tampa Bay hockey live"
      subtitle="STREAM THE LIGHTNING ON FANDUEL SPORTS SUN"
      teamId={teamIds['Tampa Bay Lightning']}
      showFDOnly={true}
    />
  )
}
