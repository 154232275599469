import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { nexstarTweets } from 'src/constants/nextstarTweets'

export default props => {
  useEffect(() => {
    // Randomly select a string from the array
    const randomString = nexstarTweets('@NXSTMediaGroup', 'Nexstar')[
      Math.floor(Math.random() * nexstarTweets('@NXSTMediaGroup', 'Nexstar').length)
    ]

    // Encode hash symbols in the randomString
    const encodedString = randomString.replace(/#/g, '%23')

    // Redirect to the desired external URL with the random string appended
    window.location.href = `https://twitter.com/intent/tweet?text=${encodedString}`
  }, []) // Empty dependency array ensures this effect runs once when the component mounts

  return (
    <>
      <Helmet>
        <meta name="referrer" content="no-referrer" />
      </Helmet>
    </>
  )
}
