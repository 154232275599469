import { Language } from '@fubotv/types/api/appconfig'

// import { getPageOptions } from '~/reducers/analytics/effects.js' //TODO: verify if needed
// import { AppState } from '~/reducers/index.js' //TODO: are we using state?
// import { getEventContext } from '~/utils/simpleAnalytics.js' //TODO: verify if needed

import { AnalyticsEvent, eventFormatter as eventFormatterV3 } from './v3.ts'
import { environment } from 'src/config/env/env.js'
import { getIdentityInfo, stripNil } from './utils.ts'
import { getPageOptions } from '../context/index.ts'
import { getUniqueIdCookie } from '@fubotv/fubo-cookie'
import { selectMarketState } from 'src/modules/reducers/market/selectors.js'
import { cohortingPropertyMapper } from '../utils/cohortingPropertyMapper.ts'
// import { environment } from '../../../config/environment/index.js'

// this payload maps to the `properties` key of the page_view event
// https://github.com/fubotv/fubo-schema/blob/master/schema/protobuf/services/papi/v3/tracking/page_view.proto

export const fireProtoAnalyticsEvent = (
  event: AnalyticsEvent,
  state,
  optionalFields?: { [key: string]: any }
) => {
  let analyticsEvent = {}
  if (typeof window !== 'undefined' && window.analytics) {
    try {
      window.analytics.ready(() => {
        const amplitudeInstance = window?.amplitude?.getInstance()
        const amplitudeDeviceId = amplitudeInstance?.options?.deviceId
        const uniqueId = amplitudeDeviceId || getUniqueIdCookie()
        const { market } = state

        const eventContext = {
          ...getPageOptions(),
          cohorting: cohortingPropertyMapper(state),
          market: market.market,
          lang: market.lang,
          sport_id: market?.sportId?.toString(),
          team_id: market?.teamId?.toString(),
          league_id: market?.leagueId?.toString(),
        }

        event.eventContext = { ...eventContext, ...event?.eventContext }
        analyticsEvent = eventFormatterV3({
          event,
          locationData: market,
          language: market?.language,
          uniqueId,
        })
        window.analytics.track(event.name, analyticsEvent.properties)
      })
    } catch (err) {
      environment.environment.env !== 'production' && console.error(err)
    }
  }
  return { ...analyticsEvent }
}

export const fireProtoPageView = async state => {
  return fireProtoAnalyticsEvent(
    {
      name: 'page_view',
      category: 'acquisition_context',
      subCategory: 'navigation',
    },
    state
  )
}

export const fireUIInteractionEvent = (properties, state) => {
  fireProtoAnalyticsEvent(
    {
      name: 'ui_interaction',
      category: 'marketing',
      subCategory: 'marketing',
      eventContext: properties,
    },
    state
  )
}

export const fireUIRenderEvent = (properties, state) => {
  fireProtoAnalyticsEvent(
    {
      name: 'ui_render',
      category: 'user_action',
      subCategory: 'element_render',
      eventContext: properties,
    },
    state
  )
}

export const fireCohortingEvent = state => {
  fireProtoAnalyticsEvent(
    {
      name: 'cohorting_event',
      category: 'experiment',
      subCategory: 'growth',
    },
    state
  )
}
