import React from 'react'
import SpainTemplateDefault from 'src/components/template/es'

const carouselImages = [
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/8_dora.jpg',
    alt: 'Dora',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/9_bubble-guppies.jpg',
    alt: 'Bubble Guppies',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/10_top-wing.jpg',
    alt: 'Top Wing',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/11_blaze.jpg',
    alt: 'Blaze',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/12_butterbeans-cafe.jpg',
    alt: "Butterbean's Cafe",
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/13_patrulla-canina.jpg',
    alt: 'Patrulla Canina',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/1_bob-esponja.jpg',
    alt: 'Bob Esponja',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/2_henry-danger.jpg',
    alt: 'Henry Danger',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/3_casa-locos.jpg',
    alt: 'Casa Locos',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/4_los-thundermans.jpg',
    alt: 'Los Thundermans',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/5_nrdd.jpg',
    alt: 'NRDD',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/6_aventuras-en-la-ciudad.jpg',
    alt: 'Aventuras en la Ciudad',
  },
  {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/keyart/7_rusty-rivets.jpg',
    alt: 'Rusty Rivets',
  },
]

const annotations = [
  {
    startSecs: '0',
    endSecs: '4',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/bobesponja.png',
  },
  {
    startSecs: '4',
    endSecs: '9.6',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/topwing.png',
  },
  {
    startSecs: '9.6',
    endSecs: '12.6',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/unacasadelocos.png',
  },
  {
    startSecs: '12.6',
    endSecs: '16.5',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/pawpatrol.png',
  },
  {
    startSecs: '15.5',
    endSecs: '21.1',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/blaze.png',
  },
  {
    startSecs: '21.1',
    endSecs: '23.9',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/butterbeanscafe.png',
  },
  {
    startSecs: '23.9',
    endSecs: '27',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/henrydanger.png',
  },
  {
    startSecs: '27',
    endSecs: '31',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-nickelodeon-nickjr/annotations/nickyrickydickydawn.png',
  },
]

const pageTitle = 'Ve Nickelodeon y Nick Jr. en Directo'
const pageDescription =
  'Disfruta de la mejor TV online. Las mejores series, dibujos infantiles de Nickelodeon y Nick Jr., realities de MTV, programas de entretenimiento para toda la familia y muchos más. En cualquier dispositivo: Ordenador, iOS, Android, Tablet…'

export default props => {
  return (
    <SpainTemplateDefault
      location={props.location}
      lang="es"
      market="spain"
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/spain_nick_nickjr.mp4"
      title="DESDE SOLO 2,99€/mes"
      subtitle="LOS MEJORES CANALES DE DIBUJOS Y SERIES INFANTILES"
      tagline="Disfrútalas en todos tus dispositivos, en directo y bajo demanda"
      annotations={annotations}
      carouselTitle="DESCUBRE LO MÁS DESTACADO DE CADA CANAL"
      carouselImages={carouselImages}
    />
  )
}
