export const offerCodes = {
  essential: 'us-recu-ga-essential-mo-7d-35amtoff1-v1',
  pro: 'us-recu-ga-pro-mo-7d-30amtoff1-v1',
  elite: 'us-recu-ga-elite-mo-7d-30amtoff1-v1',
  // premier: 'us-recu-ga-deluxe-mo-7d-30amtoff1-v1',
  latino: 'us-recu-ga-latino-mo-nt-23amtoff1-v1',
  latino_qt: 'latino-bundle-quarterly-v6',
  // Canada 7-day free trial added 10/1/24
  canada_ent_mo: 'ca-recu-ga-entertainment-mo-7d-v1',
  canada_ent_qt: 'ca-recu-ga-entertainment-qt-7d-v1',
  canada_sports_mo: 'ca-recu-ga-sports-mo-nt-5amtoff1-v1',
  canada_sports_qt: 'ca-recu-ga-sports-qt-nt-44amtoff1-v1',
  canada_sports_yr: 'ca-recu-ga-sports-yr-nt-80amtoff1-v1',
  canada_premium_mo: 'ca-recu-ga-premium-mo-nt-v1',
  canada_premium_qt: 'canada-quarter-premium-v3-up-nt',
  canada_premium_yr: 'ca-recu-ga-premium-yr-nt-v1',
}
