import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_mavericks.videoUrl}
    staticAnnotation={videos.bally_mavericks.staticAnnotation}
    title="Watch Dallas Mavericks basketball"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    teamId={teamIds['Dallas Mavericks']}
  />
)
