import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import LiveTemplateDefault from 'src/components/template/live'
import { selectPostalCode } from 'src/modules/reducers/market'
// import { ballySportsPostalCodes } from 'src/constants/ballySports'

export default props => {
  const [callsign, setCallsign] = useState(null)
  const [networkName, setNetworkName] = useState(null)
  const [loadingText, setLoadingText] = useState(null)

  const postalCode = useSelector(selectPostalCode)

  useEffect(() => {
    // Define the function to fetch postal code data
    const fetchPostalCodeData = async () => {
      try {
        const response = await fetch(
          'https://qa.fubo.tv/stream/data/bally_sports_postal_codes.json'
        )
        // const response = await fetch(
        //   'http://local.fubo.tv:8000/data/bally_sports_postal_codes.json'
        // )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        const market = data[postalCode]
        if (market) {
          setCallsign(market.callsign)
          setNetworkName(market.rsnName)
          setLoadingText(null)
        } else {
          setLoadingText('Preview not available in your region.')
        }
      } catch (error) {
        console.error('There was a problem fetching the postal code data:', error)
        setLoadingText('Error loading data')
      }
    }

    if (postalCode) {
      fetchPostalCodeData()
    }
  }, [postalCode])

  return (
    <LiveTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      callsign={callsign}
      title="Watch FanDuel Sports Live with Fubo"
      networkName={networkName}
      forceCallsign={true}
      loadingText={loadingText}
    />
  )
}
