import { palette } from './palette'
import { rgba } from './utils'

// Every color token can be:
// a) hex value like #345 or #0011AB
// b) rgb value like rgb(10,20,30)
// c) one of the palette colors: palette.orange100
// d) color with transparency set (with basic color chosen from any a, b, or c):
//    rgba('#abc', 0.4), rgba(palette.black, 0.5)
// e) mix of two colors, where each of them is either a, b or _rgbValues_ color (not the palette one!):
//    mix(0.5, rgbValues.white, rgbValues.black)
//
// After initialization FE sets the CSS variable for every token listed here with name built according
// to the object structure:
// external.facebookBlue: '#3170AA' becomes variable --fubo-color-external-facebook-blue: 49,112,170
// or player.timeslider.progress: palette.orange100 becomes variable
// --fubo-color-player-timeslider-progress: var(--fubo-color-orange100)
// and REPLACES the original color in colorTokens object with link to that CSS variable:
// facebookBlue: '#3170AA' becomes facebookBlue: 'rgb(var(--fubo-color-external-facebook-blue))
// just like it happens with every palette color (writing autogenerator would be very complex task).
//
// The only exception is the rgba() value that is left as is:
// for player.timeslider.rail: rgba(palette.white, 0.3) we will have CSS variable set
// --fubo-color-player-timeslider-rail = rgba(var(--fubo-color-white), 0.3)
// and original player.timeslider.rail value replaced with "var(--fubo-color-player-timeslider-rail).
// Though hiding quite complex logic beneath this simplifies adding the new color tokens based on direct values or
// palette tokens while keeping most of them CSS variables.

const external: Record<string, string> = {
  facebookBlue: '#3170AA',
  twitterBlue: '#22C2F2',
  instagramBlue: '#405DE6',
  socialAppleBlack: '#030303',
  socialFacebookBlue: '#3579EA',
  socialGoogleBlue: '#4285F4',
  androidGreen: '#4B7D2F',
  fireTvOrange: '#F37723',
  rokuPurple: '#662D91',
  bcrfPink: '#D0006F',
  bcrfDarkPink: '#880049',
}
export const colorTokens = {
  brand: palette.orange100,
  danger: palette.red100,
  focusBlue: '#599EEB',
  accessibleOrange: '#E03100',
  // used for button bg, should this replace brand color?
  external,
  player: {
    timeslider: {
      // Type casting progress and rail here to help the use of `typeof` below
      progress: palette.orange100 as string,
      rail: rgba(palette.white, 0.3) as string,
      ticks: palette.black as string,
    },
  },
} as const
