import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    offerBanner={
      <>
        ✔ Special offer applied!
        <br />
        Get 15% off your first month of Fubo
      </>
    }
    offerCode="fubo-affiliation-evergreen-15off"
    lang="en"
    market="us"
    video={videos.seasonal_us.videoUrl}
    videoMobile={videos.seasonal_us.videoMobileUrl}
    annotations={videos.seasonal_us.annotations}
    title="Movers save 15% on Fubo"
    subtitle="200+ channels of live sports and TV"
    tagline="No installation. Watch now on all your devices."
  />
)
