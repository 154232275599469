import React from 'react'
import styled from 'styled-components'

import { USDefaultCtaTextShort, defaultCTAURI } from 'src/modules/utils/constants'

import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import CTAButton from 'src/components/common/CTAButton'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'

const HighlightNBA = () => {
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  const ctaCopy = USDefaultCtaTextShort

  const handleOnClick = e => {
    fireUIInteractionEvent(
      {
        cta_name: ctaCopy,
        container_name: 'free_trial_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'nbaleaguepass')

  return (
    <StyledHighlightNBA>
      <div className="container">
        <div className="highlight-content">
          <h4 className="header">
            NBA League Pass included! <br />
            Follow your favorite NBA teams and players
          </h4>
          <div className="button-row">
            <div className="logo-image">
              <img src="https://assets-imgx.fubo.tv/marketing/lp/images/networks/nba-league-pass_c.png?ch=width&auto=format,compress" />
            </div>
            <CTAButton link={ctaURL} onClick={handleOnClick}>
              {ctaCopy}
            </CTAButton>
          </div>
        </div>
        <div className="highlight-image">
          <img src="https://assets-imgx.fubo.tv/marketing/lp/images/cutouts/nba_league_pass_players.png?ch=width&auto=format,compress" />
        </div>
      </div>
    </StyledHighlightNBA>
  )
}

export default HighlightNBA

const StyledHighlightNBA = styled.div`
  box-sizing: border-box;
  /* Define a linear gradient from left (blue) to right (white) */
  background: linear-gradient(to right, #0264fa 75%, #ffffff 100%),
    radial-gradient(circle at 90% 50%, #0264fa, #ffffff);

  @media screen and (max-width: 736px) {
    background: #0264fa;
  }

  /* Ensure the gradients cover the entire background */
  background-size: 100% 100%, cover;

  /* Fix the position of the radial gradient at the top right corner */
  background-position: center, top right;

  /* Make sure both gradients are displayed */
  background-repeat: no-repeat;

  /* Adjust the size of the radial gradient if necessary */
  background-size: cover, 50%;

  /* Other styling as needed */
  width: 100%; /* Make the element full width */
  @media (max-width: 600px) {
  }

  .container {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 736px) {
      flex-direction: column;
    }
    > div {
      flex: 0 1 50%;
    }
  }
  .highlight-content {
    padding: 40px;
    width: 100%;
    @media screen and (max-width: 736px) {
      padding-bottom: 0;
    }
  }
  .header {
    color: #fff;
    font-size: 22px;
    margin-bottom: 20px;
    @media screen and (max-width: 736px) {
      text-align: center;
    }
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    @media screen and (max-width: 736px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    .logo-image {
      max-width: 300px;
      @media screen and (min-width: 737px) and (max-width: 900px) {
        max-width: 250px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .highlight-image {
    line-height: 0;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      max-width: 500px;
    }
  }

  .CTAButton {
    ${'' /* background: #fff; */}
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
    padding: 14px 24px;
    width: 100%;
    max-width: 260px;
    font-weight: 700;
    text-shadow: none;
    font-size: 23px;
    transition: background 0.3s, border-radius 0.3s, color 0.3s;
    &:hover {
      background: #fff;
      color: #000;
    }
    @media screen and (max-width: 1200px) {
      width: auto;
      font-size: 21px;
      padding: 16px 30px;
    }
    @media screen and (max-width: 900px) {
      width: auto;
      font-size: 20px;
      padding: 12px 20px;
    }
    @media screen and (max-width: 736px) {
      width: 242px;
      font-size: 20px;
      padding: 10px 15px;
    }
  }
`
