import React from 'react'
import CompareCard from 'src/components/template/compare/components/modules/CompareCard'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import { typography } from 'src/components/template/compare/constants/typography'
import styled from 'styled-components'

const CompareCardFaq = ({ question, children, ...props }) => {
  return (
    <StyledCompareCardFaq hasLogo={false} hasButton={false} {...props}>
      <div className="faq">
        <h4>{question}</h4>
        <div className="card-content">{children}</div>
      </div>
    </StyledCompareCardFaq>
  )
}

export default CompareCardFaq

const StyledCompareCardFaq = styled(CompareCard)`
  border: none;
  .faq {
    width: 100%;
    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 12.6344px;
      line-height: 16px;
      /* identical to box height, or 130% */

      color: #e5e6e6;
      text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);

      margin-bottom: 9px;

      ${breakpointQuery.desktop} {
        ${typography.heading.md}
        margin-bottom: 14px;
      }
    }
    .card-content {
      font-style: normal;
      font-weight: 500;
      font-size: 10.1075px;
      line-height: 13px;
      /* or 125% */
      letter-spacing: 0.01em;

      color: #d0d2d2;

      ${breakpointQuery.desktop} {
        ${typography.body.md}
      }

      a {
        color: #1c9fd0;
      }
    }
  }
`
