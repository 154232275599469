import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle'
import { fireUIInteractionEvent } from '../analytics/v3'
import { selectStateForProtobuf } from '../reducers'

function useTrackScroll() {
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  // Ref to keep track of whether the event has been fired
  const hasTrackedRef = useRef(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY // Number of pixels scrolled vertically
      const windowHeight = window.innerHeight // Browser window height
      const scrolledPercentage = (scrollY / windowHeight) * 100

      if (scrolledPercentage > 75 && !hasTrackedRef.current) {
        hasTrackedRef.current = true // Set the ref to true to indicate the event has been tracked

        fireUIInteractionEvent(
          {
            container_name: 'scrolled_browser',
            cta_name: '75%',
          },
          stateForProtobuf
        )
      }
    }

    // Throttle the scroll event handler for performance
    const throttledHandleScroll = throttle(handleScroll, 100)

    window.addEventListener('scroll', throttledHandleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [stateForProtobuf.market])
}

export default useTrackScroll
