import React, { useEffect, useState } from 'react'
import Checkmark from 'src/components/template/compare/components/icons/CheckmarkSmall'
import CompareCard from 'src/components/template/compare/components/modules/CompareCard'
import { breakpoint, breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import { typography } from 'src/components/template/compare/constants/typography'
import styled from 'styled-components'

const CompareTableCard = ({ competitor, list, ctaPosition, labels, ...props }) => {
  const [ctaText, setCtaText] = useState(undefined)

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      if (windowWidth < breakpoint.mobile) {
        setCtaText('Start Trial')
      }
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Call handleResize immediately to set initial state
    handleResize()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <StyledCompareTableCard
      hasLogo={true}
      hasButton={true}
      ctaPosition={ctaPosition}
      ctaText={ctaText}
      competitor={competitor}
      labels={labels}
      {...props}
    >
      <div className="list">
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              {!competitor && (
                <>
                  <span>
                    <Checkmark />
                  </span>{' '}
                </>
              )}
              {item}
            </li>
          ))}
        </ul>
      </div>
    </StyledCompareTableCard>
  )
}

export default CompareTableCard

const StyledCompareTableCard = styled(CompareCard)`
  ${props =>
    props.labels &&
    `{
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 0;
  }`}
  flex: ${props => (props.labels ? '0 1 0' : '1')};
  ${breakpointQuery.mobile} {
    gap:24px;
    padding-left: ${props => (props.labels ? '0' : '8px')};
    padding-right: ${props => (props.labels ? '0' : '8px')};
  }
  ${breakpointQuery.mobile} {
    .cta {
      ${props =>
        props.labels &&
        `{
          display: none;
        }`}
        width: 100%;
      a {
        ${'' /* border-radius: 4px; */}
        padding: 2px 8px;
        width: auto;
        height: auto;
        span {
          ${typography.body.xs}
          font-size:12px;
        }
      }
    }
  }
  .list {
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 32px;
      li {
        ${typography.body.md}
        font-weight: 700;
        display: flex;
        align-items: flex-start;
        letter-spacing: 0.01em;

        /* Grey / Grey-440 */

        color: #fafafa;

        text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);

        ${breakpointQuery.mobile} {
          font-size: 10px;
          white-space: nowrap;
        }

        span {
          margin-right: 8px;
          width: 10px;
          ${breakpointQuery.mobile} {
            margin-right: 4px;
          }
        }
      }
    }
  }
`
