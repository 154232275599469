export const updateParams = params => {
  return {
    type: 'UPDATE_PARAMS',
    payload: params,
  }
}

export const deleteParam = paramKey => ({
  type: 'DELETE_PARAM',
  payload: paramKey,
})
