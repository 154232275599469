const Checkmark = ({ fill = '#FAFAFA' }) => (
  <svg width="100%" height="100%" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.12295 8.38605C4.29138 8.38604 4.45252 8.31734 4.56914 8.19581L10.8002 1.70245C11.0367 1.45601 11.0286 1.06454 10.7822 0.828059C10.5358 0.591581 10.1443 0.599651 9.9078 0.846084L4.12291 6.87449L1.52395 4.1664C1.28746 3.91998 0.895984 3.91193 0.649564 4.14842C0.403143 4.38491 0.395093 4.77638 0.631584 5.0228L3.67675 8.19584C3.79337 8.31736 3.95452 8.38605 4.12295 8.38605Z"
      stroke="white"
      strokeWidth="0.309208"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill}
    />
  </svg>
)

export default Checkmark
