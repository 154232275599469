import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
import RedirectTemplate from 'src/components/template/redirect'
import { parseQueryParams } from 'src/modules/utils/parseQueryParams'

const redirectDict = {
  ATTPTHD: 'att-sportsnet-pittsburgh',
  ATTSWHD: 'space-city-home-network',
  BEIN1HD: 'bein-sports',
  BETHD: 'bet',
  BTN: 'big-ten-network',
  BVO: 'bravo',
  CBS: 'cbs',
  CBSSN: 'cbssn',
  CNBC: 'cnbc',
  CCHD: 'comedy-central',
  DEP: 'fox-deportes',
  EE: 'ee',
  FNCHD: 'fox-news',
  FOX: 'fox',
  FS1: 'fs1',
  FS2: 'fs2',
  GLFC: 'golf-channel',
  MASNHD: 'masn',
  MARQ: 'marquee-sports-network',
  MLBHD: 'mlb-network',
  MSG: 'msg',
  MSNBC: 'msnbc',
  MTVHD: 'mtv',
  NBC: 'nbc',
  NESN: 'nesn',
  NFLHD: 'nfl-network',
  NHLNET: 'nhl-network',
  NIKHD: 'nickelodeon',
  PAC12HD: 'pac12-network',
  RAIITA: 'rai-italia',
  SNY: 'sny',
  SYFY: 'syfy',
  TENISHD: 'tennis-channel',
  USAN: 'usa-network',
  VH1HD: 'vh1',
  WEATHHD: 'the-weather-channel',
  YESHDNY: 'yes-network',
}
export default props => {
  // Get preview network slug
  let slug
  if (props && props.location && props.location.href) {
    slug = parseQueryParams(props.location.href).page_slug
  }

  // if slug matches dict above, redirect to correct page
  if (slug && redirectDict.hasOwnProperty(slug.toUpperCase())) {
    const locationObj = props.location

    // Remove page_slug from URL
    const searchParams = new URLSearchParams(locationObj.search)
    searchParams.delete('page_slug')

    const updatedSearch = searchParams.toString()
    const updatedHref =
      updatedSearch.length > 0
        ? locationObj.href.replace(locationObj.search, `?${updatedSearch}`)
        : `${locationObj.origin}${locationObj.pathname}`

    const updatedLocation = {
      ...locationObj,
      search: updatedSearch ? `?${updatedSearch}` : '',
      href: updatedHref,
    }

    return (
      <RedirectTemplate
        location={updatedLocation}
        destination={`/live/preview/${redirectDict[slug.toUpperCase()]}/`}
      />
    )
  } else {
    return <LiveTemplateDefault location={props.location} lang="en" market="us" />
  }
}
