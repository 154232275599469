import React from 'react'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import SectionText from 'src/components/template/compare/components/modules/SectionText'
import styled from 'styled-components'

const CompareProsConsSection = ({ competitor, data }) => {
  const { heading, content } = data

  return (
    <StyledCompareProsConsSection>
      <CompareContainer className="content">
        <SectionText heading={heading} className="section-text">
          {content}
        </SectionText>
      </CompareContainer>
    </StyledCompareProsConsSection>
  )
}

export default CompareProsConsSection

const StyledCompareProsConsSection = styled.section`
  .section-text {
    margin-bottom: 20px;
  }
`
