import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.bkfc.videoUrl}
    title="Watch BKFC events with Fubo"
    subtitle="CATCH ACTION-PACKED BOUTS EACH MONTH"
    tagline="Stream the fight nights live from your TV & other devices"
    networkSet="sports"
    customNetworks={['fsn']}
    showRSN={true}
    sportId={sportIds.Boxing}
    leagueId={leagueIds.BKFC}
  />
)
