import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { getURIParamValue } from 'src/modules/utils/analytics'
import { isValidQueryParam } from 'src/modules/utils/api'

const HeroTitle = ({ title, subtitle, location }) => {
  const [stateTitle, setStateTitle] = useState(title)
  const [stateSubtitle, setStateSubtitle] = useState(subtitle)

  useEffect(() => {
    const { search } = location
    const queryStringTitle = getURIParamValue(search, 'title')

    if (isValidQueryParam(queryStringTitle)) {
      setStateTitle(queryStringTitle)
    }

    const queryStringSubInfo = getURIParamValue(location.search, 'subinfo')

    if (isValidQueryParam(queryStringSubInfo)) {
      setStateSubtitle(queryStringSubInfo)
    }
  }, [])

  return (
    <HeadlineContain>
      <HeaderElement>{stateTitle}</HeaderElement>
      {stateSubtitle && <SubTitleElement>{stateSubtitle}</SubTitleElement>}
    </HeadlineContain>
  )
}

export default HeroTitle

const HeadlineContain = styled.div`
  width: 100%;
  box-sizing: border-box;
`

const HeaderElement = styled.h1`
  display: block;
  text-align: center;
  font-size: 42px;
  line-height: 50px;
  font-weight: 700;
  font-weight: 700;
  max-width: 900px;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    margin-top: 10px;
  }

  @media screen and (max-width: 736px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 34px;
    margin-top: 15px;
  }

  @media only screen and (max-width: 321px) {
    font-size: 2em;
  }
`

const SubTitleElement = styled.h2`
  width: 100%;
  font-size: 22px;
  margin-bottom: 28px;
  line-height: 1.25;
  letter-spacing: 0.05em;
  font-weight: 600;
  margin: 10px auto 0 auto;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 321px) {
    font-size: 16px;
  }
`
