import queryString from 'query-string'
import { ctaParamList } from '../constants'

const getURIParamValue = (searchQuery, param) => {
  const parsedSearchQuery = queryString.parse(searchQuery)
  const uriParamValue = parsedSearchQuery[param]

  return uriParamValue || undefined
}

const getCTAQuery = (searchQuery, initialQueryState = { addons: [] }) => {
  const ctaQuery = { ...initialQueryState }
  const parsedSearchQuery = queryString.parse(searchQuery, { arrayFormat: 'bracket' })
  Object.entries(parsedSearchQuery).forEach(([param, value]) => {
    if (ctaParamList.includes(param.toLowerCase())) {
      switch (param.toLowerCase()) {
        case 'addons':
          ctaQuery[param] = [...ctaQuery[param], ...value]
          break
        case 'shareid':
          ctaQuery['sharedID'] = value
          break
        case 'talkable_visitor_uuid':
          ctaQuery['talkable_visitor_uuid'] = value
          ctaQuery['irad'] = 'talkable'
          ctaQuery['irmp'] = 'talkable'
          break
        case 'params': {
          const decodedParam = decodeURIComponent(value)
          const parsedDecodedParams = queryString.parse(decodedParam)
          Object.entries(parsedDecodedParams).forEach(([param, value]) => {
            if (ctaParamList.includes(param.toLowerCase())) {
              ctaQuery[param] = parsedDecodedParams[param]
            }
          })
          break
        }
        default:
          ctaQuery[param] = value
          break
      }
    }
  })

  return queryString.stringify(ctaQuery, { arrayFormat: 'bracket' })
}

function replaceSlugParam(params, paramName, paramValue) {
  if (paramValue == null) {
    paramValue = ''
  }
  var pattern = new RegExp(
    '\\b(' + paramName.replace(/\[/g, '\\[').replace(/\]/g, '\\]') + '=).*?(&|#|$)'
  )
  if (params.search(pattern) >= 0) {
    return params.replace(pattern, '$1' + paramValue + '$2')
  }
  params = params.replace(/[?#]$/, '')
  return params + (params.length > 0 ? '&' : '') + paramName + '=' + paramValue
}

export { getURIParamValue, getCTAQuery, replaceSlugParam }
