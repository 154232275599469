import React from 'react'
import LazyImage from '../../LazyLoad/LazyImage'

const AppleIphoneImg = () => {
  return (
    <LazyImage
      src="https://assets-imgx.fubo.tv/marketing/lp/images/platforms/apple-iphone.jpeg?ch=width&auto=format,compress"
      alt=""
      width="100%"
    />
  )
}

export default AppleIphoneImg
