import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_spurs.videoUrl}
    staticAnnotation={videos.bally_spurs.staticAnnotation}
    title="Watch San Antonio basketball live"
    subtitle="STREAM THE SPURS ON FANDUEL SPORTS SOUTHWEST"
    teamId={teamIds['San Antonio Spurs']}
  />
)
