export const nexstarTweets = (twitter, name) => {
  return [
    `${twitter} Why can't I watch my local ABC channel on Fubo anymore? This isn't what I signed up for! 😡`,
    `${twitter} Bring back ABC to Fubo! This is unacceptable! #BringBackABC`,
    `Hey ${twitter}, I just wanted to watch Monday Night Football and local ABC news! Why are you messing with my Fubo subscription? 😠`,
    `Disappointed in ${twitter}. Their refusal to opt-in means I can't watch ABC through Fubo. Not cool. #${name}Fail`,
    `${twitter}, stop playing games with our subscriptions! We want ABC back on Fubo! 😤`,
    `Missing out on college football because of ${twitter}'s refusal to cooperate with Fubo. Why can't corporations just play nice? #FuboVs${name}`,
    `${twitter}'s tactics against Fubo are costing loyal subscribers like me! Why break something that's working perfectly? #StandWithFubo`,
    `Seriously ${twitter}? Blocking ABC content on Fubo? That's a new low. #JusticeForFubo`,
    `${twitter} All we want is access to the content we paid for! Bring ABC back to Fubo. #NotFair${name}`,
    `Wanted to watch ABC on Fubo tonight but thanks to ${twitter}, that's not happening. Frustrated and disappointed. 😩`,
    `${twitter} needs to stop bullying Fubo and let us enjoy the content we're paying for! #${name}Bully`,
    `Hey ${twitter}, remember when contracts were meant to be honored? Why back out now and hurt Fubo subscribers? #BrokenPromises`,
    `Reading about how ${twitter} isn't playing fair with Fubo. Why deny access to ABC content? #Boycott${name}`,
    `Every day I can't access ABC on Fubo is a day I resent ${twitter}. This is not how you treat customers! 😡`,
    `${twitter}'s refusal to opt into Fubo's ABC agreement shows they're more interested in profits than people. #${name}Greed`,
    `Unbelievable! Can't watch college football championships on ABC because of ${twitter}'s issues with Fubo. #BadMove${name}`,
    `Dear ${twitter}, I'd like to watch my favorite ABC shows on Fubo without interference. Is that too much to ask? 😔`,
    `${twitter}, why put Fubo subscribers in the middle of your corporate battles? We just want uninterrupted content! #FairnessForFubo`,
    `Gone are the days I could relax and enjoy ABC on Fubo. All thanks to ${twitter}'s refusal to cooperate. 😢`,
    `${twitter}: Fubo subscribers like me deserve better than this blackout! Stop playing games and bring back ABC! 📺`,
    `Heartbroken to see big corporations like ${twitter} holding Fubo subscribers hostage. We deserve better. 😞`,
    `Hey ${twitter}, do the right thing! Stop blacking out ABC on Fubo and let us enjoy our content. #RightThingToDo`,
    `Contracts are meant to be honored, ${twitter}. Why are you breaking your word and leaving Fubo subscribers in the lurch? 😠`,
    `Wanted to relax and watch ABC on Fubo, but ${twitter} had other plans. Why, ${name}, why? 😤`,
    `${twitter}: It's simple. We want ABC back on Fubo. Stop the corporate drama and let us enjoy our shows. #ABCForFubo`,
    `A loyal Fubo subscriber here, left in the dark because of ${twitter}'s refusal to cooperate. What happened to customer satisfaction? 😩`,
    `Reading about how ${twitter} isn't honoring its commitments with Fubo. This isn't how business should be done. #ShameOn${name}`,
    `In a time when content is king, ${twitter} seems keen on depriving Fubo subscribers of ABC. Not a good look, ${name}. 😠`,
    `Hope ${twitter} reconsiders its decision and brings back ABC to Fubo. Subscribers like me are waiting and watching. 📺`,
    `${twitter}'s refusal to opt-in with Fubo means I'm missing out on crucial college football games on ABC. This isn't fair! 🏈`,
    `Why let corporate battles impact the viewers, ${twitter}? We just want to enjoy ABC on Fubo without interruptions. #${name}Fail`,
    `Dear ${twitter}, playing hardball with Fubo means you're disappointing thousands of loyal subscribers. Reconsider, please. 🙏`,
    `Watching ABC on Fubo was part of my daily routine. Thanks to ${twitter}, that's disrupted now. #BringBackABC`,
    `${twitter}'s decision to not opt into the ABC-Fubo agreement has left many of us subscribers disappointed. #StandWithFubo`,
    `Corporate greed at its finest. ${twitter} needs to remember that it's the viewers who suffer most from their actions. 😤`,
    `${twitter}, I signed up for Fubo to enjoy all my content, including ABC. Don't let corporate disputes ruin it for us! 📺`,
    `It's sad when big corporations like ${twitter} forget about the little guys – the subscribers. Bring back ABC to Fubo! 😢`,
    `Heartbroken and frustrated. ${twitter}, do the right thing and restore ABC on Fubo. Don't let your subscribers down. 😞`,
    `Yet another example of corporate power plays affecting the average viewer. ${twitter}, please reconsider your stance with Fubo. 🙏`,
    `Every day without ABC on Fubo is a day ${twitter} disappoints its loyal base. Time to make amends. #TimeForChange`,
    `Dear ${twitter}, please remember it's the subscribers who suffer most. We want ABC back on Fubo. #StandWithFubo`,
    `Missing out on my daily dose of ABC content, all thanks to ${twitter}'s refusal to cooperate with Fubo. This needs to change. 😠`,
    `${twitter}'s tactics with Fubo are costing us subscribers! Enough is enough. Bring back ABC now! 📺`,
    `Was looking forward to college football on ABC via Fubo, but ${twitter} had other plans. Not fair to us subscribers! 😩`,
    `${twitter}, it's time to listen to the voice of the viewers. We want ABC back on Fubo, and we want it now! 📺`,
    `Every night without ABC on Fubo is a night ${twitter} lets its subscribers down. Time for a change, ${name}. 📺`,
    `Hey ${twitter}, remember when TV was simple? Bring back the good old days and let Fubo stream ABC! 📺 #SimpleTimes`,
    `I can't help but wonder why ${twitter} would deny so many viewers the joy of ABC via Fubo. #FuboDeservesABC`,
    `I can't watch local news and ${twitter} is to blame. Fubo subscribers want their ABC back! 🌙 #EveningWithABC`,
    `Between ${twitter} and Fubo, it's us subscribers who are losing out. Let's get ABC back and keep everyone happy! 😊 #WinWin`,
    `Why should corporate disagreements deny me my favorite ABC shows on Fubo? ${twitter}, think of the fans! 🙌 #FansFirst`,
    `Hey ${twitter}, Fubo and ABC are a match made in heaven. Don't be the third wheel! 😅 #DontSpoilTheParty`,
    `I signed up for Fubo to chill with ABC, but ${twitter} seems to have other plans. Bring back the chill! ❄️ #ChillWithABC`,
    `Local news, favorite shows, all gone because of ${twitter}'s decision. Fubo subscribers deserve better! 📰 #LocalNewsMatters`,
    `${twitter}, we get it. Business is business. But can we please have ABC back on Fubo? 🙏 #BusinessWithHeart`,
    `Thinking of all the missed moments and memories without ABC on Fubo. ${twitter}, can we make new ones? 🌟 #MissedMoments`,
    `I miss my daily ABC content on Fubo. ${twitter}, let's bring back the joy! 😄 #JoyOfABC`,
    `The drama between ${twitter} and Fubo is more intense than my favorite ABC shows! Let's have a happy ending. 🎭 #DramaEndsNow`,
    `Every channel has its charm, but ABC on Fubo had a special place. Missing those days, ${twitter}. 😔 #BringBackTheCharm`,
    `Binge-watching isn't the same without ABC on Fubo. ${twitter}, any chance we can get back to our marathons? 🍿 #BingeWatchersUnite`,
    `To all Fubo subscribers missing ABC, let ${twitter} hear our collective plea. Let's unite and bring it back! 🤝 #TogetherForABC`,
    `ABC on Fubo felt like home. ${twitter}, can we get that homely feeling back? 🏡 #HomeIsWhereABCIs`,
    `The silver lining? The hope that ${twitter} will realize Fubo subscribers truly cherish ABC. Bring it back! ☁️ #HopefulHearts`,
    `Life's short, and missing ABC on Fubo feels like missing out on so much. ${twitter}, time's ticking. ⏳ #EveryMinuteCounts`,
    `Recalling the golden days of streaming ABC on Fubo. Let's make memories again, ${twitter}. 📅 #GoldenDaysOfFubo`,
    `My plea to ${twitter}: ABC and Fubo is a duo everyone loves. Why stand in the way of love? ❤️ #LoveForABC`,
    `When I can't access ABC on Fubo, it feels like a void. Let's fill it with joy again, ${twitter}. 🕳️ #FillTheVoid`,
    `In a world full of choices, I chose Fubo for ABC. ${twitter}, let's respect choices. 🌍 #ChoiceMatters`,
    `Hoping that ${twitter} hears the voice of the many and restores ABC for Fubo subscribers. 📢 #VoiceOfTheMany`,
    `Remember when switching on Fubo meant joy with ABC? ${twitter}, can we switch back to those times? 🔄 #SwitchBackToJoy`,
    `When favorites get taken away, the heart yearns. Fubo subscribers yearn for ABC. ${twitter}, do you hear the heartbeats? 💓 #YearningForABC`,
    `Just like a book without its last chapter, Fubo feels incomplete without ABC. ${twitter}, let's write a happy ending! 📖 #CompleteTheStory`,
    `Petition to ${twitter}: All we want is a simple life with ABC on Fubo. Let's keep it simple and joyous! 🎈 #KeepItSimple`,
    `Streaming was my happy place, but without ABC on Fubo, it's lacking. ${twitter}, let's restore the happiness! 😊 #HappyStreaming`,
    `${twitter}, what happened to putting viewers first? We're missing our ABC on Fubo! #StandWithFubo`,
    `${twitter}, remember the mantra of 'customer is king'? Fubo subscribers feel left out in the cold! #StandWithFubo`,
    `${twitter}, your decision has left a gaping hole in my streaming routine. Bring ABC back to Fubo! #StandWithFubo`,
    `${twitter}, it's time to listen to the people and restore ABC on Fubo. We've waited long enough! #BringABCBack`,
    `${twitter}, TV was meant to be enjoyed, not negotiated. Let Fubo have ABC back! #StandWithFubo`,
    `${twitter}, streaming was simpler before. Don't complicate it for Fubo users wanting ABC! #StandWithFubo`,
    `${twitter}, we chose Fubo for a reason, and ABC was part of that. Listen to your viewers! #StandWithFubo`,
    `${twitter}, in a consumer-driven world, why are Fubo viewers being sidelined? #BringABCBack`,
    `${twitter}, the essence of streaming is choice. Bring back our choice to watch ABC on Fubo!`,
    `${twitter}, it's not just about channels; it's about viewer sentiment. Fubo users deserve ABC!`,
    `${twitter}, we're voicing our frustration loud and clear. Why keep ABC away from Fubo?`,
    `${twitter}, the best businesses listen to their customers. Fubo subscribers are speaking! #BringABCBack`,
    `${twitter}, when did corporate decisions start overshadowing viewer needs? We want ABC on Fubo!`,
    `${twitter}, the longer ABC is off Fubo, the louder our voices get. Are you listening? #StandWithFubo`,
    `${twitter}, you've created a void that only ABC on Fubo can fill. Address this!`,
    `${twitter}, business choices shouldn't affect viewer joy. Bring back the joy with ABC on Fubo!`,
    `${twitter}, the collective voice of Fubo subscribers can't be ignored. We miss ABC! #StandWithFubo`,
    `${twitter}, the modern world is about accessibility. Why make ABC inaccessible on Fubo?`,
    `${twitter}, TV is about relaxation, not frustration. ABC's absence on Fubo is frustrating!`,
    `${twitter}, how many voices does it take to bring ABC back to Fubo? We're counting! #StandWithFubo`,
    `${twitter}, corporate tiffs shouldn't impact viewers. Give Fubo subscribers their ABC!`,
    `${twitter}, your move feels like a step back in a digital age. ABC should be on Fubo!`,
    `${twitter}, our plea is simple: ABC on Fubo. Listen to your viewers!`,
    `${twitter}, you're creating a generation of frustrated streamers. Rectify by bringing ABC back to Fubo!`,
    `${twitter}, your decisions have consequences. The consequence here? Disappointed Fubo subscribers!`,
    `${twitter}, in an on-demand world, we demand ABC back on Fubo! #StandWithFubo`,
    `${twitter}, TV shouldn't be this complicated. Simplify our lives; bring ABC back to Fubo!`,
    `${twitter}, every tweet is a testament to our frustration. Respond by getting ABC back on Fubo!`,
    `${twitter}, you're making headlines for the wrong reasons. Bring ABC back to Fubo!`,
    `${twitter}, we're missing out on crucial content. Fubo needs ABC, and you can make it happen!`,
    `${twitter}, viewer loyalty is earned. Earn ours by letting Fubo stream ABC!`,
    `${twitter}, I need my local weather reports. Listen to Fubo subscribers and bring ABC back!`,
    `${twitter}, a channel may seem small, but its impact is huge. Fubo subscribers are feeling the impact of missing ABC!`,
    `${twitter}, TV is about connection. Connect us back to ABC on Fubo!`,
    `${twitter}, you have the power to end this frustration. Get ABC back on Fubo!`,
    `${twitter}, for every day ABC is off Fubo, you're losing trust. Time to rebuild! #StandWithFubo`,
    `${twitter}, streaming was supposed to be hassle-free. Don't make it a hassle for Fubo users waiting for ABC!`,
    `${twitter}, imagine the joy of seeing ABC back on Fubo. You can make that a reality!`,
    `${twitter}, how can you ignore the outcry? We want ABC back on Fubo now!`,
    `${twitter}, every show missed on ABC is a ding to your reputation. Listen to Fubo viewers!`,
    `${twitter}, streaming was about choice and freedom. Why limit Fubo users from enjoying ABC?`,
    `${twitter}, Fubo without ABC feels incomplete. Bring back local programming for subscribers!`,
    `${twitter}, our evenings aren't the same without ABC on Fubo. Time to restore the balance!`,
    `${twitter}, think of the families missing out on ABC content via Fubo. Don't they deserve better? #BringBackABC`,
    `${twitter}, the digital era is about swift actions. Act swiftly and get ABC back on Fubo!`,
    `${twitter}, When can Fubo subscribers expect ABC back? `,
    `${twitter}, you can't put a price on viewer sentiment. Fubo users have spoken; bring back ABC!`,
    `${twitter}, remember when TV was simple joy? Restore that joy for Fubo viewers with ABC!`,
    `${twitter}, actions speak louder than words. Show Fubo subscribers you care by restoring ABC!`,
    `${twitter}, our patience is running thin. Fubo deserves ABC. Don't let us down! #StandWithFubo`,
    `${twitter}, countless moments missed. It's high time ABC returns to Fubo!`,
    `${twitter}, be the change Fubo subscribers want to see. Bring ABC back into our homes!`,
    `${twitter}, the digital age doesn't wait. Why are Fubo users still waiting for ABC?`,
    `${twitter}, every evening without ABC on Fubo feels incomplete. Fill the void! #BringBackABC`,
    `${twitter}, every hashtag, every tweet points to one thing: Fubo wants ABC!`,
    `${twitter}, the clock's ticking, and Fubo users are watching. Bring back ABC!`,
    `${twitter}, TV was about simplicity. Make it simple again for Fubo viewers: We want ABC!`,
    `${twitter}, your legacy is at stake. Make a positive mark by getting ABC back on Fubo!`,
    `${twitter}, your inbox might be flooding, but the message is singular: Fubo needs ABC!`,
    `${twitter}, be the hero in this story. Bring back the joy of ABC to Fubo subscribers!`,
    `${twitter}, Hear us out and bring ABC to Fubo!`,
    `${twitter}, one channel can make a world of difference. Fubo users are feeling that difference without ABC!`,
    `${twitter}, TV binds families. Bind Fubo families with joy by bringing back ABC!`,
    `${twitter}, our plea is echoed in countless homes: ABC for Fubo, please!`,
    `${twitter}, nostalgia hits hard when we think of ABC. Bring it back to Fubo and end the wait!`,
    `${twitter}, the digital realm waits for no one. Why should Fubo users wait for ABC?`,
    `${twitter}, every retweet, every like is a shoutout. The shoutout? ABC for Fubo!`,
    `${twitter}, be on the right side of history. Fubo subscribers are rooting for ABC's return!`,
    `${twitter}, you can end the saga on a high note. The climax? ABC's return to Fubo!`,
    `${twitter}, the narrative is yours to control. Let the story be about ABC's triumphant return to Fubo!`,
    `${twitter}, in an era of choices, why deprive Fubo subscribers of theirs? We choose ABC!`,
    `${twitter}, the outcry is real, the demand genuine. Fubo wants ABC back!`,
    `${twitter}, it's more than just a channel. It's memories, it's nostalgia. Bring ABC back to Fubo!`,
    `${twitter}, the math is simple. Fubo + ABC = Happy Subscribers. Solve the equation!`,
    `${twitter}, our remote feels incomplete without flipping to ABC on Fubo. Time for a change!`,
    `${twitter}, a seamless TV experience is what we ask. Make it seamless by adding ABC to Fubo!`,
    `${twitter}, your decisions shape our viewing experience. Shape it positively by including ABC on Fubo!`,
    `${twitter}, with one decision, you can change the mood of countless homes. Get ABC on Fubo!`,
    `${twitter}, when nostalgia hits, it hits hard. Bring back our nostalgic moments with ABC on Fubo!`,
    `${twitter}, each day without ABC on Fubo feels longer. Shorten our wait!`,
    `${twitter}, let's rewrite the story. Let the next chapter be about ABC's return to Fubo!`,
    `${twitter}, the message is loud and clear. Fubo users want, need, and deserve ABC!`,
    `Hey ${twitter}, I missed my favorite ABC show tonight, all thanks to you. This isn't what I pay for! #${name}Issues`,
    `${twitter}, Fubo promised ABC. Why are you breaking that promise? 😒 #LetFuboBe`,
    `Disrupted my binge-watching session because ${twitter} won't let ABC on Fubo. Seriously? 🙄 #DontMessWithMyShows`,
    `${twitter}, it's simple. I want ABC on Fubo. Why make it complicated? 😖 #SimpleRequest`,
    `Cancelled my plans to watch ABC on Fubo tonight. But oh, ${twitter} had other plans. #FrustratedSubscriber`,
    `${twitter}, your stand against Fubo is hurting us the most. We're caught in the crossfire and it's not fair! 😡`,
    `Every time I can't watch ABC on Fubo, I blame ${twitter}. This isn't the experience I signed up for. 😤`,
    `${twitter}, when will you realize we just want uninterrupted access to ABC on Fubo? #EndTheDrama`,
    `Just another evening without ABC on Fubo, courtesy of ${twitter}. It's not a good look! 😔 #BringBackABCtoFubo`,
    `Hey ${twitter}, instead of fighting Fubo, how about working together for us subscribers? 🤷‍♂️ #CollaborateNotCompete`,
    `${twitter}'s hard stance against Fubo makes me question their customer-first approach. Is it just about the money? 😟 #ProfitOverPeople`,
    `Wishing for a time when I can watch ABC on Fubo without any hassles. ${twitter}, are you listening? 🙏 #SubscriberWishes`,
    `${twitter}, every tweet you see is a customer unhappy with your decision against Fubo. Time to reconsider? #VoiceOfTheCustomer`,
    `Missed the season finale on ABC because ${twitter} won't let it stream on Fubo. Heartbroken! 😢 #SpoilerAlertsEverywhere`,
    `Every time I see the ABC logo, I'm reminded of ${twitter}'s decision against Fubo. Not cool. 😞`,
    `Corporate battles shouldn't affect subscribers. ${twitter}, let Fubo users have their ABC back! #EndTheFeud`,
    `${twitter}, this isn't just about shows. It's about trust. Bring ABC back to Fubo and rebuild that trust! 🙌`,
    `It's another weekend without ABC sports on Fubo. ${twitter}, you've taken away our game nights! 😠`,
    `Dear ${twitter}, Fubo promised us ABC. Why are you breaking that promise? Bring it back! 📺`,
    `When big corporations fight, small subscribers suffer. ${twitter}, don't let us be the collateral damage. #JusticeForFuboUsers`,
    `Hey ${twitter}, every day without ABC on Fubo is a disappointment. Enough is enough! #WeWantABC`,
    `Thinking of the days I could enjoy ABC content on Fubo. ${twitter}, can we go back to those times? 🤔 #GoodOldDays`,
    `It's not just about a channel. It's about choice and freedom. ${twitter}, give Fubo subscribers their freedom back! 📺`,
    `Family movie night ruined because we can't access ABC on Fubo. All thanks to ${twitter}. 😩`,
    `${twitter}, remember the days when customer satisfaction was a priority? Let's get back to that. #ABCforFubo`,
    `Hearing my friends talk about the latest on ABC while I can't watch it on Fubo. ${twitter}, do you see the problem? 😤`,
    `The excitement of tuning into ABC on Fubo has been replaced by frustration, all thanks to ${twitter}. 😔`,
    `${twitter}, we aren't asking for much. Just let us enjoy ABC on Fubo without interruptions! 🙏`,
    `Dear ${twitter}, your feud with Fubo is costing subscribers joy and content. Time to rethink? 🤨`,
    `ABC missing from Fubo is a glaring gap. ${twitter}, the ball's in your court. #FillTheGap`,
    `Wanted to enjoy a peaceful evening with ABC shows on Fubo. But, ${twitter} had other plans. 😡`,
    `The rift between ${twitter} and Fubo is widening. But, it's the subscribers who are falling through the cracks. 😞`,
    `Dear ${twitter}, we're more than just numbers. We're people who want to enjoy our favorite content. Let ABC be on Fubo! 📺`,
    `${twitter}'s stand against Fubo feels like a stand against its subscribers. Not a wise move! 😟`,
    `Hoping for a time when I can freely watch ABC on Fubo. ${twitter}, make it happen! 🙌`,
    `Tired of this tussle between ${twitter} and Fubo. Just want my content back. 😩`,
    `It's a simple request. ${twitter}, let ABC be accessible on Fubo. Why the complications? 🤷‍♀️`,
    `Every time I can't watch ABC on Fubo, I feel let down by ${twitter}. Is this the experience you aim for? 😖`,
    `A show missed on ABC due to ${twitter}'s decisions is a memory missed. Think about that. 😔`,
    `Hoping ${twitter} hears the voice of countless Fubo subscribers asking for one thing: Bring back ABC! 📺`,
    `Another day, another disappointment. ABC still not on Fubo. ${twitter}, when will this change? 😞`,
    `Imagine a world where ${twitter} lets Fubo subscribers get their ABC. Bliss! 🌟`,
    `${twitter}, remember the phrase 'Customer is King'? Time to treat Fubo subscribers like royalty. Bring back ABC! 👑`,
    `Corporate disputes should stay corporate. ${twitter}, don't drag Fubo subscribers into this. #KeepUsOutOfIt`,
    `We aren't just subscribers. We're your audience, ${twitter}. Value us and bring ABC back to Fubo! 📺`,
    `Craving some quality time with ABC shows on Fubo. But, ${twitter} seems to have other plans. 😒`,
    `Remembering the good times with ABC on Fubo. ${twitter}, can you bring those times back? 🕰️`,
    `Hey ${twitter}, the solution is simple. Listen to Fubo subscribers and bring back ABC! 📺`,
  ]
}
