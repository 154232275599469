import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_pelicans.videoUrl}
    staticAnnotation={videos.bally_pelicans.staticAnnotation}
    title="Watch New Orleans basketball live"
    subtitle="STREAM THE PELICANS ON GULF COAST SPORTS NETWORK"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    teamId={teamIds['New Orleans Pelicans']}
    customNetworks={['gulfcoastsports', 'fanduelsports', 'abc', 'nbatv', 'espn', 'acc', 'sec']}
  />
)
