import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'
import { isUSFreeTrial } from 'src/modules/utils/constants'

export const directvData = {
  competitor: 'DirecTV Stream',
  competitorColor: '#00a6d6',
  heroData: {
    title: 'Looking for the best streaming service?',
    subtitle:
      'Compare Fubo & DirecTV Stream on price, features, channels, and add-ons to see which is better for you.',
  },
  generalCompareData: {
    heading: 'Compare Fubo vs. DirecTV Stream',
    subheading:
      'Here’s a general look at both to help you determine which is the best streaming service for you.',
    listItems: ['Channel Count', 'Plans', 'Free Trial', 'Starting Price'],
    fuboList: ['185+', '3 options', isUSFreeTrial ? 'Yes' : 'No', '$79.99/mo*'],
    competitorList: ['75+', '3 options', 'Yes', '$79.99/mo*'],
  },
  prosConsData: {
    heading: 'Pros and Cons: Fubo vs. DirecTV Stream',
    content: (
      <>
        <p>
          When it comes to cord cutting, Fubo and DirecTV Stream are two of the best streaming
          services around. Both are substantially cheaper than cable and offer many of the same
          great live channels for live sports and popular entertainment. When it comes to picking
          between the two, it comes down to inches in terms of price, channels available, unique
          benefits, and features.
        </p>
        <p>
          Fubo is a premium product and it has the price tag to match. Fubo plans are loaded with
          live channels (at least 185 live channels) and quality features. It’s worth noting, Fubo
          has a lot of channels included in their lowest tier plan, and many of those channels are
          among the most-watched channels of 2023. Plus, Fubo has an extensive video-on-demand
          library. While most streaming services have VOD content, Fubo has more than 40K TV shows
          and movies available via VOD each month as well as 55K live sporting events on the
          platform annually.
        </p>
        <p>
          DirecTV, in general, is very comparable to traditional cable and, while it is a different
          product, DirecTV Stream is still very close to the old guard. Even if their base plan has
          a lower channel count, DirecTV Stream has a good channel count overall. The biggest gripe
          with DirecTV Stream is how the plans are broken down. Sports fans that want to stream a
          wide variety of leagues and events with DirecTV are forced to consider the higher-tier,
          more-costly plan.
        </p>
      </>
    ),
  },
  plansData: {
    heading: 'Compare Fubo Pro plan vs. DirecTV Stream Choice plan',
    subheading:
      'Let’s take a look at the differences between the popular Fubo Pro plan and the DirecTV Stream Entertainment plan.',
    tableData: {
      Plan: ['Pro plan', 'Entertainment plan'],
      'Live Channels': ['185+', '75+'],
      'Monthly Price': ['$79.99', '$79.99'],
      'Hours of DVR storage': ['Unlimited', 'Unlimited'],
      'Free Trial': [isUSFreeTrial ? 'Yes' : 'No', 'No'],
      'Multiple active streams': ['Up to 10', 'Unlimited, but has restrictions'],
      'Regional sports networks': [true, true],
      'Streaming from any device': [true, true],
    },
  },
  channelsData: {
    heading: 'Compare Fubo Pro Channels vs. DirecTV Stream Entertainment Channels',
    subheading:
      'One of the biggest differences between Fubo and DirecTV Stream is channel count vs. price. This chart compares which sports networks, including regional sports networks only accessible in select markets, are available in the price-comparable Fubo Pro plan and DirecTV Stream Entertainment plan.',
    networksData: [
      { network: networkLogos.abc, fubo: true, competitor: true },
      { network: networkLogos.nbc, fubo: true, competitor: true },
      { network: networkLogos.cbs, fubo: true, competitor: true },
      { network: networkLogos.fox, fubo: true, competitor: true },
      { network: networkLogos.espn, fubo: true, competitor: true },
      { network: networkLogos.tudn, fubo: true, competitor: false },
      { network: networkLogos.nflnetwork, fubo: true, competitor: false },
      { network: networkLogos.bigten, fubo: true, competitor: false },
      { network: networkLogos.ion, fubo: true, competitor: true },
      { network: networkLogos.oxygen, fubo: true, competitor: false },
      { network: networkLogos.telemundo, fubo: true, competitor: false },
      { network: networkLogos.yesnetwork, fubo: true, competitor: false },
      { network: networkLogos.nesn, fubo: true, competitor: false },
      { network: networkLogos.fanduelsports, fubo: true, competitor: false },
      { network: networkLogos.masn, fubo: true, competitor: false },
    ],
  },
  completedChannelsData: {
    heading: 'Complete Channel Comparison',
    subheading:
      'See how the live channel lineups compare between Fubo Pro plan and DirecTV Stream Entertainment plan.',
    content: (
      <>
        <p>
          When it comes to cord cutting, Fubo and DirecTV Stream are two of the best streaming
          services. Both are substantially cheaper than cable and offer many of the same great live
          channels for live sports and popular entertainment. When it comes to picking between the
          Fubo Pro plan and DirecTV Stream Entertainment plan, it comes down to inches in terms of
          price, channels available, and features.
        </p>
        <p>
          When it comes to overall channels, Fubo definitely has an edge over DirecTV Stream’s
          Entertainment plan. Some of the channels missing between the two base plans directly
          impact sports fans. For example, Fubo includes local sports networks in most markets in
          the Pro plan. That offers local markets live games and dedicated coverage of the MLB, NHL,
          and NBA teams in most local markets. They also have excellent soccer coverage with TUDN,
          another channel missing from the DirecTV Stream Entertainment plan. While the pendulum may
          swing either way with access to entertainment, Fubo definitely has more to offer sports
          fans at a lower cost.
        </p>
        <p>
          DirecTV Stream also has a good lineup of live channels. They have many of the same
          channels available on Fubo with a few key differences. There are obviously big holes with
          the local sports coverage in the base Entertainment plan, but those channels are available
          to local markets with a hefty price upgrade to the $99.99/mo. plan. DirecTV Stream does
          have WarnerMedia networks in their Entertainment plan. However, they’re missing a few
          high-demand entertainment channels, like Oxygen, NFL Network, Telemundo and more.
        </p>
      </>
    ),
  },
  faqData: {
    qaData: [
      {
        id: 'fubo-faq-5',
        question: 'What channels does Fubo have vs. DirecTV Stream?',
        answer: (
          <p>
            Fubo and DirecTV Stream have many of the same channels. However, Fubo has an edge with
            their Pro plan vs. DirecTV Stream’s Entertainment plan. The Fubo Pro plan includes
            regional sports networks in select local markets as well as major networks like Weather
            Channel, Telemundo, Oxygen & more.
          </p>
        ),
      },
    ],
  },
}
