import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default props => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="CCHD"
    title="Watch Comedy Central Live with Fubo"
    networkName="Comedy Central"
  />
)
