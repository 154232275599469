export function createUrl(baseUrl, params) {
  let queryString = ''
  for (let key in params) {
    if (queryString === '') {
      queryString += '?'
    } else {
      queryString += '&'
    }
    queryString += key + '=' + params[key]
  }
  return baseUrl + queryString
}

export function createUrlParams(params) {
  let queryString = ''
  for (let key in params) {
    if (queryString !== '') {
      queryString += '&'
    }
    queryString += key + '=' + params[key]
  }
  return queryString
}
