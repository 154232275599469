import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.ucl.videoUrl}
      staticAnnotation={videos.ucl.staticAnnotation}
      title="Mira la UEFA Champions League"
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="soccerLatino"
      customNetworks={['univision']}
      noteLink="/la/ucl/"
    />
  )
}
