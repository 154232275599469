import React from 'react'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'
export default props => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_blues.videoUrl}
    staticAnnotation={videos.bally_blues.staticAnnotation}
    title="Watch St. Louis hockey live"
    subtitle="STREAM THE BLUES ON FANDUEL SPORTS MIDWEST"
    teamId={teamIds['St. Louis Blues']}
  />
)
