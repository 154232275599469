const Checkmark = ({ fill = '#FAFAFA' }) => (
  <svg width="100%" height="100%" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.02138 8.26663C4.137 8.26663 4.24735 8.21833 4.32579 8.1334L10.4497 1.50304C10.605 1.33492 10.5946 1.07274 10.4265 0.917459C10.2583 0.762175 9.99617 0.772585 9.84089 0.940711L4.02136 7.24149L1.33297 4.33108C1.17768 4.16296 0.915501 4.15257 0.747383 4.30786C0.579266 4.46315 0.568869 4.72533 0.724162 4.89344L3.71697 8.13342C3.79542 8.21834 3.90577 8.26663 4.02138 8.26663Z"
      fill={fill}
    />
  </svg>
)

export default Checkmark
