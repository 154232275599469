import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import { useCheckFanduelRSN } from 'src/modules/utils/useCheckFanduelRSN'
import FuboFreeTemplate from 'src/components/template/fubofree'
import { useSelector } from 'react-redux'
import { selectShowFanduelPlanCardsFlag } from 'src/modules/reducers/cohorts'
import { fanduelSportsNetworkFAQs } from 'src/constants/faqs'

const BallySportsNBA = props => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()
  const showFDOnly = useSelector(selectShowFanduelPlanCardsFlag)

  const commonProps = {
    location: props.location,
    lang: 'en',
    market: 'us',
    video: props.poster ? null : props.video || videos.generic_basketball.videoUrl,
    staticAnnotation: props.staticAnnotation,
    title: props.title,
    subtitle: props.subtitle,
    tagline: 'Anytime. Anywhere. From any device. Regional restrictions apply.',
    networkSet: 'basketball',
    customNetworks: props.customNetworks || ['fanduelsports'],
    sportId: sportIds.Basketball,
    leagueId: leagueIds.NBA,
  }

  return showFDOnly && showFDPlanCards ? (
    <FuboFreeTemplate
      {...commonProps}
      //nba league pass addon
      // addonCode="nbalp"
      hasFanduelPlanCards={props.hideFanduelPlanCards ? false : showFDPlanCards}
      fanduelRSNData={rsnData}
      additionalFAQs={fanduelSportsNetworkFAQs}
      {...props}
    />
  ) : (
    <USTemplateDefault
      {...commonProps}
      //nba league pass addon
      // addonCode="nbalp"
      hasFanduelPlanCards={props.hideFanduelPlanCards ? false : showFDPlanCards}
      fanduelRSNData={rsnData}
      additionalFAQs={fanduelSportsNetworkFAQs}
      {...props}
    />
  )
}
export default BallySportsNBA
