import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.latino.videoUrl}
      annotations={videos.latino.annotations}
      title="Ahorra en TV en vivo para toda la familia"
      // subtitle="POR SOLO $32.99/mes"
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="entertainmentLatino"
      noteLink="/la/offer/"
      offerCode="latino-bundle-monthly-v3-trial-10offv2"
      offerBanner={
        <>
          ✔ ¡Oferta especial activada!
          <br />
          Aprovecha $10 de descuento en tu primer mes de Fubo.
        </>
      }
      discountedPrice={'$22.99/mes'}
    />
  )
}
