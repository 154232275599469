import React from 'react';
import useLazyLoad from 'src/modules/hooks/useLazyLoad';

const LazyImage = ({ src, alt, ...props }) => {
  const loadedSrc = useLazyLoad(src);
  return (
    <img
      src={loadedSrc || ""} // Placeholder image URL
      alt={alt}
      data-src={src}
      {...props}
    />
  );
};

export default LazyImage;
