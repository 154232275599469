import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/PFL_Challenger_20s_LP.mp4'}
      title="Watch the Professional Fighters League"
      subtitle="MAKE YOUR FRIDAYS A FIGHT NIGHT"
      tagline="Stream live MMA fights from any device"
      networkSet="canada"
    />
  )
}
