// Define action types
export const TRY_PAGE_LOAD = 'TRY_PAGE_LOAD'
export const PAGE_LOAD_SUCCESS = 'PAGE_LOAD_SUCCESS'
export const SET_APP_REFERRER = 'SET_APP_REFERRER'
export const PAGE_LOAD_FAIL = 'PAGE_LOAD_FAIL'

export type SetAppReferrerAction = {
  type: typeof SET_APP_REFERRER
  referrer: string
}

export type TryPageLoadAction = {
  type: typeof TRY_PAGE_LOAD
  payload: {
    deviceId: string
    referringUrl?: string
  }
}

// The following types have a payload and error type of
// any because they are 3rd party API responses
// that we dont control or use in our app
export type PageLoadSuccessAction = {
  type: typeof PAGE_LOAD_SUCCESS
  payload: any
}

export type PageLoadFailAction = {
  type: typeof PAGE_LOAD_FAIL
  error: any
}
