import React from 'react'
import styled from 'styled-components'

import ContractDocumentIcon from 'src/assets/svg/icon-contract-document.svg'
import DollarSignIcon from 'src/assets/svg/icon-dollar-sign.svg'
import DVRIcon from 'src/assets/svg/icon-dvr.svg'
import TVIcon from 'src/assets/svg/tv.svg'
import AddOnIcon from 'src/assets/svg/add-on.svg'

import { SoccerIcon } from 'src/components/icons/SoccerIcon'

const DealSection = ({
  market = 'us',
  lang = 'en',
  dvrHours,
  background,
  title,
  savingsText,
  dvrIcon,
  dvrHeader,
  dvrText,
  cableSource,
}) => {
  const copy = {
    canada: {
      en: {
        title: '3 reasons you’ll love Fubo',
        items: [
          {
            label: 'Exclusive sports',
            icon: <SoccerIcon />,
            description:
              savingsText ||
              'Fubo is the only way to watch the Premier League, now with matches in 4K UHD. Plus, get Serie A & Coppa Italia too.',
          },
          {
            label: 'No contracts, cancel anytime',
            description:
              'What you see is what you pay. Want to cancel or take a break? No problem.',
          },
          {
            label: dvrHeader || 'Cloud DVR included',
            description:
              dvrText ||
              'Enjoy unlimited hours of DVR space to record sports, movies and more. Watch on all your devices.',
          },
        ],
      },
    },
    us: {
      en: {
        title: 'How is Fubo better than cable TV?',
        items: [
          {
            label: 'Big savings',
            description: savingsText || (
              <>
                Fubo has the live sports and TV you love for half the cost of similar cable
                packages.
                {cableSource && (
                  <span style={{ fontSize: '.7em', fontStyle: 'italic', display: 'block' }}>
                    *Cord Cutter News, March 2020
                  </span>
                )}
              </>
            ),
          },
          {
            label: 'No contracts, cancel anytime',
            description:
              'What you see is what you pay. Want to cancel or take a break? No problem.',
          },
          {
            label: dvrHeader || 'Unlimited Cloud DVR Space',
            description:
              dvrText ||
              'Enjoy the freedom to record all the sports, shows, movies & news that you love to watch & rewatch again.',
          },
        ],
      },
      es: {
        title: '¿Por Qué es Fubo Mejor Que el Servicio de Cable?',
        items: [
          {
            label: 'Ahorra $$$',
            description:
              savingsText ||
              'Fubo tiene el deporte y la TV que te encanta y por la mitad del precio de servicios similares de cable.',
          },
          {
            label: 'Sin contratos, cancela cuando quieras',
            description:
              'Con Fubo nunca pagarás más de lo acordado. Cancelar online también es facil.',
          },
          {
            label: dvrHeader || 'Espacio Ilimitado en tu Cloud DVR',
            description:
              dvrText ||
              'Graba todos los deportes, shows, películas y noticieros que quieras para verlos cuando quieras.',
          },
        ],
      },
    },
    latino: {
      en: {
        title: 'How is Fubo better than cable TV?',
        items: [
          {
            label: 'Big savings',
            description:
              'Fubo has the live sports and TV you love for half the cost of similar cable packages.',
          },
          {
            label: 'No contracts, cancel anytime',
            description:
              'What you see is what you pay. Want to cancel or take a break? No problem.',
          },
          {
            label: dvrHeader || 'Unlimited Cloud DVR Space',
            description:
              dvrText ||
              'Enjoy the freedom to record all the sports, shows, movies & news that you love to watch & rewatch again.',
          },
        ],
      },
      es: {
        title: '¿Por Qué es Fubo Mejor Que el Servicio de Cable?',
        items: [
          {
            label: 'Ahorra $$$',
            description:
              'Fubo tiene el deporte y la TV que te encanta y por la mitad del precio de servicios similares de cable.',
          },
          {
            label: 'Sin contratos, ni costos adicionales',
            description:
              'Con Fubo nunca pagarás más de lo acordado. Cancelar online también es facil.',
          },
          {
            label: dvrHeader || 'Espacio Ilimitado en tu Cloud DVR',
            description:
              dvrText ||
              'Graba todos los deportes, shows, películas y noticieros que quieras para verlos cuando quieras.',
          },
        ],
      },
    },

    svod: {
      en: {
        title: 'Top 3 reasons to stream TV with the Fubo Free plan',
        items: [
          {
            icon: <TVIcon />,
            label: '170+ live channels',
            description:
              'Enjoy top shows, news, movies and more. Watch live and on-demand. All free.',
          },
          {
            label: 'No contract, no commitment.',
            description:
              'Start watching right away with no hassles and no special equipment required.',
          },
          {
            icon: <AddOnIcon />,

            label: 'Premium add-ons',
            description:
              'Add premium content to your free plan to create your perfect low-cost live TV plan.',
          },
        ],
      },
    },
  }

  if (!copy?.[market]?.[lang]) return null
  const marketCopy = copy?.[market]?.[lang] || {}
  const sectionTitleText = title || marketCopy.title
  const sectionItems = marketCopy.items || []
  const icons = [<DollarSignIcon />, <ContractDocumentIcon />, dvrIcon || <DVRIcon />]
  return (
    <SectionElement background={background}>
      <SectionContent>
        <SectionHeader>
          <div className="section-title">
            <h3>{sectionTitleText}</h3>
          </div>
        </SectionHeader>
        <SectionBody>
          <SectionList>
            {sectionItems.map((item, i) => (
              <SectionListItem key={i}>
                {item.icon ? item.icon : icons[i]}
                <div className="list-item-title">{item.label}</div>
                <div className="list-item-description">{item.description}</div>
              </SectionListItem>
            ))}
          </SectionList>
        </SectionBody>
      </SectionContent>
    </SectionElement>
  )
}

export default DealSection

const SectionElement = styled.section`
  width: 100%;
  height: auto;
  background: ${props => props.background || '#FAFAFA;'};
  color: #d9d9d9;
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 60px;

  .section-title {
    padding: 0 5%;
  }
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  // padding: 70px 0;
  max-width: 1440px;
  margin: 0 auto;
`

const SectionHeader = styled.div`
  h3 {
    color: #242526;

    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    display: block;
    margin: 10px 0px 40px 0;
    letter-spacing: 1px;
    text-align: center;
  }

  @media (max-width: 600px) {
    max-width: 400px;
    margin: 0 auto;
  }
`

const SectionBody = styled.div`
  width: 100%;
`

const SectionList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 0;

  svg {
    width: 149px;
    height: 69px;
    margin: 10px 0;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

const SectionListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  flex: 1 1 0;

  .list-item-title {
    color: #f57c00;
    font-size: 20.48px;
    font-weight: 700;
    font-weight: 700;
    margin: 15px 0px 5px 0px;
  }

  .list-item-description {
    color: #1a1d29;
    font-size: 1.25em;
    margin: 0 3% 40px 3%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
`
