import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/The_Bachelor_2022_LP_15s.mp4"
    staticAnnotation={networkLogos.abc}
    title="Watch The Bachelor on ABC"
    subtitle="WITH NEW EPISODES EVERY MONDAY"
    tagline="Stream it live from your TV and other devices"
    ctaText="Start Watching"
  />
)
