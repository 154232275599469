import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      ctaText="Learn More"
      video={videos.liga_mx.videoUrl}
      staticAnnotation={videos.liga_mx.staticAnnotation}
      title="Watch Liga MX Live on ESPN Deportes"
      subtitle="STREAM ON YOUR PHONE, TV AND MORE"
      networkSet="soccerLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/liga-mx-es-on-espn-deportes/"
      disablePlanCards={true}
    />
  )
}
