import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'

export default props => {
  const { hasGenericVideo } = props

  return (
    <StyledContainer>
      <USDefaultTemplate
        location={props.location}
        team={props.team}
        lang="en"
        market="us"
        ctaText="Get Access"
        video={hasGenericVideo ? videos.generic_football.videoUrl : videos.cfb.videoUrl}
        title={'Watch college football all season'}
        tagline="Stream live games on ESPN & more with Fubo"
        networkSet="collegeFootball"
        showRedzone={true}
        sportId={sportIds.Football}
        leagueId={leagueIds['NCAA Football']}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: block;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`
