import React from 'react'
import Layout from 'src/components/common/Layout'
import styled from 'styled-components'

const TermsConditionsTemplate = ({ lang = 'en', market = 'all', children }) => {
  return (
    <Layout lang={lang} market={market}>
      <StyledTermsConditionsTemplate>{children}</StyledTermsConditionsTemplate>
    </Layout>
  )
}

export default TermsConditionsTemplate

const StyledTermsConditionsTemplate = styled.div`
  color: #000;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  flex: 1;
  a {
    color: #3366cc;
    font-weight: 600;
    text-decoration: underline;
  }
  h1 {
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 20px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    > li {
      padding-bottom: 12px;
    }
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
`
