import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.generic_baseball.videoUrl}
      title="Watch MLB live in Spanish"
      tagline="60+ channels of sports, news, soaps, and entertainment in Spanish"
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/mlb-es/"
    />
  )
}
