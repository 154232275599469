import React from 'react'
import styled from 'styled-components'

const ChannelError = () => {
  return (
    <SectionError>
      {/* Sorry, you are not in the correct region for this lineup.<br />Please try again. */}
    </SectionError>
  )
}

export default ChannelError

const SectionError = styled.div`
  line-height: 24px;
  color: #242424;
  font-size: 18;
  text-align: center;
  margin: 60px 34px 30px;
  background-color: transparent;
`
