import { environment } from 'src/config/env/env'
import { createCall } from '@fubotv/js-client/dist/esm'

function createApiCall() {
  const { env, api } = environment
  const context = { env, api }
  const call = createCall(context)

  return {
    call,
  }
}

export const { call } = createApiCall()
