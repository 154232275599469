import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.disney_channel.videoUrl}
    title="Watch the Disney Channel with Fubo"
    subtitle="ENJOY DUCKTALES AND MORE, LIVE AND ON DEMAND"
    tagline="Stream it all from your TV, phone and other devices"
    ctaText="Start Watching"
    networkSet="entertainment"
    customNetworks={[
      'nickelodeon',
      'disney',
      'universalkids',
      'disneyjunior',
      'nickjr',
      'disneyxd',
      'nicktoons',
    ]}
  />
)
