import React from 'react'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const CompareCardContainer = ({ children, ...props }) => {
  return <StyledCompareCardContainer {...props}>{children}</StyledCompareCardContainer>
}

export default CompareCardContainer

const StyledCompareCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 32px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  ${breakpointQuery.desktop} {
    flex-direction: row;
  }
`
