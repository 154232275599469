import React from 'react'
import OneboxTemplateDefault from 'src/components/template/onebox'
import { videos } from 'src/constants/videos'
export default props => (
  <OneboxTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/logos/logo-fubo-uefa-nations-league.png?w=300&ch=width&auto=format,compress"
    logoHeight="90px"
    video={videos.uefa_nations_league.videoUrl}
    videoUrlBase="https://marketing-partners.fubo.tv/wsc/conmebol/"
    background="https://assets-imgx.fubo.tv/marketing/lp/images/posters/fubo-uefa-background_compressed.jpg?w=1920&ch=width&auto=format,compress"
    title="Watch live UEFA Nations League soccer"
    subtitle="All matches + exclusive content. Cancel anytime."
    networkSet="soccer"
    customNetworks={['fsn']}
  />
)
