import { createSelector } from "reselect"

export const selectMarketState = (state) => state.market

export const selectCountryCode = createSelector(
  selectMarketState,
  state => state.countryCode2
)

export const selectCountryName = createSelector(
  selectMarketState,
  state => state.countryName
)

export const selectDma = createSelector(selectMarketState, state => state.dma)

export const selectPostalCode = createSelector(selectMarketState, state => state.postalCode)
