import React, { useEffect, useState } from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'
import { useEPLImage } from 'src/modules/hooks/useEPLImage'
import styled from 'styled-components'

export default props => {
  const posterImg = useEPLImage()

  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        offerCode={'canada-month-essentials-spc'}
        offerBanner={
          <>
            Exclusive offer for SPC students! <br />
            Get a month of Premier League action for only $14.99.
          </>
        }
        ctaText="Get Access"
        // video={videos.canada_epl.videoUrl}
        poster={posterImg}
        noVideo={true}
        pageTitle="Watch the Premier League all season"
        title={
          <>
            Watch the Premier League <span className="desktop-line-break">all season</span>
          </>
        }
        subtitle="LIVE GAMES AVAILABLE IN 4K ULTRA HD"
        tagline="Streaming exclusively on Fubo"
        plan="soccer"
        networkSet="canadaEssentials"
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-spc-w.png?ch=width&auto=format,compress"
        logoHeight="90px"
        hasPlanCards={false}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline-block;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
