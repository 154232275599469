import React from 'react'
import { Link } from 'src/components/template/compare/components/modules/Link'
import { palette, typography } from 'src/modules/design-system/styles'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsTranscendInitialized } from 'src/modules/reducers/transcend'
import { InstagramIcon } from 'src/components/template/compare/components/icons/InstagramIcon'
import { TwitterIcon } from 'src/components/template/compare/components/icons/TwitterIcon'
import { FacebookIcon } from 'src/components/template/compare/components/icons/FacebookIcon'
import { initZVA, selectIsZvaLoading } from 'src/modules/reducers/zva'
import { selectCountryCode, selectPostalCode } from 'src/modules/reducers/market'

const termsUrl = '/documents/USA/terms-of-service'
const privacyUrl = '/documents/USA/privacy-policy'
const cookiePolicyUrl = '/documents/USA/cookie-policy'
const instagramUrl = 'https://www.instagram.com/fubotv'
const facebookUrl = 'https://www.facebook.com/fuboTV'
const twitterUrl = 'https://twitter.com/fuboTV'
const helpCenterUrl = 'https://support.fubo.tv/hc'
const unsolicitedSubmissionsUrl =
  'https://legal.fubo.tv/policies/en-US/?name=unsolicited-submissions-policy'
const affiliateURI = 'https://www.fubo.tv/stream/affiliate/'

const CompareFooter = () => {
  // Transcend
  const isTranscendInitialized = useSelector(selectIsTranscendInitialized)
  const [showTranscendLink, setShowTranscendLink] = React.useState(false)

  const showTranscendHandler = e => {
    e.preventDefault()
    window.transcend.showConsentManager({
      viewState: 'DoNotSellExplainer',
    })
  }

  const hideTranscendHandler = React.useCallback(
    e => {
      if (showTranscendLink && e.key === 'Escape') {
        window.transcend.hideConsentManager()
      }
    },
    [showTranscendLink]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', hideTranscendHandler)
    return () => document.removeEventListener('keydown', hideTranscendHandler)
  }, [hideTranscendHandler])

  React.useEffect(() => {
    if (isTranscendInitialized) {
      setShowTranscendLink(
        window.transcend?.ready &&
          window.airgap?.getRegimes &&
          window.airgap.getRegimes().has('DoNotSell')
      )
    }
  }, [isTranscendInitialized])

  // Solvvy
  const dispatch = useDispatch()
  const isSolvvyLoading = useSelector(selectIsZvaLoading)
  const countryCode = useSelector(selectCountryCode)
  const postal = useSelector(selectPostalCode)
  const handleStartSolvvy = e => {
    e.preventDefault()
    dispatch(
      initZVA({
        authenticated: false,
        location_info: {
          current_country: countryCode,
          current_zip: postal,
        },
      })
    )
  }

  return (
    <StyledCompareFooter>
      <div className="top-links">
        <div className="main">
          <Link
            className="footer-link"
            href="https://advertising.fubo.tv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Advertise
          </Link>
          <Link className="footer-link" href="https://careers.fubo.tv/">
            Careers
          </Link>
          <Link
            className="footer-link"
            href="mailto:press@fubo.tv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Press Inquiries
          </Link>
          <Link
            className="footer-link"
            href="https://ir.fubo.tv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Investors
          </Link>
          <Link
            className="footer-link"
            href={affiliateURI}
            target="_blank"
            rel="noopener noreferrer"
          >
            Become an Affiliate
          </Link>
          <Link
            className="footer-link"
            href={helpCenterUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Help Center
          </Link>
          <Link
            className="footer-link"
            href={unsolicitedSubmissionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsolicited Submissions
          </Link>
          <Link
            className="footer-link"
            href="https://fubo.tv/accessibility/statement"
            target="_blank"
            rel="noopener noreferrer"
          >
            Accessibility
          </Link>
          <Link
            className="footer-link"
            href="https://www.fubo.tv/news"
            target="_blank"
            rel="noopener noreferrer"
          >
            News
          </Link>
          {showTranscendLink && (
            <Link
              className="footer-link"
              href=""
              // @ts-expect-error - TS2322 - Type 'EventListener' is not assignable to type '(arg1: MouseEvent<HTMLAnchorElement, MouseEvent>) => void'.
              onClick={showTranscendHandler}
            >
              Privacy
            </Link>
          )}
        </div>
        <div className="social">
          <Link
            className="footer-link"
            href={facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </Link>
          <Link className="footer-link" href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <TwitterIcon />
          </Link>
          <Link
            className="footer-link"
            href={instagramUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </Link>
        </div>
      </div>
      <div className="footer-body">
        <div className="support">
          <Link href="" onClick={handleStartSolvvy} target="_blank" rel="noopener noreferrer">
            {isSolvvyLoading ? 'Loading...' : 'Contact Us'}
          </Link>
        </div>
        <div className="description">
          Fubo is the world’s only sports-focused live TV streaming service with top leagues and
          teams, plus popular shows, movies and news for the entire household. Watch 100+ live TV
          channels, thousands of on-demand titles and more on your TV, phone, tablet, computer and
          other devices.
        </div>
      </div>
      <div className="secondary">
        <div className="copyright">&copy; {new Date().getFullYear()} fuboTV Inc.</div>
        <div className="privacy">
          <Link className="footer-link" href={termsUrl} target="_blank" rel="noopener noreferrer">
            Terms
          </Link>
          <Link className="footer-link" href={privacyUrl} target="_blank" rel="noopener noreferrer">
            Privacy
          </Link>
          <Link
            className="footer-link"
            href={cookiePolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </Link>
        </div>
      </div>
    </StyledCompareFooter>
  )
}

const StyledCompareFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 1.875rem;
  font-size: ${typography.fontSizes.fontsize04};
  background: ${palette.grey000};

  .footer-link {
    ${typography.interactive.S};
    color: ${palette.gray440};
    text-decoration: underline;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 0.9375rem;
    }
  }

  .top-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    text-decoration: none;
    color: #fff;
  }

  .main {
    display: flex;
    flex-wrap: wrap;

    .footer-link {
      &:not(:last-child) {
        margin-right: 0.625rem;
      }
    }
  }

  .social {
    .footer-link {
      display: inline-block;
      padding: 4px;
    }
  }

  .footer-body {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 1.875rem;
  }

  .support {
    display: flex;
    flex-direction: column;
    min-width: 14.375rem;
    margin-bottom: 0;
    padding: 0 0 0 1.25rem;

    a {
      cursor: pointer;
      color: ${palette.grey260};
      ${typography.body.S};
      font-weight: ${typography.weights.bold};
      display: flex;
      flex-grow: 0;
      flex-basis: auto;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      padding: 0.625rem 1.25rem;
      border: 1px solid ${palette.grey340};

      &:not(:last-child) {
        margin-bottom: 0.9375rem;
      }

      span + span {
        margin-left: .4375rem;
      }
    }
  }

  .description {
    color: ${palette.grey340};
    ${typography.body.S};
    max-width: 36.25rem;
  }

  .secondary {
    color: ${palette.grey260};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .copyright {
      font-weight: ${typography.weights.medium};
    }

    .footer-link {
      &:not(:last-child) {
        margin-right: 0.625rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .top-links {
      flex-direction: column-reverse;

      .social {
        margin-bottom: 0.9375rem;
      }
    }

    .footer-body {
      flex-direction: column;
    }

    .support {
      flex-direction: column;
      margin-bottom: 1.25rem;
      padding: 0;

      a {
        &:not(:last-child) {
          margin-bottom: 0.9375rem;
        }
      }
    }

    .description {
      text-align: center;
      color: ${palette.grey360};
      ${typography.body.S};
    }

    .secondary {
      flex-direction: column-reverse;
      justify-content: center;

      .privacy {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-bottom: 0.9375rem;

        ${breakpointQuery.mobile} {
          flex-direction: column;
        }

        .footer-link {
          flex: 1;
        }
      }
    }
  }
`

export default CompareFooter
