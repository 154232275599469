import React from 'react'
import { useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectShowAddOnsSectionFlag } from 'src/modules/reducers/cohorts'
import styled from 'styled-components'

export default props => {
  const isAddOnsSectionEnabled = useSelector(selectShowAddOnsSectionFlag)
  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Stream Live TV & Sports"
        subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
        tagline="Watch and DVR the channels you love. No contracts, no commitment."
        showRedzone={true}
        newTemplate={true}
        showAddOn={isAddOnsSectionEnabled}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .hero-new .hero-content {
    h1 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
        font-size: 68px;
      }
    }
    h2 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
  }
`
