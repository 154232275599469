import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/MLB_Spring_2022_13s.mp4'}
      title="Watch MLB Network with Fubo"
      subtitle="LIVE GAMES, MLB TONIGHT AND MORE"
      tagline="Stream it on your TV, phone and other devices"
      networkSet="canada"
      customNetworks={['mlbnetwork']}
    />
  )
}
