import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/CBB_on_SEC_2021_LP_15s_V2.mp4'}
    title="Stream college hoops on SEC Network"
    subtitle="GET LIVE GAMES, NEWS AND MORE"
    tagline="Watch it all from your TV and other devices"
    networkSet="basketball"
    customNetworks={['sec']}
    league="nba"
    showRSN={true}
    sportId={sportIds.Basketball}
    leagueId={leagueIds["Men's NCAA Basketball"]}
  />
)
