import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

export default props => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      offerBanner={
        <>
          {' '}
          ✔ Special offer for Spurs fans activated! <br /> Enjoy $30 off a full year of the Premier
          League & more with Fubo{' '}
        </>
      }
      offerCode="canada-year-essentials-tottenham-spurs"
      poster={'https://assets.fubo.tv/marketing/lp/images/posters/toronto-spurs-poster.jpg'}
      noVideo={true}
      title="Watch Tottenham soccer live"
      subtitle="STREAM EVERY PREMIER LEAGUE MATCH"
      tagline="Available all season exclusively on Fubo"
      plan="sportsOnly"
      forceAnchorLink={false}
      networkSet="canadaEssentials"
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
      logoHeight="90px"
      team="Tottenham"
      planCardOverride="tottenhamOffer"
      soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_toronto-spurs.jpg?w=451&ch=width&auto=format,compress"
      preselectPlanLength="annual"
    />
  )
}
