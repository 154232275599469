export const getFormattedUnit = (
  unit: Maybe<string>,
  periodLength?: Maybe<number>
): string | null => {
  if (!unit) return null
  if (unit === 'month' && periodLength === 3) {
    return 'quarter'
  }
  if (unit === 'month' && periodLength === 12) {
    return 'year'
  }
  return unit
}

export const getFormattedUnitAdjective = (formattedUnit: Maybe<string>) => {
  if (!formattedUnit) return null
  switch (formattedUnit) {
    case 'month':
      return 'monthly'
    case 'quarter':
      return 'quarterly'
    case 'year':
      return 'annual'
    default:
      return null
  }
}
