const initialState = {}

export const experimentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_EXPERIMENTS':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
