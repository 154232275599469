import type { StacFlagKeys } from './flags.js'
import type { StacFlagsMap } from './types.js'

export const INIT_STAC_FLAGS = 'STAC_FLAGS/INIT'
export type InitStacFlagsAction = {
  type: 'STAC_FLAGS/INIT'
  query?: any
}

export const TRY_FETCH_STAC_FLAGS = 'STAC_FLAGS/TRY_FETCH'
export type TryFetchStacFlagsAction = {
  type: 'STAC_FLAGS/TRY_FETCH'
  keys?: StacFlagKeys
  query?: any
}

export const FETCH_STAC_FLAGS_SUCCESS = 'STAC_FLAGS/FETCH_SUCCESS'
export type FetchStacFlagsSuccessAction = {
  type: 'STAC_FLAGS/FETCH_SUCCESS'
  flags: StacFlagsMap
}

export const FETCH_STAC_FLAGS_FAILED = 'STAC_FLAGS/FETCH_FAILED'
export type FetchStacFlagsFailedAction = {
  type: 'STAC_FLAGS/FETCH_FAILED'
}

export type StacFlagsAction =
  | InitStacFlagsAction
  | TryFetchStacFlagsAction
  | FetchStacFlagsSuccessAction
  | FetchStacFlagsFailedAction
