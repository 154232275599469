import { JsonValue } from '@protobuf-ts/runtime'

import { cohortingEvent, CohortingEventProps } from './cohortingEvent.ts'
import { pageViewEvent, PageViewProps } from './pageView.ts'
import { uiInteractionEvent, UiInteractionProps } from './uiInteraction.ts'
import { uiRenderEvent, UiRenderProps } from './uiRender.ts'

export type SchemaProps = UiInteractionProps | UiRenderProps | PageViewProps | CohortingEventProps
export function getEventProto(data: SchemaProps): JsonValue {
  const { name } = data
  switch (name) {
    case 'page_view':
      return pageViewEvent(data)
    case 'ui_interaction':
      return uiInteractionEvent(data)
    case 'ui_render':
      return uiRenderEvent(data)
    case 'cohorting_event': {
      return cohortingEvent(data)
    }
    default:
      return {}
  }
}
