const initialState = {
  countryCode: null,
  countryCode2: null,
  countryName: null,
  dma: null,
  postalCode: null,
}

export const marketReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "MARKET_FETCH_SUCCESS":
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
