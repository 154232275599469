import React, { useEffect, useState } from 'react'
import { FuboLogo } from 'src/components/icons/FuboLogo'
import CompareButton from 'src/components/template/compare/components/modules/CompareButton'
import styled from 'styled-components'
import { breakpoint, breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import { typography } from 'src/components/template/compare/constants/typography'
import Checkmark from 'src/components/template/compare/components/icons/CheckmarkSmall'
import { USDefaultCtaTextShort, isUSFreeTrial } from 'src/modules/utils/constants'

const CompareRows = ({ competitor, listItems, fuboList, competitorList, ...props }) => {
  const [ctaText, setCtaText] = useState(USDefaultCtaTextShort)

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      if (windowWidth < breakpoint.mobile) {
        setCtaText(isUSFreeTrial ? 'Start Trial' : USDefaultCtaTextShort)
      }
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Call handleResize immediately to set initial state
    handleResize()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <StyledCompareRows {...props}>
      <div className="item logo label"></div>
      <div className="item logo fubo title first">
        <FuboLogo width={70} height={29} />
      </div>
      <div className="item logo competitor">{competitor}</div>

      <div className="item cta label"></div>
      <div className="item cta fubo">
        <CompareButton label={ctaText} />
      </div>
      <div className="item cta competitor"></div>

      {listItems.map((item, index) => (
        <>
          <div className={`item list label ${index === listItems.length - 1 ? 'last' : ''}`}>
            {item}
          </div>
          <div className={`item list fubo ${index === listItems.length - 1 ? 'last' : ''}`}>
            <span className="checkmark">
              <Checkmark />
            </span>{' '}
            {fuboList[index]}
          </div>
          <div className={`item list competitor ${index === listItems.length - 1 ? 'last' : ''}`}>
            {competitorList[index]}
          </div>
        </>
      ))}
    </StyledCompareRows>
  )
}

export default CompareRows

const StyledCompareRows = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  color: #fff;
  gap: 0 32px;
  margin-bottom: 40px;

  ${breakpointQuery.mobile} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .item {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 16px;
    padding-bottom: 16px;

    ${breakpointQuery.mobile} {
      padding-left: 8px;
      padding-right: 8px;
    }

    &.last {
      padding-bottom: 40px;
    }

    &.label {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .list {
    ${typography.body.md}
    font-weight: 700;
    display: flex;
    align-items: flex-start;
    letter-spacing: 0.01em;

    /* Grey / Grey-440 */

    color: #fafafa;

    text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);

    ${breakpointQuery.mobile} {
      font-size: 10px;
      line-height: 1.3;
      ${'' /* white-space: nowrap; */}
    }

    span {
      margin-right: 8px;
      width: 10px;
      ${breakpointQuery.mobile} {
        margin-right: 4px;
      }
    }
  }

  .logo {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 16px;

    &.competitor {
      font-style: normal;
      font-weight: 800;
      font-size: var(--logo-height);
      line-height: 1;
      /* identical to box height, or 159% */

      color: #ffffff;
    }
    &.fubo svg {
      height: var(--logo-height);
    }
  }

  .cta {
    display: flex;
    justify-content: center;
  }

  ${breakpointQuery.mobile} {
    .cta {
      box-sizing: border-box;
      ${props =>
        props.labels &&
        `{
          display: none;
        }`}
      width: 100%;
      a {
        ${'' /* border-radius: 4px; */}
        padding: 2px 8px;
        width: 100%;
        height: auto;
        span {
          ${typography.body.xs}
          font-size:12px;
        }
      }
    }
  }

  /* Middle column styling */
  .item.fubo {
    position: relative;
    border-left: 0.828795px solid #e5e6e6;
    border-right: 0.828795px solid #e5e6e6;
    background: #19273c;
    &.first {
      border-top: 0.828795px solid #e5e6e6;
      border-top-left-radius: 11.6031px;
      border-top-right-radius: 11.6031px;
    }
    &.last {
      border-bottom: 0.828795px solid #e5e6e6;
      border-bottom-left-radius: 11.6031px;
      border-bottom-right-radius: 11.6031px;
    }
    span.checkmark {
      margin-right: 8px;
      width: 10px;
      ${breakpointQuery.mobile} {
        margin-right: 4px;
      }
    }
  }
`
