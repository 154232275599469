import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/formula1-b.jpg?w=2048&ch=width&auto=format,compress"
    title="Watch Formula 1 Grand Prix events"
    subtitle="SEE EVERY SESSION FOR EVERY RACE ALL SEASON"
    tagline="Stream them live from any device"
    ctaText="Start Watching"
    networkSet="sports"
    sportId={sportIds['Auto racing']}
    leagueId={leagueIds['Formula 1']}
  />
)
