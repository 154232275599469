import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default props => {
  return (
    <BallySportsNBA
      location={props.location}
      video={videos.bally_magic.videoUrl}
      staticAnnotation={videos.bally_magic.staticAnnotation}
      title="Watch Orlando basketball live"
      subtitle="STREAM THE MAGIC ON FANDUEL SPORTS FLORIDA"
      teamId={teamIds['Orlando Magic']}
      showFDOnly={true}
    />
  )
}
