const mobile = 767
const desktop = 768

const mobileQuery = `@media (max-width: ${mobile}px)`
// const tablet = `@media (min-width: 768px)`
const desktopQuery = `@media (min-width: ${desktop}px)`
// const desktop = `@media (min-width: 1024px)`

export const breakpoint = {
  mobile: mobile,
  // tablet: tablet,
  desktop: desktop,
}

export const breakpointQuery = {
  mobile: mobileQuery,
  // tablet: tablet,
  desktop: desktopQuery,
}
