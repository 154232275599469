import { ApiProgramWithAssets } from '@fubotv/js-client/dist/esm/domains/content/standard/remote.js'
import {
  EpisodeWithExclusiveNetwork,
  LeagueWithMatches,
  Match,
  Network,
  StationType,
  Team,
  TeamMatch,
} from '@fubotv/types/api/content'
import { PlanChannel } from '@fubotv/types/api/plan'
import { kebabCase } from '../kebabCase'

type ContentItem = {
  name: string
  id: number
  type: 'channel' | 'network'
  logoOnWhiteUrl: string
}

type DirectoryItem = {
  type: 'match' | 'team' | 'league' | 'network' | 'channel'
  id: string
  name: string
  leagueName?: string
  networkType?: 'National' | 'OTA' | 'RSN'
}

type ContentWith =
  | ContentItem
  | EpisodeWithExclusiveNetwork
  | LeagueWithMatches
  | Match
  | Network
  | PlanChannel
  | Team
  | TeamMatch
  | DirectoryItem

type ContentWithout = ApiProgramWithAssets

function hasTypeProp(content: object): content is ContentWith {
  return content && 'type' in content
}

function hasIdProp(content: object): content is ContentWith {
  return content && 'id' in content
}

function isAvailableOnChannelLogo(content: object): content is AvailableOnChannelLogo {
  return (
    content && 'id' in content && 'name' in content && 'logo' in content && !('type' in content)
  )
}

function isDirectoryItem(content: object): content is DirectoryItem {
  return content && 'id' in content && 'name' in content
}

function hasNetworkTypeProp(content: object): content is ContentWith {
  return content && 'networkType' in content
}

class ContentRouter {
  content: ContentWith | ContentWithout
  route: string
  type:
    | 'Episode'
    | 'GenericMatch'
    | 'LeagueWithMatches'
    | 'National'
    | 'Network'
    | 'OTA'
    | 'ProgramWithAssets'
    | 'RSN'
    | 'Team'
    | 'TeamMatch'
    | 'channel'
    | 'network'
    | 'team'
    | 'match'
    | 'league'
    | 'genre'
    | StationType
  name: string
  id: number | string
  parentName: string
  parentId: string | number

  constructor(
    content:
      | ApiProgramWithAssets
      | ContentItem
      | EpisodeWithExclusiveNetwork
      | LeagueWithMatches
      | Match
      | Network
      | PlanChannel
      | Team
  ) {
    this.content = content
    this.id = this._getContentId()
    this.parentId = this._getParentId()
    this.type = this._getContentType()
    this.name = kebabCase(this._getContentName())
    this.parentName = kebabCase(this._getParentName())
  }
  private _getContentId(): string | number {
    if (hasIdProp(this.content)) {
      return this.content.id
    }
    if (isAvailableOnChannelLogo(this.content)) {
      return this.content.id
    }
    if (this.content.program !== undefined) {
      return this.content.program.programId
    }
  }

  private _getContentType():
    | 'Episode'
    | 'GenericMatch'
    | 'LeagueWithMatches'
    | 'National'
    | 'Network'
    | 'OTA'
    | 'ProgramWithAssets'
    | 'RSN'
    | 'Team'
    | 'TeamMatch'
    | 'channel'
    | 'network'
    | 'team'
    | 'match'
    | 'league'
    | 'genre'
    | StationType {
    if (hasTypeProp(this.content)) {
      return this.content.type
    } else {
      return 'ProgramWithAssets'
    }
  }

  private _getParentId(): Maybe<string | number> {
    if (hasTypeProp(this.content)) {
      switch (this.content.type) {
        case 'Episode': {
          return this.content.seriesId
        }
        case 'Team': {
          return this.content.leagueId
        }
        case 'GenericMatch':
        case 'TeamMatch': {
          return this.content.league.id
        }
        default:
          return null
      }
    }
    return null
  }

  private _getParentName(): Maybe<string> {
    if (hasTypeProp(this.content)) {
      switch (this.content.type) {
        case 'Episode': {
          return this.content.seriesTitle.toLowerCase()
        }
        case 'Team': {
          return this.content.leagueName?.toLowerCase()
        }
        case 'GenericMatch':
        case 'TeamMatch': {
          return this.content.league.name?.toLowerCase()
        }
        case 'match':
        case 'league':
        case 'team':
        case 'network':
        case 'channel': {
          return this.content?.name.toLowerCase()
        }
        default:
          return null
      }
    }
    return null
  }

  private _getContentName(): Maybe<string> {
    if (hasTypeProp(this.content)) {
      switch (this.content.type) {
        case 'match':
        case 'league':
        case 'team':
        case 'channel':
        case 'Team':
        case 'network': {
          return this?.content?.name.toLowerCase()
        }
        case 'REGIONAL':
        case 'NATIONAL': {
          return this?.content?.displayName?.toLowerCase()
        }
        case 'Episode':
        case 'GenericMatch':
        case 'TeamMatch': {
          return this?.content?.title?.toLowerCase()
        }
        default:
          return null
      }
    } else {
      if (isDirectoryItem(this?.content)) return this?.content?.name
      return this?.content?.program?.title
    }
  }

  getContentRoute(): string {
    let location = null
    let path = null

    if (hasTypeProp(this.content)) {
      switch (this.content.type) {
        case 'Episode': {
          location = 'programs'
          path = `${this.id}`
          break
        }
        case 'REGIONAL': {
          if (this.content.networkType === 'RSN') {
            location = 'channel'
            path = `${this.id}/${this.name}`
            break
          }
          if (this.content.networkType === 'OTA') {
            location = 'networks'
            path = `${this.name}`
            break
          }
          break
        }
        case 'network': {
          location = 'networks'
          path = `${this.name}`
          break
        }
        case 'channel':
        case 'NATIONAL': {
          if (isDirectoryItem(this.content) && hasNetworkTypeProp(this.content)) {
            const networkType = this.content.networkType
            location = networkType === 'OTA' ? 'networks' : 'channel'
            path = networkType === 'RSN' ? `${this.id}/${this.name}` : `${this.name}`
            break
          }
          location = 'channel'
          path = `${this.name}`
          break
        }
        case 'Team':
        case 'team': {
          location = 'teams'
          path = `${this.id}`
          break
        }
        case 'GenericMatch':
        case 'TeamMatch':
        case 'match': {
          location = 'matches'
          path = `${this.id}`
          break
        }
        case 'LeagueWithMatches':
        case 'league': {
          location = 'leagues'
          path = `${this.id}`
          break
        }
        default:
          location = 'channel'
          path = `${this.id}/${this.name}`
      }
    } else {
      location = 'program'
      path = `${this.id}`
    }
    // For routes that are invalid (due to an issue in the data), return null
    if (location === null || path === null) return null
    return `/welcome/${location}/${path}`
  }

  getParentRoute(): Maybe<string> {
    let location = null
    let path = null

    if (hasTypeProp(this.content)) {
      switch (this.content.type) {
        case 'Episode': {
          location = 'series'
          path = `${this.parentId}/${this.parentName}`
          break
        }
        case 'REGIONAL':
        case 'network': {
          location = 'networks'
          path = `${this.name}`
          break
        }
        case 'channel':
        case 'NATIONAL': {
          location = 'channel'
          path = `${this.name}`
          break
        }
        case 'Team':
        case 'team': {
          location = 'leagues'
          path = `${this.parentId}`
          break
        }
        case 'GenericMatch':
        case 'TeamMatch':
        case 'match': {
          location = 'leagues'
          path = `${this.parentId}`
          break
        }
        default:
          location = 'channel'
          path = `${this.id}/${this.name}`
      }
    }
    return `/welcome/${location}/${path}`
  }
}

export default ContentRouter
