import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default props => (
  <NFL
    {...props}
    team="Washington Commanders"
    teamShort="Commanders"
    teamId={teamIds['Washington Commanders']}
  />
)
