export function FuboLogo({ width = 70, height = 29 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 600 243" fill="none" version="1.1" role="img">
      <title>fubo</title>
      <path
        d="M272.439 66.45h-44.583v90.109c0 16.647-.195 44.134-31.209 44.134-31.013 0-32.249-29.033-32.096-44.449V66.449h-44.582v89.795c0 56.573 20.955 85.759 74.894 85.759 16.594 0 26.361-5.487 32.988-10.392v7.248h44.583V66.449h.005ZM102.534 1.388c-49.039-5.386-60.184 5.84-69.097 17.51-7.887 10.33-8.917 24.697-8.917 35.022v12.53H0v41.309h24.963v131.104h44.583V107.759h33.656v-41.31H70.438V54.817c0-11.852 9.51-13.322 14.266-13.02l9.81.448 8.02-40.857ZM334.298 2.696l-44.582 8.75V238.86h44.582v-7.205c13.05 7.973 28.299 10.797 44.578 10.797 20.569 0 39.482-7.304 54.407-19.528a105.134 105.134 0 0 1-21.891-38.438c-8.016 10.736-20.006 17.559-33.408 17.559-24.128 0-43.691-22.11-43.691-49.388 0-27.277 19.558-49.387 43.691-49.387 24.133 0 43.69 22.11 43.69 49.387v.048c.015 22.816 8.612 43.643 22.626 59.479l.009-.01c.773.878 1.56 1.742 2.367 2.591 9.609 10.053 21.489 17.868 34.739 22.597v.028c9.252 3.283 19.2 5.067 29.563 5.067 49.168 0 89.027-40.202 89.027-89.8 0-49.597-39.859-89.795-89.027-89.795-20.874 0-40.074 7.248-55.256 19.386a104.287 104.287 0 0 1 21.256 38.58c8.092-10.201 20.063-16.661 33.418-16.661 24.376 0 44.134 21.509 44.134 48.042s-19.763 48.042-44.134 48.042c-24.372 0-43.91-21.266-44.134-47.594 0-22.83-8.293-43.671-21.948-59.516-16.008-18.575-39.392-30.283-65.433-30.283-16.284 0-31.529 4.575-44.578 12.548V2.695h-.005Z"
        fill="#fff"
      />
    </svg>
  )
}
