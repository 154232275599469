import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/ACCCBB2021.mp4"
    title="Watch ACC Network with Fubo"
    subtitle="GET GAMES, NEWS, ANALYSIS AND MORE"
    tagline="Stream it live from your TV, phone and other devices"
  />
)
