import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Yellowstone_1280x720_LP_9s_cropped.mp4'
    }
    staticAnnotations={networkLogos.paramount}
    title="Watch Yellowstone on Paramount Network"
    subtitle="CATCH NEW EPISODES LIVE EACH WEEK"
    tagline="Stream on your TV, phone and other devices"
    customNetworks={['paramount']}
  />
)
