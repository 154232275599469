import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default props => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      video={
        'https://assets-imgx.fubo.tv/marketing/lp/videos/compressed/TrailBlazers_Web_LP_10s.mp4'
      }
      videoMobile={
        'https://assets-imgx.fubo.tv/marketing/lp/videos/mobile/TrailBlazers_Mobile_LP_10s.mp4'
      }
      staticAnnotation={networkLogos.ripcity}
      title="Watch the Portland Trail Blazers"
      subtitle="GET LIVE GAMES ALL SEASON ON RIP CITY TV"
      tagline="Stream anytime, anywhere, from any device. Regional restrictions apply."
      customNetworks={['ripcity']}
    />
  )
}
