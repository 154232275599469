import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/ABC2021.mp4"
    title="Watch ABC live with Fubo"
    subtitle="THE BACHELOR, GREY'S ANATOMY AND MORE"
    tagline="Stream them all from your TV, phone and other devices"
    ctaText="Start Watching"
  />
)
