type Props = {
  width?: number
  height?: number
  fill?: string
  ariaLabel?: string
}

export function TwitterIcon({
  width = 24,
  height = 20,
  fill = '#ffffff',
  ariaLabel = 'Twitter',
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 20" role="img" aria-label={ariaLabel}>
      <title>Twitter</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
        <g transform="translate(-1347.000000, -32.000000)" fill={fill} fillRule="nonzero">
          <g transform="translate(1310.000000, 30.000000)">
            <g>
              <path d="M61,4.36751664 C60.1168767,4.76906439 59.1680348,5.04049605 58.1720368,5.16253695 C59.188566,4.53767214 59.9693145,3.54822274 60.3370013,2.3691509 C59.3854875,2.94782379 58.3318333,3.36797328 57.2102105,3.5943666 C56.3120873,2.61308851 55.0323554,2 53.6161238,2 C50.8968635,2 48.6922897,4.26052056 48.6922897,7.04885962 C48.6922897,7.44459131 48.7358365,7.82994064 48.8197894,8.19952414 C44.7276099,7.98889663 41.0994451,5.97885073 38.6709499,2.92407893 C38.2471069,3.66978298 38.004248,4.53709534 38.004248,5.46242399 C38.004248,7.2141604 38.8734963,8.75959528 40.1947125,9.66502439 C39.3875735,9.63878007 38.6283406,9.41166575 37.964545,9.03347833 C37.9640762,9.05453147 37.9640762,9.07568074 37.9640762,9.09697421 C37.9640762,11.5432719 39.6613698,13.5838881 41.9138029,14.0478262 C41.5006943,14.1631378 41.0656483,14.2250475 40.6165867,14.2250475 C40.2992904,14.2250475 39.9908535,14.1933236 39.690151,14.1342979 C40.3167748,16.1401139 42.1351462,17.6000865 44.2897983,17.6407027 C42.6046453,18.9950251 40.4815401,19.8021101 38.1745915,19.8021101 C37.7771391,19.8021101 37.385218,19.7782691 37,19.7314043 C39.179027,21.1641231 41.7672251,22 44.5478446,22 C53.6045926,22 58.5572548,14.306376 58.5572548,7.63426182 C58.5572548,7.41536687 58.5523798,7.19762551 58.5429579,6.98103776 C59.5051123,6.2692206 60.3399075,5.37998991 61,4.36751664 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
