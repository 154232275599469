import React from 'react'
import Layout from 'src/components/common/Layout'
import styled from 'styled-components'
import { Link } from 'gatsby'

const SelectBuffaloSabresTemplate = ({ lang = 'en', market = 'all' }) => {
  return (
    <Layout lang={lang} market={market}>
      <StyledSelectBuffaloSabresTemplate>
        <div className="content-card">
          <h1>Choose your home location</h1>
          <Link className="cta-button" to="/buffalo-sabres">
            USA
          </Link>
          <Link className="cta-button" to="/ca/buffalo-sabres">
            CANADA
          </Link>
        </div>
      </StyledSelectBuffaloSabresTemplate>
    </Layout>
  )
}

export default SelectBuffaloSabresTemplate

const StyledSelectBuffaloSabresTemplate = styled.div`
  background: linear-gradient(-225deg, rgb(106, 17, 203) 0%, rgb(37, 117, 252) 100%);
  background-color: #15101e;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content-card {
    background: rgba(255, 255, 255, 0.95);
    padding: 80px 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 95%;
    border-radius: 15px;
    gap: 30px;
    margin: 0 40px 200px;

    @media screen and (max-width: 1000px) {
      padding: 80px 6%;
    }

    @media screen and (max-width: 767px) {
      margin: 0 10px 100px;
      padding: 80px 6%;
    }

    h1 {
      font-size: 42px;
      line-height: 1.25;
      margin-bottom: 20px;
      color: #1a1a1a;
      letter-spacing: -1px;
      font-weight: 900;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: block;
      @media screen and (max-width: 767px) {
        font-size: 34px;
      }
    }
    .cta-button {
      font-size: 22px;
      background: #fa4616;
      padding: 18px 20px;
      width: 420px;
      border-radius: 30px;
      font-weight: 700;
      text-align: center;
      margin: 0 auto;
      max-width: 100%;
      box-sizing: border-box;
      text-decoration: none;
      color: #fff;
      font-weight: 900;
      transition: background 0.2s;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }

      &:hover {
        background: #b33922;
      }
    }
  }
`
