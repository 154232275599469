function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function updateParams(params, paramName, paramValue) {
  const encodedParamName = encodeURIComponent(paramName)
  const encodedParamValue = encodeURIComponent(paramValue)

  const regex = new RegExp(`([?&])${escapeRegExp(encodedParamName)}(=[^&]*)?`, 'gi')

  if (regex.test(params)) {
    if (paramValue === '' || paramValue === null) {
      return params.replace(regex, '$1').replace(/&$/, '')
    } else {
      return params.replace(regex, `$1${encodedParamName}=${encodedParamValue}`)
    }
  } else {
    if (paramValue === '' || paramValue === null) {
      return params
    } else {
      const separator = params === '?' ? '' : params.includes('?') ? '&' : '?'
      return `${params}${separator}${encodedParamName}=${encodedParamValue}`
    }
  }
}

export function replaceUrlParam(url, paramName, paramValue) {
  const [urlWithoutHash, hashFragment] = url.split('#')
  const updatedUrl = updateParams(urlWithoutHash, paramName, paramValue)

  const finalUrl = hashFragment ? `${updatedUrl}#${hashFragment}` : updatedUrl
  return decodeURIComponent(finalUrl)
}

export function replaceSlugParam(params, paramName, paramValue) {
  const updatedParams = updateParams(`?${params}`, paramName, paramValue)
  return updatedParams.startsWith('?') ? updatedParams.slice(1) : updatedParams
}
