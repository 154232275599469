import React from 'react'
import styled from 'styled-components'

export function Link(props) {
  const { children, href, onClick, className, target, rel } = props

  return (
    <StyledLink href={href} onClick={onClick} className={className} target={target} rel={rel}>
      {children}
    </StyledLink>
  )
}

const StyledLink = styled.a`
  padding: 0px 2px;
`
