import React, { useEffect, useState } from 'react'
import LiveTemplateDefault from 'src/components/template/live'

export default props => {
  return (
    <LiveTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      callsign="YESHDNY"
      title="Watch YES Network Live With Fubo"
      networkName="YES Network"
      networkSet="baseball"
      customNetworks={['yesnetwork']}
    />
  )
}
