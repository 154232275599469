import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.ucl.videoUrl}
      staticAnnotation={videos.ucl.staticAnnotation}
      title="Watch the UEFA Champions League"
      tagline="60+ channels of sports, news, novelas, and entertainment in Spanish."
      networkSet="soccerLatino"
      customNetworks={['univision']}
      noteLink="/la/ucl-es/"
    />
  )
}
